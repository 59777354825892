<template>
  <div class="edit-div-ahover">
    <div class="fontSize20gay text-left">全局配置</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0 80px 0">
      <div style="width: 800px;">
        <el-form label-width="120px">
          <el-form-item label="预约使用率" size="large">
            <el-button type="danger" size="small" @click="resetData">重置</el-button>
            <span class="error marginLeft15">*</span><span class="error">此操作将重置该系统所有实验室已使用的课时数，重置后使用率将重新计算。</span>
          </el-form-item>
          <el-form-item label="本学期开始日期" size="large">
            <el-date-picker v-model="startingWeek" type="date" placeholder="开始周日期" size="large" value-format="YYYY-MM-DD" />
            <el-button class="marginLeft15" type="success" plain @click="saveStartingWeek">保存</el-button>
          </el-form-item>
          <el-form-item label="实验室数据" size="large">
            <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD" size="large" />
            <el-button class="marginLeft15" type="success" plain @click="exportRoomData">导出</el-button>
          </el-form-item>
          <el-form-item size="large" label="预约告知书" style="align-items: start">
            <editor @getText="getText" ref="editor" :content="sysInfo.value" style="height: 350px;width: 100%"></editor>
<!--            <el-input v-model="sysInfo.value" autocomplete="off" type="textarea" rows="8" class="room-input-cl" style="width: 600px;height: 180px" placeholder="请输入"/>-->
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="marginTop15px"><button class="subBtn" @click="subData">保存</button></div>
  </div>
</template>
<script>
import {
  exportFile,
  editSystemContent,
  resetRoomData,
  getSystemContent,
  setSystemTermStart,
  getSystemTermStart
} from "@/api/api";
import editor from "@/components/editor.vue";
export default {
  name: "index",
  components:{
    editor
  },
  props:['Row'],
  data(){
    return{
      token:localStorage.getItem('token'),
      Info:{id:'',name:'',code:'',userIds:[],openLesson:[]},
      content:[''],
      roomList:[],
      userList:[],
      studentList:[],
      teacherList:[],
      optionsCourse:[],
      value1:[],
      startingWeek: '',
      sysInfo:{
        value:''
      }
    }
  },
  mounted() {
    this.getSysData();
  },
  methods:{
    exportRoomData(){
      if(this.value1[0] && this.value1[1]){
        exportFile('/laboratory/export?exportStartDate='+this.value1[0]+'&exportEndDate='+this.value1[1]+'&Authorization=Bearer '+this.token);
      }else{
        this.$root.ElMessage.warning('起始时间必传');
      }
    },
    saveStartingWeek(){
      if (!this.startingWeek) {
        this.$message.warning('请选择开始周日期')
        return
      }
      setSystemTermStart({
        value: this.startingWeek
      }).then(res => {
        if (res.success) {
          this.$message.success('提交成功')
        }
      })
    },
    getSysData(){
      getSystemContent().then((res)=>{
        this.sysInfo=res.data;
        this.setText();
      })
      getSystemTermStart().then(res => {
        if (res.success) {
          const data = res.data
          if (data) {
            this.startingWeek = data.value
          }
        }
      })
    },
    resetData(){
      this.$root.ElMessageBox.confirm(
          '你确认要重置实验室使用课时数吗？', 'Warning', {confirmButtonText: '确认', cancelButtonText: '取消', type: 'warning',}
      ).then(() => {
        resetRoomData().then((res)=>{
          if(res.success){
            this.$root.ElMessage({type:'success',message:res.message});
          }else{
            this.$root.ElMessage.error(res.message);
          }
        })
      })
    },
    subData(){//提交
      if((!this.sysInfo.value)){
        this.$root.ElMessage.error('请填写预约告知书！');
        return false;
      }
      editSystemContent(this.sysInfo,'put').then((res)=>{
        if(res.success){
          this.getSysData();
          this.$root.ElMessage({type:'success',message:res.message});
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    handleAvatarSuccess(e){//图片上传成功后
      this.Info.businessLicenseImg=e.data;
    },
    setText(){
      this.$refs.editor.setText(this.sysInfo.value);
    },
    getText(html){
      this.sysInfo.value=html;
    },
  }
}
</script>

<style lang="scss" scoped>
  .el-form-item{
    display: flex;align-items: center;
    .el-form-item__label{width: 800px !important;color: red;padding: 0 15px 0 0;}
    .room-select-tl{width: 300px;}
    .room-input-tl{width: 600px;height: 40px}
    .room-input-cl{width: 300px;height: 40px}
    .room-input-qb{width: 100px;height: 40px}
  }
</style>