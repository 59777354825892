<template>
  <div style="background: #ffffff;border-radius: 8px;padding: 12px 24px 24px 24px">
    <div class="fontSize20gay text-left">编辑页面</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
      <div style="width: 800px;margin: auto">
        <el-form-item label="实验室编号" size="large" label-width="120px">
          <el-input v-model="Info.code" autocomplete="off" :disabled="Info.id?true:false" placeholder="请输入" class="room-input-tl" />
        </el-form-item>
        <el-form-item label="实验室名称" size="large" label-width="120px">
          <el-input v-model="Info.name" autocomplete="off" placeholder="请输入" class="room-input-tl" />
        </el-form-item>
        <el-form-item label="实验室图片" size="large" label-width="120px" style="display: flex;align-items: start">
          <el-upload action="" class="avatar-uploader" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="Info.img" :src="projectName+Info.img" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="所属楼栋" size="large" label-width="120px">
          <el-select v-model="Info.buildingId" filterable placeholder="请选择一个楼栋" class="room-select-tl">
            <el-option v-for="item in buildingList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="地点" size="large" label-width="120px">
          <el-input v-model="Info.address" autocomplete="off" placeholder="请输入" class="room-input-tl" />
        </el-form-item>
        <el-form-item label="教务排课地点" size="large" label-width="120px">
          <el-input v-model="Info.jwName" autocomplete="off" placeholder="请输入" class="room-input-tl" />
        </el-form-item>
        <el-form-item label="管理员" size="large" label-width="120px">
          <el-select-v2 v-model="userIds" filterable :options="optionsAdmin" placeholder="请选择管理该实验室的人员(可多选)" style="width: 300px" multiple />
        </el-form-item>
        <el-form-item label="是否开放预约" size="large" label-width="120px">
          <el-switch class="marginLeft5" v-model="Info.open" inline-prompt active-text="是" inactive-text="否" style="transform: scale(1.2)" />
        </el-form-item>
        <el-form-item label="学生是否预约" size="large" label-width="120px">
          <el-switch class="marginLeft5" v-model="Info.openStudent" inline-prompt active-text="是" inactive-text="否" style="transform: scale(1.2)" />
        </el-form-item>
        <el-form-item label="最大预约课时" size="large" label-width="120px">
          <el-input v-model="Info.studentDayMax" autocomplete="off" oninput="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" class="room-input-cl" placeholder="同一天学生预约时长不超过?节课"/>
        </el-form-item>
        <el-form-item label="预约天数限制" size="large" label-width="120px">
          <span class="fontSize14gay paddingLF5">提前</span>
          <el-input v-model="Info.openBeforeDayMin" autocomplete="off" oninput="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" class="room-input-qb" placeholder="请输入"/><span class="fontSize14gay paddingLF5"> 天，至 </span>
          <el-input v-model="Info.openBeforeDayMax" autocomplete="off" oninput="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" class="room-input-qb" placeholder="请输入"/><span class="fontSize14gay paddingLF5"> 天 可预约</span>
        </el-form-item>
        <el-form-item size="large" label="开放预约时段" label-width="120px">
          <el-select-v2 v-model="openLesson" filterable :options="optionsCourse" placeholder="请选择可开放的课程时段(可多选)" style="width: 300px" multiple />
        </el-form-item>
        <el-form-item size="large" label="核定学时数" label-width="120px">
          <el-input v-model="Info.studyNumber" autocomplete="off"  oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" class="room-input-cl" placeholder="请输入数字"/>
        </el-form-item>
      </div>
    </div>
    <div class="marginTop15px"><button class="subBtn" @click="subData">保存</button><button class="qxBtn" @click="qxBtn">取消</button></div>
  </div>
</template>
<script>
import {addRoom,getBuildingList,getAdminList,getCourseList,uploadFile} from "@/api/api";
import {projectName} from "@/utils/config"
export default {
  name: "index",
  props:['Row'],
  data(){
    return{
      projectName:projectName,
      token:localStorage.getItem('token'),
      Info:{
        id:'',
        name:'',
        code:'',
        openStudent:false,
        open:false,
        jwName: '',
      },
      userIds:[],
      openLesson:[],
      content:[''],
      buildingList:[],
      adminList:[],
      optionsAdmin:[{value:1,label:'你啥都会'},{value:2,label:'你啥都会2'},{value:3,label:'杀得好啥'},{value:4,label:'你啥都会萨达'}],
      optionsCourse:[],
    }
  },
  mounted() {
    if(this.$root.useRoute.query.info){
      this.Info=JSON.parse(this.$root.useRoute.query.info);
      if(this.Info.userIds){
        this.userIds=this.Info.userIds.split(",");
        this.userIds=this.userIds.map(function(data){
          return +data;
        });
      }
      if(this.Info.openLesson){
        this.openLesson=this.Info.openLesson.split(",");
        this.openLesson=this.openLesson.map(function(data){
          return +data;
        });
      }
    }
    this.getList1();
    this.getList2();
    this.getList3();
  },
  methods:{
    qxBtn(){
      this.$root.useRouter.go(-1);
    },
    getInfo(row){//清空表单
      if(row){
        this.Info=JSON.parse(row);
      }else{
        this.Info={};
      }
    },
    getList1(){
      getBuildingList().then((res)=>{
        this.buildingList=res.data;
      })
    },
    getList2(){
      getAdminList().then((res)=>{
        this.adminList=res.data;
        let da=[];
        for(let i in this.adminList){
          da.push({value:this.adminList[i].id,label:this.adminList[i].name});
        }
        this.optionsAdmin=da;
      })
    },
    getList3(){
      getCourseList().then((res)=>{
        let courseList=res.data;
        let da=[];
        for(let i in courseList){
          da.push({value:courseList[i].id,label:courseList[i].name});
        }
        this.optionsCourse=da;
      })
    },
    changeDic(index,type){
      if(type=='add'){
        this.content.push('');
      }else if(type=='reced'){
        this.content.splice(index,1);
      }
    },
    beforeAvatarUpload(e){
      let formData= new FormData();
      formData.append('file',e);
      uploadFile(formData).then((res)=>{
        if(res.success){
          this.Info.img=res.data;
        }else{
          this.$root.ElMessage.error(res.message);
        }
        console.log(res);
      })
    },
    handleAvatarSuccess(e){//图片上传成功后
      this.Info.businessLicenseImg=e.data;
    },
    subData(){//提交
      if((!this.Info.name) || (!this.Info.code)){
        this.$root.ElMessage.error('编号和名称不能为空！');
        return false;
      }
      if(!this.Info.jwName){
        this.$root.ElMessage.error('教务排课地点不能为空！');
        return false
      }
      let method='post';
      if(this.Info.id){method='put';}
      this.Info.userIds=this.userIds.join(",");
      this.Info.openLesson=this.openLesson.join(",");
      addRoom(this.Info,method).then((res)=>{
        if(res.success){
          this.$root.ElMessage({type:'success',message:res.message});
          this.$root.useRouter.go(-1);
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .el-form-item{
    display: flex;align-items: center;
    .el-form-item__label{width: 800px !important;color: red;padding: 0 15px 0 0;}
    .room-select-tl{width: 300px;}
    .room-input-tl{width: 600px;height: 40px}
    .room-input-cl{width: 300px;height: 40px}
    .room-input-qb{width: 100px;height: 40px}
  }
  .avatar-uploader .avatar {
    width: 340px;
    height: 180px;
    display: block;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
  }

  .avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
  }
  .el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 340px;
    height: 180px;
    text-align: center;
  }
</style>