<template>
    <div class="mobile-body flex flex-direction">
        <header>
            <div class="header-top border-box flex justify-between align-center">
                <div class="header-top-search border-box flex">
                    <input v-model="pageInfo.labName" @change="inputChange" type="text" placeholder="实验室名称"
                        class="border-box" />
                    <img src="@/static/img/admin/icon-search.png" alt="">
                </div>
                <img src="@/static/img/admin/icon-filter.png" alt="" @click.stop="showDialogEvent">
            </div>
            <div class="header-nav flex border-box">
                <div style="margin-right: 24px;flex-shrink: 0;" v-for="(item, index) in  stateArray " :key="item"
                    @click="changeState(index)">
                    <div :style="{ color: stateIndexCurrent == index ? '#333' : '#999' }">{{ item }}</div>
                    <div class="step-bottom-div" :class="stateIndexCurrent == index ? 'step-bottom-check' : ''"></div>
                </div>
            </div>
        </header>
        <main v-infinite-scroll="loadMore" infinite-scroll-distance="10" class="border-box"
            :class="{ 'changeHeight': stateIndexCurrent !== 1 && stateIndexCurrent !== 2 }">
            <div v-if="tableData.length == 0" class="no-data">暂无数据</div>
            <div v-else class="main-item border-box flex justify-between" v-for="( item, index ) in  tableData" :key="index"
                @click="info(item.id)">
                <div v-if="stateIndexCurrent == 1 || stateIndexCurrent == 2" class="main-item-left flex align-center">
                    <span @click.stop>
                        <el-checkbox-group v-model="checkedId" @change="handleCheckedIdChange">
                            <el-checkbox :label="item.id"><br /></el-checkbox>
                        </el-checkbox-group>
                    </span>
                </div>
                <div class="main-item-center">
                    <div class="title">实验室预约</div>
                    <div class="name">实验室名称<span>{{ item.laboratory.name || '' }}</span></div>
                    <div class="role">身份<span>{{ item.type == 1 ? '教师' : '学生' }}</span></div>
                    <div class="people">申请人<span>{{ item.user.name || '' }}</span></div>
                    <div class="time">申请时间<span>{{ item.time || '' }}</span></div>
                </div>
                <div class="main-item-right">
                    <div class="stateBtnAll" :class="'stateBtnColor' + [item.state]">{{ stateArray[item.state + 1] }}</div>
                </div>
            </div>
        </main>
        <footer v-if="stateIndexCurrent == 1 || stateIndexCurrent == 2" class="border-box flex justify-between">
            <div class="footer-left flex flex-direction">
                <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
                    label="全选"></el-checkbox>
                <div>已选{{ allCheckLength }}条</div>
            </div>
            <div class="footer-right">
                <div v-if="stateIndexCurrent == 1">
                    <button :disabled="checkedId.length == 0" :style="{ opacity: checkedId.length == 0 ? .5 : 1 }"
                        @click=submit(1)>批量通过</button>
                    <button :disabled="checkedId.length == 0" :style="{ opacity: checkedId.length == 0 ? .5 : 1 }"
                        @click=submit(2)>批量不通过</button>
                </div>
                <button v-if="stateIndexCurrent == 2" :disabled="checkedId.length == 0"
                    :style="{ opacity: checkedId.length == 0 ? .5 : 1 }" @click=submit(3)>批量退回</button>
            </div>
        </footer>
        <el-drawer v-model="showDialog" :with-header="false" direction="btt" modal-class="bbt-modal-wrapper">
            <div class="drawer">
                <div class="drawer-top">
                    <div class="title">预约日期</div>
                    <img src="@/static/img/admin/icon-close.png" alt="" @click="showDialog = false">
                </div>
                <div class="drawer-content">
                    <div class="flex justify-center">
                        <el-date-picker class="marginLeft10" v-model="pageInfo.reservationTime" :editable="false"
                            type="date" value-format="YYYY-MM-DD" placeholder="选择日期" />
                    </div>
                </div>
                <div class="drawer-footer flex justify-between border-box">
                    <button @click="resetTime()">重置</button>
                    <button @click="submitTime">完成</button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { getAppoinmentPage, checkAppointment } from "@/api/api";

export default {
    data() {
        return {
            // 控制全选按钮的状态
            checkAll: false,
            // 选中的id
            checkedId: [],
            showDialog: false,
            stateArray: ['全部', ...this.$root.stateList],
            // nav当前选中的state
            stateIndexCurrent: 1,
            pageInfo: {
                limit: 5,
                labName: '',
                state: '0',
                reservationTime: '',
                page: 1,
                total: 0,
                pages: 1,
            },
            tableData: [],
            arrId: [],
            allCheckLength: '0',
            reservationExamineBo: {
                remarks: "",
                reservationIds: [],
                state: 0
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            // 清空arrid数组
            this.arrId = []
            getAppoinmentPage(this.pageInfo).then(res => {
                this.pageInfo.total = parseInt(res.data.total);
                this.tableData = [...this.tableData, ...res.data.records];
                this.tableData.forEach((item) => {
                    this.arrId.push(item.id)
                })
                this.pageInfo.pages = res.data.pages
                console.log(res);
            }).catch(res => {
                this.$root.ElMessage.error(res.message)
            })
        },
        resetData() {
            this.pageInfo.limit = 5;
            this.pageInfo.page = 1;
            this.pageInfo.total = 0;
            this.tableData = [];
            this.getList();
        },
        showDialogEvent() {
            this.showDialog = true
        },
        // nav切换回调
        changeState(index) {
            this.checkAll = false
            this.allCheckLength = 0
            this.checkedId = []
            this.pageInfo.reservationTime = ''
            let currentIndex = index - 1
            console.log(currentIndex);
            if (currentIndex < 0) {
                this.pageInfo.state = ''
                this.resetData()
            } else {
                this.pageInfo.state = currentIndex
                this.resetData()
            }
            this.stateIndexCurrent = index
        },
        // 下拉加载
        loadMore() {
            if (this.pageInfo.page < this.pageInfo.pages) {
                this.checkAll = false
                this.pageInfo.page += 1
                this.getList()
            }
        },
        // dialog选择时间段确定按钮
        submitTime() {
            if (!this.pageInfo.reservationTime) {
                return this.$root.ElMessage.error('请选择日期');
            }
            this.resetData()
            this.showDialog = false
        },
        // 重置时间
        resetTime() {
            this.pageInfo.reservationTime = ''
        },
        inputChange() {
            this.resetData()
        },
        info(id) {
            console.log(id);
            this.$router.push({ path: '/mobile/admin/appoinmentInfo', query: { id } })
        },
        // 复选框触发回调
        handleCheckedIdChange(val) {
            console.log(val);
            this.reservationExamineBo.reservationIds = val
            this.allCheckLength = val.length
            this.checkAll = this.arrId.length === val.length
        },
        // 全选
        handleCheckAllChange(val) {
            this.checkedId = val ? this.arrId : []
            this.reservationExamineBo.reservationIds = this.checkedId
            this.allCheckLength = this.checkedId.length
        },
        // 提交
        submit(state) {

            this.$root.ElMessageBox.prompt('备注', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                this.reservationExamineBo.remarks = value
                this.reservationExamineBo.state = state
                checkAppointment(this.reservationExamineBo).then(res => {
                    console.log(res);
                    if (res.success) {
                        this.$root.ElMessage({ type: 'success', message: res.message });
                        this.resetData()
                    } else {
                        this.$root.ElMessage.error(res.message);
                    }
                })
            }).catch(() => {
            })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-drawer {
    border-radius: 20px 20px 0 0;
}

::v-deep .el-checkbox__inner {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: rgba(74, 114, 255, 1);
    border: none;
}

::v-deep .el-checkbox__inner:hover {
    border-color: rgba(235, 235, 235, 1);
}

::v-deep .el-checkbox__inner::after {
    height: 9px;
    left: 6px;
    top: 3px;
    width: 4px;
    border: 3px solid var(--el-checkbox-checked-icon-color);
    border-top: 0;
    border-left: 0;
    border-radius: 2px;

}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: rgba(74, 114, 255, 1);
    border-color: rgba(74, 114, 255, 1);
}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
    height: 3px;
    top: 8px;
}
::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: rgba(74, 114, 255, 1);
}
.changeHeight {
    height: calc(100vh - 102px);
}

.border-box {
    box-sizing: border-box;
}

.mobile-body {
    background-color: #f6f6f6;
}

header {
    width: 100%;
    height: 102px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    .header-top {
        width: 100%;
        height: 62px;
        padding: 12px 16px;

        &-search {
            flex: 1;
            position: relative;
            height: 100%;

            >input {
                width: 100%;
                height: 100%;
                background-color: #f6f6f6;
                border: none;
                outline: none;
                border-radius: 4px 4px 4px 4px;
                padding-left: 36px;
            }

            >input::placeholder {
                font-size: 14px;
                color: #999999;
            }

            >img {
                position: absolute;
                left: 12px;
                top: 11px;
                width: 16px;
                height: 16px;
            }
        }

        >img {
            width: 24px;
            height: 24px;
            margin-left: 8px;
        }
    }

    .header-nav {
        width: 100%;
        height: calc(100% - 70px);
        margin-top: 8px;
        padding-left: 16px;
        overflow-x: auto;

        .step-bottom-div {
            margin-top: 5px;
        }
    }
}

main {
    width: 100%;
    padding: 12px 16px;
    margin-bottom: 68px;
    height: calc(100vh - 170px);
    margin-top: 102px;
    overflow-y: auto;

    .main-item {
        background: #fff;
        border-radius: 6px;
        padding: 12px;

        &-left {
            width: 32px;
        }

        &-center {
            flex: 1;
            font-size: 13px;
            text-align: left;

            >div:first-child {
                font-size: 16px;
                margin-bottom: 12px;
            }

            >div:not(:first-child) {
                margin-top: 6px;
                color: #999999;

                >span {
                    margin-left: 12px;
                    color: #333;
                }
            }
        }

        &-right {
            width: 48px;
        }
    }

    .main-item:not(:last-child) {
        margin-bottom: 12px;
    }
}

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 68px;
    background-color: #fff;
    z-index: 999;
    padding: 12px 16px;

    .footer-left {
        >div {
            text-align: left;
            font-size: 12px;
            font-family: PingFang SC, PingFang SC;
            color: #999999;
        }
    }

    .footer-right {
        button {
            width: 106px;
            height: 44px;
            line-height: 44px;
            color: #fff;
            font-size: 16px;
            background: #4A72FF;
            border-radius: 6px 6px 6px 6px;
            opacity: 1;
            border: none;
        }

        >div {
            >button {
                width: 106px;
                height: 44px;
                line-height: 44px;
                color: #fff;
                font-size: 16px;
                background: #4A72FF;
                border-radius: 6px 6px 6px 6px;
                opacity: 1;
                border: none;
            }

            >button:last-child {
                margin-left: 12px;
            }
        }
    }
}

.bbt-modal-wrapper {
    border-radius: 20px 20px 0 0;

    .drawer {
        &-top {
            position: relative;

            .title {
                font-weight: 600;
                font-size: 17px;
            }

            >img {
                width: 24px;
                height: 24px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        &-content {
            width: 100%;
            margin: 16px 0 30px 0;
        }

        &-footer {
            >button {
                width: 164px;
                height: 44px;
                border: 1px solid #EBEBEB;
                border-radius: 6px;
                line-height: 44px;
                color: #666;
                font-size: 16px;
                background-color: transparent;
            }

            >button:last-child {
                background-color: #4A72FF;
                color: #fff;
            }
        }
    }
}
</style>
