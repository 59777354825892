<template>
  <div class="exam-list-container">
    <div class="exam-tabs">
      <div class="exam-tabs-item"
           :class="{'tabs-selected':iter.selected, 'ml-32': index != 0}"
           v-for="(iter, index) in navList"
           :key="iter.label"
           @click="selectNav(index)">
        <div class="tabs-label">{{ iter.label }}</div>
        <div class="tabs-selected-bottom"></div>
      </div>
    </div>
    <div v-infinite-scroll="getList" infinite-scroll-distance="20" class="exam-container">
      <div class="exam-item"
           v-for="iter in examList"
           :key="iter.id"
           @click="toExam(iter)">
        <div class="tags-item start"
             :class="examStatus(iter.startTime, iter.endTime, iter)">{{ examLabel(iter.startTime, iter.endTime, iter) }}</div>
        <div class="flex align-center" style="padding-top: 10px;">
          <div v-show="iter.infinite == 0" class="exam-tag flex justify-center align-center">单次</div>
          <div v-show="iter.infinite == 1" class="exam-tag flex justify-center align-center">不限次</div>
          <div class="exam-title">{{ iter.name }} </div>
        </div>
        <div class="exam-time flex justify-between">
          <div style="line-height: 24px;">
            {{ [iter.startTime, iter.endTime].join(' 至 ') }}
          </div>
          <div style="font-size: 16px;"> <el-icon><ArrowRight /></el-icon> </div>
        </div>
        <div class="exam-score">
          合格分：{{ iter.paper.score }}分
        </div>
      </div>
      <div v-show="examList.length === 0">
        <el-empty description="暂时没有考试" />
      </div>
    </div>
  </div>
</template>

<script>
import {getUserExam} from "@/api/examination";

export default {
  name: "examList",
  data(){
    return {
      searchParams: {
        limit: 10,
        page: 1,
      },
      pageEnd: false,
      getPage: false,
      navList: [
        {
          label: '全部',
          selected: true,
          params: {
            searchSoon: undefined,
            searchNow: undefined,
            searchOver: undefined,
          },
        },
        {
          label: '即将开始',
          selected: false,
          params: {
            searchSoon: true,
            searchNow: undefined,
            searchOver: undefined,
          },
        },
        {
          label: '进行中',
          selected: false,
          params: {
            searchNow: true,
            searchSoon: undefined,
            searchOver: undefined,
          },
        },
        {
          label: '已结束',
          selected: false,
          params: {
            searchOver: true,
            searchSoon: undefined,
            searchNow: undefined,
          },
        },
      ],
      examList: [],
    }
  },
  mounted() {

  },
  methods: {
    getList(){
      if (this.pageEnd || this.getPage) {
        return
      }
      this.getPage = true

      getUserExam(this.searchParams).then(res => {
        if (res.success) {
          // this.examList = res.data.records
          this.examList.push(...res.data.records)

          this.searchParams.page += 1
          if (res.data.records.length === 0) {
            this.pageEnd = true
          }
        } else {
          this.$root.ElMessage.error(res.message);
        }
      }).finally(() => {
        this.getPage = false
      })
    },
    selectNav(index){
      this.navList.map(iter => iter.selected = false)
      this.navList[index].selected = true

      // 更新参数
      this.searchParams = { ...this.searchParams,  ...this.navList[index].params }
      this.pageEnd = false
      this.searchParams.page = 1

      this.examList = []
      this.getList()
    },
    /**
     * 前往考试
     */
    toExam(iter){
      if (iter.check && iter.infinite == 0) {
        this.$root.ElMessage.error('你已参加过此次考试');
        return
      }

      const now = new Date().getTime()
      const itemStart = new Date(iter.startTime.replace(/\-/g, '/')).getTime()
      const itemEnd = new Date(iter.endTime.replace(/\-/g, '/')).getTime()
      if (itemStart > now) {
        this.$root.ElMessage.error('考试暂未开始');
      } else if(now > itemStart && now < itemEnd){
        this.$root.useRouter.replace({ path: `/mobile/user/exam/${iter.id}` })
      } else if(iter.state == 2 || now > itemEnd){
        this.$root.ElMessage.error('考试已结束');
      }
    },
    examLabel(start, end, iter){
      const now = new Date().getTime()
      const itemStart = new Date(start.replace(/\-/g, '/')).getTime()
      const itemEnd = new Date(end.replace(/\-/g, '/')).getTime()
      let text = ''
      if (itemStart > now) {
        text = '即将开始'
      } else if(now > itemStart && now < itemEnd){
        text = '进行中'
      } else if(iter.state == 2 || now > itemEnd){
        text = '已结束'
      }
      return text
    },
    examStatus(start, end, iter){
      const now = new Date().getTime()
      const itemStart = new Date(start.replace(/\-/g, '/')).getTime()
      const itemEnd = new Date(end.replace(/\-/g, '/')).getTime()
      let text = ''
      if (itemStart > now) {
        text = 'not-started'
      } else if(now > itemStart && now < itemEnd){
        text = 'start'
      } else if(iter.state == 2 || now > itemEnd){
        text = 'failure'
      }
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
.exam-list-container{
  padding: 42px 16px 0;
  box-sizing: border-box;
  text-align: left;
  background-color: #f6f6f6;
  min-height: 100vh;
  .exam-tabs{
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 16px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 42px;
    background-color: #fff;
    overflow-x: auto;
    box-sizing: border-box;
    z-index: 1;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .exam-tabs-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      .tabs-label{
        font-size: 16px;
        color: #999999;
      }
      .tabs-selected-bottom{
        margin-top: 7px;
        width: 20px;
        height: 3px;
      }
    }
    .ml-32{
      margin-left: 32px;
    }
    .tabs-selected{
      .tabs-label{
        font-size: 16px;
        font-weight: 600;
        color: #333333;
      }
      .tabs-selected-bottom{
        margin-top: 7px;
        width: 20px;
        height: 3px;
        background: #4A72FF;
        border-radius: 3px 3px 0 0;
      }
    }
  }
  .exam-container{
    padding: 12px 0;
    z-index: 2;
    height: calc(100vh - 42px);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .exam-item{
      position: relative;
      margin-bottom: 12px;
      padding: 12px 16px;
      border-radius: 8px;
      background-color: #fff;
      .tags-item{
        position: absolute;
        right: 0;
        top: 0;
        padding: 4px 8px;
        border-radius: 0 8px 0 8px;
        font-size: 12px;
        color: #FFFFFF;
      }
      .not-started{
        background-color: #4A72FF;
      }
      .start{
        background-color: #1DB469;
      }
      .failure{
        background-color: #CCCCCC;
      }
      .exam-tag{
        padding: 2px 4px;
        background: #FFFFFF;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #4A72FF;
        color: #4A72FF;
        font-size: 10px;
        white-space:nowrap;
      }
      .exam-title{
        width: 310px;
        margin-left: 8px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
      }
      .exam-time{
        margin-top: 8px;
        font-size: 13px;
        color: #666666;
      }
      .exam-score{
        margin-top: 8px;
        font-size: 13px;
        color: #666666;
      }
    }
  }
}
</style>