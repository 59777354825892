<template>
  <div class="edit-div-ahover">
    <div class="fontSize20gay text-left">实验室预约</div>
    <el-scroll class="edit-div-scroll">
      <div style="width: 800px;">
        <el-form label-width="120px">
          <el-form-item label="预约实验室" size="large">
            <el-select v-model="Info.laboratoryId" @change="getAppoint" filterable placeholder="请选择一个实验室" class="room-select-tl">
              <el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="预约角色" size="large">
            <el-select v-model="Info.userType" @change="getUserList" filterable placeholder="请选择一个角色" class="room-select-tl">
              <el-option v-for="item in roleTypeList" :key="item.type" :label="item.name" :value="item.type"/>
            </el-select>
          </el-form-item>
          <el-form-item label="申请人" size="large">
            <el-select v-model="Info.userId" value-key="id" filterable remote placeholder="请搜索选择教师/学生"
                       :remote-method="remoteMethod"
                       :loading="loading" class="room-select-al">
              <el-option v-for="item in userList" :key="item.id" :label="item.name+'('+item.code+' '+(item.department || '')+' '+(item.school || '')+' '+(item.major || '')+' '+(item.clasz || '')+')'" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item size="large" label="用途说明">
            <el-input v-model="Info.use" autocomplete="off" class="room-input-tl" placeholder="请输入"/>
          </el-form-item>
          <el-form-item size="large" label="授课班级">
            <el-input v-model="Info.className" autocomplete="off" class="room-input-tl" placeholder="请填写授课班级" :maxlength="255"></el-input>
          </el-form-item>
          <el-form-item size="large" label="学生人数">
            <el-input type="number" v-model="Info.studentNum" autocomplete="off" class="room-input-tl" placeholder="请填写学生人数"></el-input>
          </el-form-item>
          <el-form-item size="large" label="联系电话">
            <el-input v-model="Info.studentPhone" maxlength="11" autocomplete="off" class="room-input-tl" placeholder="请填写联系电话"></el-input>
          </el-form-item>
          <div class="flex align-start">
            <div class="my-form-title">预约时段</div>
            <div style="margin-bottom: 20px">
              <div class="flex fontSize14gay">
                <div class="flex align-center"><div class="box-tip" style="background-color: #F2F5FF;"></div><div>可预约</div></div>
                <div class="flex align-center"><div class="box-tip" style="background-color: #F0F0F0;"></div><div>不可预约</div></div>
                <div class="flex align-center"><div class="box-tip" style="background-color: #4A72FF;"></div><div>已选</div></div>
              </div>
              <div class="flex marginTop10px" v-if="Info.laboratoryId">
                <div>
                  <div class="title-appoin"><div>日期</div><div>时间段</div></div>
                  <div v-for="(c,index) in courseList" :key="index" class="fontSize12gay66 Time-oclp">
                    <div>{{c.start}}</div><div>{{c.end}}</div>
                  </div>
                </div>
                <el-scrollbar>
                  <div class="flex">
                    <div v-for="(cda,index) in apppointList" :key="index" style="margin-left: 8px">
                      <div class="title-appoin">{{cda.date}}</div>
                      <div v-for="(cour,index1) in cda.curriculumList" :key="index1" class="Course-oclp" @click="setCourse(cour.id,cour.isAppoint,cda.date)" :class="rpoo(cour.id,cda.date)?'course-used-check':(cour.isAppoint?'course-used-allow':'course-used-no')">
                        {{cour.name}}
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
              <div class="marginTop10px fontSize14gay99" v-else>请选择一个实验室</div>
            </div>
          </div>
          <div class="flex align-start">
            <div class="my-form-title">使用资产</div>
            <div style="margin-bottom: 20px">
              <el-select-v2 v-model="assetIds" filterable :options="optionsAsset" @change="chooseAsset" placeholder="请选择耗材/器材(可多选)" style="width: 600px" multiple />
              <div>
                <table class="fontSize13gay marginTop20px text-left form-table-jl" v-if="Info.laboratoryId">
                  <tr><th>资产名称</th><th>类型</th><th>库存</th><th>使用数量</th><th>操作</th></tr>
                  <tr v-for="(a,index) in checkAssetList" :key="index">
                    <td>{{a.name}}</td><td>{{a.type==1?'器具':'耗材'}}</td><td>{{a.number}}</td><td><input type="number" v-model="checkAssetList[index].checkNumber"></td>
                    <td @click="deleteAsset(a.id,index)" style="cursor: pointer;color: red">删除</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="flex align-start">
            <div class="my-form-title">附件</div>
            <div style="margin-bottom: 20px">
              <el-upload v-show="fileList.length === 0"
                         class="upload-demo"
                         action=""
                         ref="filesUpload"
                         list-type="picture"
                         :multiple="true"
                         :show-file-list="false"
                         :limit="5"
                         :file-list="fileList"
                         :http-request="handlerUpload" >
                <el-button style="border: none;background-color: #E6EBF0;">
                  上传附件<el-icon class="el-icon--right"><Upload /></el-icon>
                </el-button>
              </el-upload>
              <div class="common-file-list-container"
                   v-for="(iter, index) in fileList" :key="iter.name">
                <div class="common-file-list-item-left">
                  <div style="display: flex;flex: 1;">
                    <div>
                      <el-icon><DocumentRemove /></el-icon>
                    </div>
                    <div style="margin-left: 14px;flex: 1;font-size: 14px;">{{ iter.name }}</div>
                  </div>
                  <div>
                    <el-icon style="color: #67c23a;"><CircleCheck /></el-icon>
                  </div>
                </div>
                <div class="common-file-list-item-right">
                  <el-icon @click="removeHandler(index)"><DeleteFilled /></el-icon>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </el-scroll>
    <div class="marginTop15px"><button class="subBtn" @click="subData">保存</button><button class="qxBtn" @click="qxBtn">取消</button></div>
  </div>
</template>
<script>
import {
  addAppointment,
  getAppointCourseInfo,
  getAppointmentInfo,
  getAssetListAll,
  getCourseList,
  getRoomList,
  getStudentList,
  getTeacherList,
  uploadFile
} from "@/api/api";

export default {
  name: "index",
  props:['Row'],
  data(){
    return{
      token:localStorage.getItem('token'),
      Info:{id:'',curriculumId:'',date:'',userId:'',userType:'',use:'',utensil:[], studentPhone:''},
      checkDateCurrArray:[],
      roleTypeList:[{type:0,name:'学生'},{type:1,name:'教师'}],
      content:[''],
      roomList:[],
      userList:[],
      studentList:[],
      teacherList:[],
      courseList:[],
      assetList:[],
      apppointList:[],
      optionsAsset:[],
      assetIds:[],
      checkAssetList:[],//已选的资产列表
      loading:false,
      fileList: [],
    }
  },
  mounted() {
    if(this.$root.useRoute.query.id){
      this.getInfo(this.$root.useRoute.query.id);
    }
    this.getList1();
    this.getList2();
    this.getList3();
  },
  methods:{
    /**
     * 自定义文件上传
     */
    handlerUpload(e){
      const loading = this.$loading({ fullscreen: true })
      const formData = new FormData()
      formData.append('file', e.file)
      uploadFile(formData).then(res => {
        if (res.success) {
          const name = ((res.data || '').split('/') || []).reverse()[0]
          this.fileList.push({
            name: name,
            url: res.data,
            value: res.data,
          })
        } else {
          this.$message.error(res.message)
        }
      }).finally(() => {
        loading.close()
      })
    },
    removeHandler(index){
      this.fileList.splice(index, 1)
    },
    remoteMethod(content){
      let list=[];
      let searchList=[];
      if(this.Info.userType===0){list=this.studentList;}
      if(this.Info.userType===1){list=this.teacherList;}
      if(content){
          this.loading=true;
          setTimeout(()=>{
            this.loading=false;
            for(let i in list){
              if(list[i].name.includes(content)){
                searchList.push(list[i]);
              }
            }
          },200)
        this.userList=searchList;
      }else{
        this.userList=[];
      }
    },
    qxBtn(){
      this.$root.useRouter.go(-1);
    },
    getInfo(id){//清空表单
      getAppointmentInfo(id).then((res)=>{
        let that=this;
        this.getAppoint(res.data.laboratoryId);
        let zc=res.data.reservationUtensilList;//材料
        let info={id:res.data.id,curriculumId:res.data.curriculumId,laboratoryId:res.data.laboratoryId,userType:res.data.type,date:res.data.reservationTime,
          use:res.data.use,userId:res.data.userId,utensil:[],
          studentPhone: res.data.studentPhone,
          studentNum: res.data.studentNum,
          className: res.data.className,
        };

        // 附件
        const { annex } = res.data
        info.annex = annex
        if (annex) {
          const name = ((annex || '').split('/') || []).reverse()[0]
          this.fileList.push({ name: name, url: annex, value: annex, })
        }

        this.userList[0]=res.data.user;//给用户初始化一个值 以便于回显
        this.Info=info;
        setTimeout(function(){
          if(res.data.type==0){that.userList=that.studentList;}
          if(res.data.type==1){that.userList=that.teacherList;}
          that.Info.userId=res.data.userId;
        },500);
        let checkAssetListArray=[];
        let checkAssetIdsArray=[];
        for(let i in zc){
          let s=zc[i].utensil;
          s.checkNumber=zc[i].number;
          checkAssetIdsArray.push(s.id);
          checkAssetListArray.push(s);
        }
        let curriculumIdArray=info.curriculumId.split(',');
        let dateArray=info.date.split(',');
        for(let i in curriculumIdArray){
          this.checkDateCurrArray.push({date:dateArray[i],curriculumId:curriculumIdArray[i]});
        }
        this.checkAssetList=checkAssetListArray;
        this.assetIds=checkAssetIdsArray;
        //console.log(this.checkAssetList);
      })
    },
    getList1(){
      getRoomList().then((res)=>{
        this.roomList=res.data;
      })
    },
    getList2(){
      getStudentList().then((res)=>{
        this.studentList=res.data;
      })
      getTeacherList().then((res)=>{
        this.teacherList=res.data;
      })
    },
    getList3(){
      getCourseList().then((res)=>{
        this.courseList=res.data;
      })
    },
    getAppoint(e){
      this.checkDateCurrArray=[];//当实验室改变时先初始化数据
      this.checkAssetList=[];
      getAppointCourseInfo(e).then((res)=>{
        this.apppointList=res.data;
      })
      getAssetListAll(e).then((res)=>{
        let HassetList=res.data;
        this.assetList=res.data;
        let H=[];
        for(let i in HassetList){
          H.push({value:HassetList[i].id,label:'【'+(HassetList[i].type==1?'器材':'耗材')+'】'+HassetList[i].name+'('+HassetList[i].number+')',number:HassetList[i].number});
        }
        this.optionsAsset=H;
      })
    },
    getUserList(e){
      if(e==1){this.userList=this.teacherList;}
      else if(e==0){this.userList=this.studentList;}
      else{this.userList=[];}
      this.userList=[];
      this.Info.userId='';//更改角色后 清空用户id
    },
    setCourse(id,flag,date){
      let f=0;
      let k=date;
      this.checkDateCurrArray.find((res,i)=>{
        if(res.date==k && res.curriculumId==id){
          this.checkDateCurrArray.splice(i,1);
          f++;
          return;
        }
      })
      if(flag){
        if(f==0){this.checkDateCurrArray.push({date:k,curriculumId:id})}
      }
    },
    rpoo(id,data){//判断数组是否包含
      let f=0;
      this.checkDateCurrArray.find(res=>{
        if(res.date==data && res.curriculumId==id){
          f++;
          return;
        }
      })
      if(f>0){return 1}else{return 0;}
    },
    chooseAsset(e){//选择资产/删除资产
      let s=e || this.assetIds;
      let checkMyAsset=[];
      for(let i in s){
        let id=s[i];
        let snum=0;
        this.checkAssetList.find(res0=>{
          if(res0.id==id){
            snum=res0.checkNumber;
          }
        })
        this.assetList.find(res=>{
          if(res.id==id){
            res.checkNumber=snum;
            checkMyAsset.push(res);
          }
        })
      }
      this.checkAssetList=checkMyAsset;
    },
    deleteAsset(id,index){//删除已使用的资产
      this.checkAssetList.splice(index,1);
      this.assetIds.splice(this.assetIds.indexOf(id),1);
    },
    subData(){//提交
      if((!this.Info.laboratoryId) || (!this.Info.userId)){
        this.$root.ElMessage.error('实验室和申请人不能为空！');
        return false;
      }
      let utensildfg=[];
      for(let i in this.checkAssetList){
        let s=this.checkAssetList[i];
        utensildfg.push({number:s.checkNumber,utensilId:s.id});
      }
      this.Info.utensil=utensildfg;
      let dates='';
      let curriculumIds='';
      let xxx=0;
      for(let j in this.checkDateCurrArray){
        let s=this.checkDateCurrArray[j];
        if(j==0){dates=s.date;curriculumIds=s.curriculumId;}else{dates=dates+','+s.date;curriculumIds=curriculumIds+','+s.curriculumId}
        /*this.apppointList.find(res=>{
          if(res.date==s.date){
            let courseLk=res.curriculumList;
            courseLk.find(res0=>{
              if(res0.id==s.curriculumId){
                if(res0.isAppoint){
                  if(xxx==0){dates=s.date;curriculumIds=s.curriculumId;}else{dates=dates+','+s.date;curriculumIds=curriculumIds+','+s.curriculumId}
                  xxx++;
                }
              }
            })
          }
        })*/
      }
      this.Info.date=dates;
      this.Info.curriculumId=curriculumIds;
      let method='post';
      //if(this.Info.id){method='put';}

      // 附件
      if (this.fileList.length > 0) {
        this.Info.annex = this.fileList[0].url
      }

      addAppointment(this.Info,method).then((res)=>{
        if(res.success){
          this.$root.ElMessage({type:'success',message:res.message});
          this.$root.useRouter.go(-1);
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    handleAvatarSuccess(e){//图片上传成功后
      this.Info.businessLicenseImg=e.data;
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-form-item{
    display: flex;align-items: center;
    .el-form-item__label{width: 800px !important;color: red;padding: 0 15px 0 0;}
    .room-select-tl{width: 300px;}
    .room-select-al{width: 600px;}
    .room-input-tl{width: 600px;height: 40px}
    .room-input-cl{width: 300px;height: 40px}
    .room-input-qb{width: 100px;height: 40px}
    .el-form-item--large .el-form-item__content{line-height: 16px !important;}
  }
  .title-appoin{color:#333333;font-size: 13px;width: 52px;height: 52px}
  .Time-oclp{padding:6px 12px;height: 32px;width: 32px;margin-bottom: 8px;font-size: 13px}
  .Course-oclp{height: 44px;line-height: 42px;width: 56px;font-size: 13px;margin-bottom: 8px}
  .course-used-allow{background: #EBF0F8;color: #3769B9;cursor: pointer;}
  .course-used-no{background: #F0F0F0;color: #666666;cursor: not-allowed}
  .course-used-check{background: #3769B9 !important;color: #ffffff !important;cursor: default;}
  .box-tip{height: 12px;width: 12px;border-radius: 2px;margin: 0 10px 0 20px}
  .my-form-title{width: 108px;text-align: right;color: #606266;font-size: 14px;padding-right: 12px}
  .form-table-jl{
    width: 600px;
    th{font-size: 14px;}
    td{font-size: 13px;color: #999999}
    input{height: 26px;width: 50px;border: 1px solid #cccccc;outline: none}
  }

.common-file-list-container{
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  .common-file-list-item-left{
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    width: 608px;
    background: #F5F7FA;
    border-radius: 2px;
    font-size: 16px;
  }
  .common-file-list-item-right{
    margin-left: 14px;
    font-size: 16px;
    color: #8D9094;
  }
}
</style>