<template>
  <div class="edit-div-ahover">
    <div class="fontSize20gay text-left">公告信息</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0 90px 0">
      <div style="width: 950px;">
        <el-form label-width="120px">
          <el-form-item size="large" label="标题">
            <el-input v-model="Info.title" autocomplete="off" class="room-input-tl" placeholder="请输入"/>
          </el-form-item>
          <el-form-item size="large" label="公告内容" style="align-items: start">
            <editor @getText="getText" ref="editor" :content="Info.content" style="height: 350px;width: 100%"></editor>
<!--            <el-input v-model="Info.content" autocomplete="off" type="textarea" rows="8" class="room-input-cl" style="width: 600px;height: 180px" placeholder="请输入"/>-->
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="marginTop15px"><button class="subBtn" @click="subData">保存</button><button class="qxBtn" @click="qxBtn">取消</button></div>
  </div>
</template>
<script>
import {addNotice,getNoticeInfo} from "@/api/api";
import editor from "@/components/editor.vue";
export default {
  components:{
    editor
  },
  name: "index",
  props:['Row'],
  data(){
    return{
      Info:{id:''},
    }
  },
  mounted() {
    if(this.$root.useRoute.query.id){
      this.Info.id=this.$root.useRoute.query.id;
      this.getInfo();
    }
  },
  methods:{
    qxBtn(){
      this.$root.useRouter.go(-1);
    },
    getInfo(){
      getNoticeInfo(this.Info.id).then((res)=>{
        this.Info=res.data;
        this.setText();
      })
    },
    subData(){//提交
      if((!this.Info.title) || (!this.Info.content)){
        this.$root.ElMessage.error('标题和内容不能为空！');
        return false;
      }
      let method='post';
      if(this.Info.id){method='put';}
      addNotice(this.Info,method).then((res)=>{
        if(res.success){
          this.$root.ElMessage({type:'success',message:res.message});
          this.$root.useRouter.go(-1);
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    setText(){
      this.$refs.editor.setText(this.Info.content);
    },
    getText(html){
      this.Info.content=html;
    },
    handleAvatarSuccess(e){//图片上传成功后
      this.Info.businessLicenseImg=e.data;
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-form-item{
    display: flex;align-items: center;
    .el-form-item__label{width: 800px !important;color: red;padding: 0 15px 0 0;}
    .room-select-tl{width: 300px;}
    .room-input-tl{width: 600px;height: 40px}
    .room-input-cl{width: 300px;height: 40px}
    .room-input-qb{width: 100px;height: 40px}
  }
</style>