<template>
  <div class="certificate-container">
    <div class="exam-item"
         v-for="iter in certificateList"
         :key="iter.id"
         @click="toCertificateQuery(iter)">
      <div class="flex align-center">
        <div v-show="iter.exam.infinite == 0" class="exam-tag flex justify-center align-center">单次</div>
        <div v-show="iter.exam.infinite == 1" class="exam-tag flex justify-center align-center">不限次</div>
        <div class="exam-title">{{ iter.name }} </div>
      </div>
      <div class="exam-time flex justify-between">
        <div style="line-height: 24px;">
          {{ [iter.exam.startTime, iter.exam.endTime].join(' 至 ') }}
        </div>
        <div style="font-size: 16px;"> <el-icon><ArrowRight /></el-icon> </div>
      </div>
      <div class="exam-score">
        您的分数：{{ iter.score || 0 }}分
      </div>
    </div>
    <div v-show="certificateList.length === 0">
      <el-empty description="您还没有获得证书" />
    </div>
  </div>
</template>

<script>
import {getMyCertificate} from "@/api/examination";

export default {
  name: 'certificateList',
  data(){
    return {
      certificateList: []
    }
  },
  mounted() {
    getMyCertificate().then(res => {
      if (res.success) {
        this.certificateList = res.data
      } else {
        this.$root.ElMessage.error(res.message);
      }
    })
  },
  methods: {
    toCertificateQuery(item){
      this.$root.useRouter.push({ path: `/mobile/user/certificate/query/${item.id}` })
    }
  },
}
</script>

<style lang="scss" scoped>
.certificate-container{
  padding: 16px;
  box-sizing: border-box;
  text-align: left;
  background-color: #f6f6f6;
  min-height: 100vh;
  .exam-item{
    position: relative;
    margin-bottom: 12px;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: #fff;
    .tags-item{
      position: absolute;
      right: 0;
      top: 0;
      padding: 4px 8px;
      border-radius: 0 8px 0 8px;
      font-size: 12px;
      color: #FFFFFF;
    }
    .exam-tag{
      padding: 2px 4px;
      background: #FFFFFF;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid #4A72FF;
      color: #4A72FF;
      font-size: 10px;
      white-space:nowrap;
    }
    .exam-title{
      width: 310px;
      margin-left: 8px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
    }
    .exam-time{
      margin-top: 8px;
      font-size: 13px;
      color: #666666;
    }
    .exam-score{
      margin-top: 8px;
      font-size: 13px;
      color: #666666;
    }
  }
}
</style>