<template>
  <div class="pdf-preview-container">
    <div id="pdfContainer">

    </div>
  </div>
</template>

<script>

export default {
  name: 'PdfPreview',
  data() {
    return {
      pdrUrl: '',
    }
  },
  mounted() {
    const loading = this.$loading({
      lock: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });

    const fileUrl = this.$route.query.fileUrl
    if (fileUrl) {
      // 加载pdf
      const pdfh5 = new Pdfh5('#pdfContainer', {
        pdfurl: fileUrl,
      })

      //监听完成事件
      pdfh5.on("complete", function (status, msg, time) {
        // 加载完成
        loading.close();

        console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
        // success和
        if (status === 'error') {
          this.$root.ElMessage.error('pdf加载失败, 请返回下载学习')
        }
        //禁止手势缩放
        // pdfh5.zoomEnable(false);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pdf-preview-container{
  height: 100vh;
}
</style>