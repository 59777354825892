<template>
  <div class="mobile-body-white">
    <div class="paddingTop80"><img src="@/static/img/logo.png" style="height: 33px"></div>
    <div class="fontTitle">实验室综合管理平台</div>
    <div class="marginTop40">
      <div class="mobile-login-input">
        <img src="@/static/img/mobile/user.png"><input type="text" v-model="loginInfo.username" placeholder="账号">
      </div>
      <div class="mobile-login-input">
        <img src="@/static/img/mobile/lock.png"><input type="password" v-model="loginInfo.password" placeholder="密码">
      </div>
      <div class="flex align-center fontSize14" style="margin: 16px">
        <div>登录角色：</div>
        <el-radio-group v-model="loginInfo.roleType" class="ml-4 marginLeft10">
          <el-radio label="0" size="large">学生</el-radio>
          <el-radio label="1" size="large">教师</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="flex"><button class="loginBtn" @click="subData">登 录</button></div>
    <div class="text-center marginTop50">
      <div class="fontSize14gay99">其他登录方式</div>
      <div><img src="@/static/img/mobile/mobile-logo.png" @click="goThreeLogin" class="img36 marginTop15px"></div>
    </div>
  </div>
</template>
<script>
import {userLogin} from "@/api/mobile";
export default {
  data(){
    return{
      loginInfo:{username:'',password:'',roleType:''},
      ticket:''
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
  },
  methods:{
    subData(){
      console.log(this.loginInfo);
      if(!this.loginInfo.username || !this.loginInfo.password){
        this.$root.ElMessage.error({message: '用户名密码不能为空'});
        return false;
      }
      let loginUrl='';
      let type=parseInt(this.loginInfo.roleType);
      if(type===0){loginUrl='/student/login'}else if(type===1){
        loginUrl='/teacher/login'
      }else{
        this.$root.ElMessage.error({message: '登录角色不存在'});return false;
      }let datafp='username='+this.loginInfo.username+'&password='+this.loginInfo.password;
      userLogin(loginUrl,this.loginInfo).then(res=>{
        if(res.success){
          this.$root.ElMessage({message: res.message,type:'success'});
          window.localStorage.setItem('token',res.data);
          this.$root.useRouter.push("/mobile/index");
        }else{
          this.$root.ElMessage.error({message: res.message});
        }
      })
    },
    goThreeLogin(){//跳转第三方登录
      window.location.href="http://ids.guc.edu.cn/cas?service=https://lab.guc.edu.cn/"
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-login-input{
  background:#F6F6F6;height: 44px;border-radius:4px;height: 44px;display: flex;align-items: center;padding: 10px 16px;margin: auto 16px;margin-top: 20px;
  image{width: 24px;height: 24px}
  input{margin-left: 10px;width: 95%;outline: none;background: transparent;height: 28px;border: 0;font-size: 15px}
}
</style>