<template>
  <div class="mobile-body-white">
    <div class="paddingTop80"><img src="@/static/img/logo.png" style="height: 33px"></div>
    <div class="fontTitle">实验室综合管理平台</div>
    <div class="marginTop40">
      <div class="mobile-login-input">
        <img src="@/static/img/mobile/user.png"><input type="text" v-model="loginInfo.username" placeholder="账号">
      </div>
      <div class="mobile-login-input">
        <img src="@/static/img/mobile/lock.png"><input type="password" v-model="loginInfo.password" placeholder="密码">
      </div>
      <div class="mobile-login-input remember-password">
        <el-checkbox v-model="rememberPassword" label="记住密码" size="large" />
      </div>
    </div>
    <div class="flex">
      <button class="loginBtn" @click="subData">登 录</button>
    </div>
  </div>
</template>
<script>
import {userLogin} from "@/api/mobile";

const passwordKey = {
  key: 'cGFzc3dvcmQ',
  get(){
    try {
      const val = JSON.parse(atob(localStorage.getItem(this.key)))
      if (val && Object.keys(val).includes('username') && Object.keys(val).includes('password')) {
        return val
      }
      return null
    } catch (e) {
      return null
    }
  },
  set(val){
    localStorage.setItem(this.key, btoa(JSON.stringify(val)))
  }
}

export default {
  data() {
    return {
      loginInfo: {
        username: '',
        password: ''
      },
      rememberPassword: false,
    }
  },
  mounted() {
    const val = passwordKey.get()
    if (val) {
      const { username, password, rememberPassword } = val
      this.loginInfo = { username, password }
      this.rememberPassword = rememberPassword
    }
  },
  methods: {
    subData() {
      if (!this.loginInfo.username || !this.loginInfo.password) {
        this.$root.ElMessage.error({message: '用户名密码不能为空'});
        return false;
      }
      userLogin('/admin/login', this.loginInfo).then(res => {
        if (res.success) {
          // 记住密码
          this.rememberPassword && passwordKey.set({...this.loginInfo, rememberPassword: this.rememberPassword})
          //
          this.$root.ElMessage({message: res.message, type: 'success'});
          window.localStorage.setItem('adminToken', res.data);
          this.$root.useRouter.push("/mobile/admin/index");
        } else {
          this.$root.ElMessage.error({message: res.message});
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-login-input {
  background: #F6F6F6;
  height: 44px;
  border-radius: 4px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  margin: auto 16px;
  margin-top: 20px;

  image {
    width: 24px;
    height: 24px
  }

  input {
    margin-left: 10px;
    width: 95%;
    outline: none;
    background: transparent;
    height: 28px;
    border: 0;
    font-size: 15px
  }
}
.remember-password{
  padding-left: 0;
  margin-top: 0;
  height: 30px;
  background-color: transparent;
}
</style>
