<template>
  <el-dialog v-model="dialog_visible" modal-class="monitor-dialog-modal" custom-class="monitor-dialog"
    :close-on-click-modal="false" :close-on-press-escape="false" align-center :lock-scroll="false" :show-close="false"
    :destroy-on-close="true"
    :style="'background-image: url(' + monitor_dialog_bg + ');background-size: cover'">
    <template #header>
      <div class="monitor-dialog-header">
        <div class="monitor-dialog-title">视频监控</div>
        <div style="opacity: 0;">
          <audio ref="audioRef" loop src="" @ended="musicEnd"></audio>
        </div>
        <div class="monitor-dialog-close" @click="close"></div>
      </div>
    </template>
    <div class="monitor-dialog-content flex-direction">
      <div class="top flex">
        <div class="title">
          <img style="width: 40px;height: 40px;object-fit: cover;" :src="iconShow(currWarningEvent)" alt="">
          <span v-if="currWarningEvent"> {{ currWarningEvent.warningEventVo.warningEventType.name }} </span>
        </div>
        <div class="tab">
          <!-- 选中 changeBtn -->
          <button :class="{ 'changeBtn': isVideo }" @click="changeMode('REAL_TIME_VIDEO')">实时视频</button>
          <button :class="{ 'changeBtn': isImage }" @click="changeMode('DETAILS_GRAPHICS')">应急预案</button>
        </div>
      </div>
      <div class="center flex">
        <el-scrollbar class="slider">
          <div class="camera-list">
            <div v-for="(iter, index) in warningEventsList" :key="index"
                 class="camera-item"
                 :class="{ 'selected' : iter.selected }"
                 @click="selectWarningEvent(iter)">
              {{ iter.sideName }}
            </div>
          </div>
        </el-scrollbar>
        <div class="main">
          <div v-show="isVideo" class="plan">
            <div v-for="(iter, index) in warningEventsList" :key="index" :id="iter.player.elementId"
                 v-show="currWarningEvent && currWarningEvent.player.elementId === iter.player.elementId"
                 style="width: 100%;height: 100%;object-fit: contain;"></div>
          </div>
          <div v-if="isImage && currWarningEvent">
            <div class="plan" v-html="currWarningEvent.warningEventPlanVo.plan"></div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <button @click="releaseWarningAction">解除预警</button>
        <button @click="close">关闭</button>
      </div>
    </div>
  </el-dialog>
  <el-dialog v-model="small_dialog_visible" modal-class="monitor-dialog-modal"
             custom-class="monitor-small-dialog"
             :close-on-click-modal="false" :close-on-press-escape="false"
             align-center :show-close="false" :lock-scroll="false"
             :style="'background-image: url(' + monitor_dialog_small_bg + ');background-size: cover'">
    <template #header>
      <div class="monitor-dialog-headers">
        <div class="monitor-dialog-title">处理意见</div>
        <div class="monitor-dialog-close" @click="small_dialog_visible = false"></div>
      </div>
    </template>
    <div class="monitor-dialog-contents">
      <div class="main flex align-center">
        <div class="main-top flex">
          <span style="margin-top: 9px;">预置式选择分类：</span>
          <div>
            <el-select v-model="formData.warningEventOpinionId" :teleported="false" placeholder="请选择" size="large" style="width: 466px;" >
              <el-option v-for="item in warningProcessing" :key="item.id" :label="item.content" :value="item.id" />
            </el-select>
          </div>
        </div>
        <div class="main-top flex" v-show="selectedOther()" >
          <span style="margin-top: 9px;">处理意见：</span>
          <textarea v-model="formData.closeMsg" type="textarea" placeholder="请输入处理意见" maxlength=100></textarea>
        </div>
        <div class="main-footer">
          <button @click="small_dialog_visible = false">取消</button>
          <button @click="submit">确定</button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {queryDataViewSetting, queryWarningEvent, warningEventClose, warningEventOpinionAll} from "@/api/monitor";
import {projectName} from "@/utils/config";
import monitorStore, {FlvPlayer} from "@/utils/monitor";
export default {
  name: 'warningDialog',
  data() {
    return {
      projectName,
      monitor_dialog_bg: require('@/static/img/monitor/monitor-dialog-bg.png'),
      dialog_visible: false,
      equipmentList: [],
      //
      playerList: [],
      // 当前正在播放
      currPlayId: '',
      // ---------------------------------------
      // 显示模式 图文详情->DETAILS_GRAPHICS, 实时视频->REAL_TIME_VIDEO
      displayMode: 'REAL_TIME_VIDEO',
      // 预警事件列表
      warningEventsList: [],
      // 当前事件/当前轮播的iter
      currWarningEvent: null,
      // 轮播timer
      carouselTimer: '',
      // -
      small_dialog_visible: false,
      monitor_dialog_small_bg: require('@/static/img/monitor/monitor-dialog-small-bg.png'),
      formData: {
        closeMsg: "",
        warningEventId: null,
        warningEventOpinionId: '',
      },
      // 关于音频
      playMusicChange: false,
      //
      warningProcessing: [],
    }
  },
  computed: {
    isImage(){
      return this.displayMode === 'DETAILS_GRAPHICS'
    },
    isVideo(){
      return this.displayMode === 'REAL_TIME_VIDEO'
    },
  },
  mounted() {
    // this.getWarningEventList()
    this.getWarningEventOpinionAll()
  },
  methods: {
    selectedOther(){
      const item = this.warningProcessing.find(iter => iter.id === this.formData.warningEventOpinionId)
      if (item) {
        return item.type === 1
      }
      return false
    },
    getWarningEventOpinionAll(){
      warningEventOpinionAll().then(res => {
        if (res.success) {
          this.warningProcessing = []
          Array.isArray(res.data) && res.data.forEach(iter => {
            const { content, type, id } = iter
            this.warningProcessing.push({ content, type, id  })
          })
        }
      })
    },
    // 音频播放结束
    musicEnd() {
      this.playMusicChange = false
    },
    /**
     * 播放警报音频
     */
    playMusic(path){
      try {
        // 设置音频
        this.$refs.audioRef.src = `${projectName}${path}`
        // 播放
        this.$refs.audioRef.play()
      } catch (e) {
        console.log(e, '---------------e 等待用户交互')
      }
    },
    /**
     * 预警图标
     * @param pathObj
     */
    iconShow(pathObj){
      if (pathObj) {
        const path = pathObj?.warningEventPlanVo?.iconPath
        if (path) {
          return this.projectName + path
        }
      }
      return require('@/static/img/monitor/alert.png')
    },
    /**
     * 解除预警
     */
    submit(){
      warningEventClose(this.formData).then(res => {
        if (res.success) {
          this.small_dialog_visible = false
          this.$message.success('预警已解除')
          setTimeout(() => {
            this.getWarningEventList()
          }, 500)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 预警事件列表
     */
    getWarningEventList(){
      queryWarningEvent().then(res => {
        if (res.success) {
          let index = 0
          if (this.warningEventsList.length > 0) {
            // 销毁
            this.destructionAll()
          }
          this.warningEventsList.length = 0
          res.data.forEach(iter => {
            iter.settings.forEach(item => {
              this.warningEventsList.push({
                sideName: iter.buildIngName + item.laboratory.name,
                buildingId: iter.buildingId,
                selected: false,
                player: new FlvPlayer( item.warningEventVo.equipmentId,`warningVideo${index}`),
                ...item,
              })
              index += 1
            })
          })
          // 没有预警就隐藏不显示
          if (this.warningEventsList.length === 0) {
            this.close()
            return
          }
          // 生成顺序轮播索引
          this.warningEventsList.map((iter, index) => {
            iter.nextIndex = index + 1
            // 关闭截图
            iter.player.setShowScreenshot(false)
            // 静音自动播放
            iter.player.setVolume(0)
          })
          if (this.warningEventsList.length > 0) {
            this.warningEventsList[this.warningEventsList.length-1].nextIndex = 0
          }
          // console.log(this.warningEventsList, '--------this.warningEventsList--');
          // 播放第一项
          this.$nextTick(() => {
            if (this.warningEventsList.length > 0) {
              this.selectWarningEvent(this.warningEventsList[0])
            }
          })
          // 大于两项启动顺序轮播
          queryDataViewSetting().then(r => {
            if (r.success) {
              const frequency = parseInt(r.data.frequency)
              if (!isNaN(frequency)) {
                // 先清空
                this.carouselTimer && clearInterval(this.carouselTimer)
                // 定时器
                this.carouselTimer = setInterval(() => {
                  if (this.currWarningEvent) {
                    // 获取下一个 并轮播切换
                    const nextElementIndex = this.currWarningEvent.nextIndex
                    const nextElement = this.warningEventsList[nextElementIndex]
                    this.selectWarningEvent(nextElement)
                  }
                }, frequency * 1000)
              }
            }
          })
        }
      })
    },
    /**
     * 销毁所有视频
     */
    destructionAll(){
      // 销毁连接
      this.warningEventsList.forEach(iter => {
        if (iter.player.flvPlayer) {
          iter.player.destruction()
        }
      })
      // 销毁定时器
      clearInterval(this.carouselTimer)
    },
    /**
     * 点击解除预警
     */
    releaseWarningAction(){
      this.small_dialog_visible = true
      // 记录预警id
      this.formData.warningEventId = this.currWarningEvent.warningEventVo.id
    },
    /**
     * 选择事件
     */
    selectWarningEvent(iter){
      // 选中
      this.warningEventsList.forEach(item => item.selected = false)
      iter.selected = true
      // 全部销毁
      this.warningEventsList.forEach(iter => {
        if (iter.player.flvPlayer) {
          iter.player.destruction()
          iter.player.flvPlayer = null
        }
      })
      //
      this.currWarningEvent = iter
      //
      // 默认设置为实时视频
      this.displayMode = 'REAL_TIME_VIDEO'
      // 播放当前实时视频
      iter.player.play()
      // 播放警告音频
      this.playMusic(iter.warningEventPlanVo.soundPath)
    },
    /**
     * 切换显示模式
     */
    changeMode(val){
      this.displayMode = val
    },
    /**
     * 开启弹层
     */
    open(){
      this.getWarningEventList()
      this.dialog_visible = true
    },
    /**
     * 关闭弹层
     */
    close() {
      this.destructionAll()
      this.dialog_visible = false
      monitorStore.getElasticLayerQueue().push('closeWarningLayer')
      this.$emit('close', this.formData.warningEventId)
    },
  }
}
</script>

<style lang="scss">
.monitor-dialog {
  --el-dialog-width: 1420px;
  height: 800px;
  --el-dialog-bg-color: transparent;
  --el-box-shadow: none;

  .el-dialog__body {
    padding: 0 !important;
  }
}
.monitor-small-dialog {
  --el-dialog-width: 800px!important;
  height: 450px!important;
  --el-dialog-bg-color: transparent!important;
  --el-box-shadow: none;

  .el-dialog__body {
    padding: 0 !important;
  }
}

</style>

<style scoped lang="scss">
.video-player {
  width: 100%;
  height: 100%;

  .video-player {
    width: 100%;
    height: 100%;
  }
}

.monitor-dialog-header {
  position: relative;
  width: 100%;
  height: 64px;

  .monitor-dialog-title {
    position: absolute;
    top: 9px;
    left: 20px;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: #009DFF;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .monitor-dialog-close {
    position: absolute;
    right: -10px;
    top: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.monitor-dialog-headers {
  position: relative;
  width: 100%;
  height: 64px;

  .monitor-dialog-title {
    position: absolute;
    top: 9px;
    left: 20px;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: #009DFF;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .monitor-dialog-close {
    position: absolute;
    right: -10px;
    top: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.monitor-dialog-content {
  width: 1400px;
  height: 736px;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;

  .top {
    text-align: left;
    padding: 10px 0 20px 0;

    .title {
      display: flex;
      align-items: center;
      width: 240px;

      >img {
        margin-right: 8px;
      }

      >span {
        color: #FF386B;
        font-size: 16px;
      }
    }

    .tab {
      >button {
        margin-left: 20px;
        width: 140px;
        height: 40px;
        font-size: 16px;
        color: #80CEFF;
        background-color: rgba(0, 157, 255, .1);
        border: 1px solid #002849;
        cursor: pointer;
      }

      .changeBtn {
        color: #009DFF;
        border: 1px solid #009DFF;
        background-color: rgba(0, 157, 255, .3);
        font-weight: 600;
      }
    }
  }

  .center {
    height: 576px;

  }

  .slider {
    width: 240px;
    height: 576px;

    .camera-list {
      .camera-item {
        width: 240px;
        height: 56px;
        padding: 0 16px;
        font-size: 16px;
        font-weight: bold;
        line-height: 56px;
        background: rgba(0, 157, 255, 0.1);
        color: #80CEFF;
        cursor: pointer;

        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.selected {
          background: rgba(0, 157, 255, 0.3);
          color: #009DFF;
        }

        &+.camera-item {
          margin-top: 16px;
        }
      }
    }
  }

  .main {
    width: 1100px;
    height: 100%;
    margin-left: 20px;
    border: 1px solid #009DFF;

    .plan {
      text-align: left;
      width: 1100px;
      height: 100%;
      background: #060C16;
      box-sizing: border-box;
      font-size: 14px;
      color: #fff;
      padding: 20px;
    }
  }

  .bottom {
    text-align: right;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;

    >button {
      width: 160px;
      height: 40px;
      border-radius: 20px 20px 20px 20px;
      font-size: 14px;
      margin-left: 20px;
      cursor: pointer;
    }

    :first-child {
      color: #FF386B;
      box-shadow: inset 0px 0px 4px 0px #FF386B;
      background: rgba(255, 56, 107, 0.1);
      border: 1px solid #FF386B;
    }

    :last-child {
      background: rgba(0, 157, 255, 0.1);
      box-shadow: inset 0px 0px 4px 0px #009DFF;
      opacity: 1;
      border: 1px solid #009DFF;
      color: #009DFF;
    }
  }
}

.monitor-dialog-contents {
  --el-text-color-regular: #B4C0CC;
  --el-fill-color-blank: rgba(0, 0, 0, 0);
  --el-border-color: #009DFF;
  --el-border-color-hover: #009DFF;
  --el-select-input-focus-border-color: #009DFF;
  --el-color-primary: #009DFF;
  --el-select-border-color-hover: #009DFF;
  --el-input-focus-border-color: #009DFF;
  --el-input-hover-border-color: #009DFF;
  --el-text-color-disabled: #009DFF;
  // 下拉框背景
  --el-bg-color-overlay: #00000a;
  --el-fill-color-light: #001e38;

  --el-color-white: #ffffff;
  --el-color-black: #000000;
  --el-color-primary-light-3: #79bbff;
  --el-color-primary-light-5: #a0cfff;
  --el-color-primary-light-7: #c6e2ff;
  --el-color-primary-light-8: #d9ecff;
  --el-color-primary-light-9: #ecf5ff;
  --el-color-primary-dark-2: #337ecc;

  --el-bg-color: #ffffff;
  --el-bg-color-page: #f2f3f5;
  --el-text-color-primary: #303133;
  --el-text-color-secondary: #909399;
  --el-text-color-placeholder: #a8abb2;
  --el-border-color-light: #009DFF;
  --el-border-color-lighter: #009DFF;
  --el-border-color-extra-light: #009DFF;
  --el-border-color-dark: #009DFF;
  --el-border-color-darker: #009DFF;
  --el-fill-color: #009DFF;
  //--el-fill-color-lighter: #009DFF;
  --el-fill-color-extra-light: #009DFF;
  --el-fill-color-dark: #009DFF;
  --el-fill-color-darker: #009DFF;
  --el-box-shadow: 0px 12px 32px 4px rgba(0, 0, 0, 0.04), 0px 8px 20px rgba(0, 0, 0, 0.08);
  --el-box-shadow-light: 0px 0px 12px rgba(0, 0, 0, 0.12);
  --el-box-shadow-lighter: 0px 0px 6px rgba(0, 0, 0, 0.12);
  --el-box-shadow-dark: 0px 16px 48px 16px rgba(0, 0, 0, 0.08), 0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  --el-disabled-bg-color: var(--el-fill-color-light);
  --el-disabled-text-color: var(--el-text-color-placeholder);
  --el-disabled-border-color: var(--el-border-color-light);
  --el-overlay-color: rgba(0, 0, 0, 0.8);
  --el-overlay-color-light: rgba(0, 0, 0, 0.7);
  --el-overlay-color-lighter: rgba(0, 0, 0, 0.5);
  --el-mask-color: rgba(255, 255, 255, 0.9);
  --el-mask-color-extra-light: rgba(255, 255, 255, 0.3);
  --el-border-width: 1px;
  --el-border-style: solid;
  --el-border: var(--el-border-width) var(--el-border-style) var(--el-border-color);
  --el-svg-monochrome-grey: var(--el-border-color);


  .main {
    flex-direction: column;

    &-top {
      margin-top: 10px;

      >span {
        color: #80CEFF;
        font-size: 14px;
      }

      >textarea {
        resize: none;
        box-sizing: border-box;
        width: 480px;
        height: 248px;
        background: #001F32;
        border-radius: 5px 5px 5px 5px;
        color: #fff;
        outline: none;
        border: none;
        padding: 9px 12px;

        // overflow-y: hidden;
        &::placeholder {
          color: #B4C0CC;
          font-size: 14px;
        }
      }

    }

    &-footer {
      margin-top: 60px;

      >button {
        width: 160px;
        height: 40px;
        border-radius: 20px;
        cursor: pointer;
      }

      :first-child {
        border: 1px solid #009DFF;
        background: rgba(0, 157, 255, 0.1);
        color: #B4C0CC;
        margin-right: 40px;
      }

      :last-child {
        border: 1px solid #009DFF;
        background: #009DFF;
        color: #fff;
      }
    }
  }
}
</style>