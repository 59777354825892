<template>
  <div class="mobile-body">
    <div class="paddingTop80"><img src="@/static/img/logo.png" style="height: 33px"></div>
    <div class="fontTitle">实验室综合管理平台</div>
    <el-scrollbar class="agree-div-content" max-height="300px">
      <div class="fontSize15gayB text-center">预约须知</div>
      <div class="fontSize13gay marginTop8px text-left" style="line-height: 30px" v-html="text"></div>
    </el-scrollbar>
    <div><button class="agreeBtn marginTop40" @click="goAuthLogin">同意并开始预约</button></div>
    <div class="marginTop20px"><img src="@/static/img/mobile/Frame.png"></div>
  </div>
</template>
<script>
import {getText} from "@/api/mobile";
export default {
  data(){
    return{
      text:'',
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    this.getSystemText();
  },
  methods:{
    getSystemText(){//获取须知
      getText().then(res=>{
        this.text=res.data.value;
      })
    },
    goAuthLogin(){
      let token=localStorage.getItem('token');
      if(token) {
        this.$root.useRouter.push("/mobile/index");
      }else{
        this.$root.useRouter.push("/mobile/login");
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .agree-div-content{
    width: calc(80% - 32px);background: #FFFFFF;border-radius: 12px;padding: 16px;margin: 24px 10%;
  }
</style>