import {read, utils} from "xlsx";
import dayjs from "dayjs";

export const integerParse = function (val, set){
  const res = parseInt(val)
  if (isNaN(res)) {
    set('')
  } else {
    set(res)
  }
}

export const floatParse = function (val, set){
  const res = parseFloat(val)
  if (isNaN(res)) {
    set('')
  } else {
    set(res)
  }
}

export const patrolInspectionParams = function (params){
  const keys = {
    '合格': 1,
    '不合格': 0
  }
  const data = params
  for (let key in data) {
    if (Object.keys(keys).includes(data[key])) {
      data[key] = keys[data[key]]
    }
  }
  return data
}

/**
 * 数值化: 133233 -> 133,233
 * @param number
 */
export const numerization = number => {
  return new Intl.NumberFormat().format(number)
}

/**
 * blob转file
 * @param blob
 * @returns {File}
 */
export const blobToFile = blob => {
  return new File([blob], 'file', {type: blob.type, lastModified: Date.now()});
}

/**
 * @param blob
 * @param cb
 */
export const fileToDataURL = (blob, cb) => {
  let reader = new FileReader()
  reader.onload = function (evt) {
    let base64 = evt.target.result
    cb(base64)
  }
  reader.readAsDataURL(blob)
}

export const base64ToBlob = code => {
  const parts = code.split(';base64,')
  const contentType = parts[0].split(':')[1]
  const raw = window.atob(parts[1])
  const rawLength = raw.length
  const uInt8Array = new Uint8Array(rawLength)
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }
  return new Blob([uInt8Array], { type: contentType })
}

/**
 * base64转File
 */
export const base64ToFile = val => blobToFile(base64ToBlob(val))

export const readExcelByOne = file => {
  const fileReader = new FileReader()
  return new Promise((resolve, reject) => {
    fileReader.onload = ev => {
      try {
        const data = ev.target.result;
        const workbook = read(data, {
          type: "binary"
        });
        // 取第一张表
        const wsName = workbook.SheetNames[0]
        // 生成json表格内容
        const ws = utils.sheet_to_json(workbook.Sheets[wsName])
        resolve(ws)
      } catch (e) {
        console.log(e, '-------e')
        reject(null)
      }
    }
    fileReader.readAsBinaryString(file)
  })
}

/**
 * 账号状态
 */
export const accountStatus = date => {
  if (!date) {
    return true
  }
  if (dayjs(date).isBefore(dayjs())) {
    return true
  }
  return false
}


