import {getMessageWs, wsUrl} from "@/utils/config";
import {blobToFile} from "@/utils/common";
import {reactive, createApp} from "vue";
import ScreenRecording from "@/view/visualization/components/ScreenRecording";
import {getEquipmentById} from "@/api/monitor";
import { ElMessage } from 'element-plus'

export const getToken = () => window.localStorage.getItem('token')

export const getMobileToken = () => window.localStorage.getItem('adminToken')

export const isObject = obj => Object.prototype.toString.call(obj) === '[object Object]'

export const debounce = function (fn, delay, immediate = true) {
  let timer = null
  return function (...args) {
    if (timer !== null) {
      clearTimeout(timer)
    }
    if (immediate) {
      if (timer === null) {
        fn.apply(this, args)
      }
      timer = setTimeout(() => {
        timer = null
      }, delay)
    } else {
      timer = setTimeout(() => {
        fn.apply(this, args)
      }, delay)
    }
  }
}

/**
 * 判断客户端
 * @returns {string}
 */
export const judgeClient = function (){
  let client = '';
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
    client = 'iOS';
  } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
    client = 'Android';
  } else {
    client = 'PC';
  }
  return client;
}

/**
 * 判断是否ios
 */
export const isIos = () => judgeClient() === 'iOS'

export class Mp4Player{
  constructor(url, elementId) {
    this.elementId = elementId
    this.url = url
  }

  play(){
    const videoElement = document.getElementById(this.elementId)
    this.flvPlayer = new window.DPlayer({
      container: videoElement,
      lang: 'zh-cn',
      live: false,
      autoplay: true,
      hotkey: false,
      // 播放斥
      mutex: true,
      video: {
        type: 'auto',
        url: this.url,
      },
    })
  }

  destruction(){
    try {
      this.flvPlayer.destroy()
    } catch (e){
      console.log('destruction exception: ', e)
    }
  }
}

/**
 * 直播
 */
export class FlvPlayer{
  constructor(id, elementId) {
    // -
    // 摄像头id
    this.id = id
    // dom元素id
    this.elementId = elementId
    // 当前播放对象
    this.flvPlayer = null
    // 当前截图
    this.currScreenshotBlob = ''
    this.currScreenshotFile = ''
    // 右键菜单
    this.contextmenu = [
      // {
      //   text: 'keli',
      //   click: (player) => {
      //     console.log(player);
      //   },
      // },
    ]
    // 是否开启截图
    this.showScreenshot = true
    // 默认音量
    this.volume = 0.7
    // 默认含音频
    this.hasAudio = true
  }

  async init() {
    const videoElement = document.getElementById(this.elementId)
    const token = getToken()
    const url = `${wsUrl}/websocket/camera/real-play/${this.id}/${token}`
    await this.detectingAudio(url, videoElement)
  }

  async detectingAudio(url, videoElement) {
    const res = await getEquipmentById(this.id)
    if (res.success) {
      this.hasAudio = res.data.hasAudio !== false
    } else {
      this.hasAudio = true
      ElMessage.error(res.message)
    }
    this._createPlayer(url, videoElement)
  }

  _createPlayer(url, videoElement){
    this.flvPlayer = new window.DPlayer({
      container: videoElement,
      lang: 'zh-cn',
      live: true,
      autoplay: false,
      screenshot: this.showScreenshot,
      hotkey: false,
      // 播放斥
      mutex: false,
      video: {
        type: 'flv',
        url: url,
      },
      // 右键菜单
      contextmenu: this.contextmenu,
      // 设置音量
      volume: this.volume,
      pluginOptions: {
        flv: {
          // refer to
          // https://github.com/bilibili/flv.js/blob/master/docs/api.md#flvjscreateplayer
          mediaDataSource: {
            // mediaDataSource config
            hasAudio: this.hasAudio,
          },
          config: {
            // config
            lazyLoad: false,
            // lazyLoadMaxDuration: 5 * 60
          },
        }
      }
    })

    if (this.flvPlayer) {
      //
      const createInsertElement = id => {
        const parent = document.getElementById(id)
        parent.style.display = 'none'

        this.recordingIconContainer = parent
        this.showRecording = () => {
          this.recordingIconContainer.style.display = 'block'
        }
        this.hideRecording = () => {
          this.recordingIconContainer.style.display = 'none'
        }

        createApp(ScreenRecording).mount(`#${id}`)
      }

      const createInsertElementParent = parent => {
        const div = document.createElement("div")
        div.style.position = 'absolute'
        div.style.zIndex = 2008
        div.style.top = 0
        div.setAttribute("id", `${this.elementId}RECORDING`)
        parent.appendChild(div)
        createInsertElement(`${this.elementId}RECORDING`)
      }

      const getInsertElementParent = () => {
        const parent = document.getElementById(this.elementId)
        parent.style.position = "relative";
        createInsertElementParent(parent)
      }

      getInsertElementParent()

      // play
      this.flvPlayer.on('play', () => {
        console.log('play---开始播放!');
        Reflect.has(this, 'onPlayHandlerHandler') && this.onPlayHandlerHandler()
      })
      // playing
      this.flvPlayer.on('playing', () => {
        console.log('playing---开始播放!');
      })
      // progress
      this.flvPlayer.on('progress', () => {
        console.log('progress---开始播放!');
        Reflect.has(this, 'onProgressHandler') && this.onProgressHandler()
      })
      // 截图事件
      this.flvPlayer.on('screenshot', (image) => {
        if (image) {
          this.currScreenshotFile = blobToFile(image)
          this.currScreenshotBlob = image
          this.screenshotHandler(image, this)
          // 退出全屏
          const is_full_screen = document.fullscreenElement ? true : false
          is_full_screen && this.flvPlayer.fullScreen.cancel('browser')
        }
      })

      // 开启全屏
      this.flvPlayer.on('fullscreen', () => {
        Reflect.has(this, 'openFullscreen') && this.openFullscreen()
      })

      // 取消全屏
      this.flvPlayer.on('fullscreen_cancel', () => {
        Reflect.has(this, 'openFullscreenCancel') && this.openFullscreenCancel()
      })
    }
  }

  /**
   *
   * @param handler
   */
  onPlayHandler(handler){
    this.onPlayHandlerHandler = handler
  }

  /**
   * 进度
   * @param handler
   */
  onProgress(handler){
    this.onProgressHandler = handler
  }

  /**
   * 保存截图
   */
  saveScreenshotBlob(){
    const dataURL = URL.createObjectURL(this.currScreenshotBlob);
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'DPlayer.png';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(dataURL);
  }

  /**
   * 设置音量
   */
  setVolume(val){
    this.volume = val
  }

  /**
   * 是否开启截图热键
   * @param val
   */
  setShowScreenshot(val){
    this.showScreenshot = val
  }

  /**
   * 设置右键菜单
   */
  setContextmenu(menu){
    this.contextmenu = menu
  }

  /**
   * 追加右键菜单
   */
  appendContextmenu(menuItem){
    this.contextmenu.push(menuItem)
  }

  /**
   * 事件截屏
   */
  screenshot(handler){
    this.screenshotHandler = handler
  }

  /**
   * 全屏事件
   */
  setFullscreenHandler(open, close){
    this.openFullscreen = open
    this.openFullscreenCancel = close
  }

  /**
   * 销毁|断开
   */
  destruction(){
    try {
      this.flvPlayer.destroy()
    } catch (e){
      console.log('destruction exception: ', e)
    }
  }

  /**
   * 暂停
   */
  suspend(){
    this.flvPlayer.pause()
  }

  /**
   * 播放
   */
  async play() {
    if (this.flvPlayer === null) {
      await this.init()
      this.flvPlayer.play()
    } else {
      this.flvPlayer.play()
    }
  }

  /**
   * 手动截图
   * @param handler
   */
  manualScreenshot(handler){
    this.flvPlayer.screenshot(handler)
  }
}

/**
 * 回放
 */
export class PlaybackFlvPlayer extends FlvPlayer{
  constructor(id, elementId, start, end) {
    super(id, elementId)
    this.start = start
    this.end = end
  }

  async init() {
    const videoElement = document.getElementById(this.elementId)
    const token = getToken()
    const url = `${wsUrl}/websocket/camera/recording/${this.id}/${this.start}/${this.end}/${token}`
    await this.detectingAudio(url, videoElement)
  }
}

/**
 * 移动端直播
 */
export class MobileFlvPlayer extends FlvPlayer{
  constructor(id, elementId) {
    super(id, elementId)
  }

  init() {
    const videoElement = document.getElementById(this.elementId)
    const token = getMobileToken()
    const url = `${wsUrl}/websocket/camera/real-play/${this.id}/${token}`
    // 判断是否ios
    if (isIos()) {
      this._createPlayerIos(url, videoElement)
    } else {
      this._createPlayer(url, videoElement)
    }
  }

  _createPlayerIos(url, videoElement){
    this.flvPlayer = new window.FlvPlayer({
      container: videoElement,
      url: url,
      decoder: 'flvplayer-decoder-multiple.js',
      debug: false,
      live: true,
      autoPlay: true,
      hasAudio: true,
    })
  }
}

/**
 * 移动端回放
 */
export class MobileFlvPlayerPlayback extends MobileFlvPlayer{
  constructor(id, elementId, start, end) {
    super(id, elementId)
    this.start = start
    this.end = end
  }
  init(){
    const videoElement = document.getElementById(this.elementId)
    const token = getMobileToken()
    const url = `${wsUrl}/websocket/camera/recording/${this.id}/${this.start}/${this.end}/${token}`
    // 判断是否ios
    if (isIos()) {
      this._createPlayerIos(url, videoElement)
    } else {
      this._createPlayer(url, videoElement)
    }
  }
}

class ElasticLayerQueue{
  constructor() {
    this.data = reactive([])
  }

  push(event){
    if (this.data.length === 6) {
      this.pop()
    }
    this.data.unshift(event)
  }

  pop(){
    this.data.pop()
  }

  shift(){
    this.data.shift()
  }

  getPrev(){
    return this.data[0]
  }
}

class MonitorStore{
  constructor() {
    this.data = reactive({
      warningEvent: [],
      // 录屏显示
      showImgBase64: '',
      // 待上传录屏对象
      currVideoCoverEvent: null,
      //
      elasticLayerQueue: new ElasticLayerQueue(),
    })
  }

  getElasticLayerQueue(){
    return this.data.elasticLayerQueue
  }

  getCurrVideoCoverEvent(){
    return this.data.currVideoCoverEvent
  }

  setCurrVideoCoverEvent(val){
    this.data.currVideoCoverEvent = val
  }

  getShowImgBase64(){
    return this.data.showImgBase64
  }

  setShowImgBase64(val){
    this.data.showImgBase64 = val
  }

  setWarningEvent(val){
    this.data.warningEvent = val
  }

  getWarningEvent(){
    return this.data.warningEvent
  }
}

const monitorStore = new MonitorStore()

/**
 * 预警事件监听 web socket
 */
class MessageSocket{
  constructor() {
    this.socket = null
    this.temer = null
    this.reconnectionTimer = null
    this.receiveMessageHanler = []
  }

  init(){
    const token = getToken()
    if (token) {
      const url = getMessageWs(token)
      this.socket = new WebSocket(url)
      this.socket.addEventListener('open', event => this.open(event))
      this.socket.addEventListener('message', event => this.receiveMessage(event))
      this.socket.addEventListener('close', event => this.closeHandler(event))

      this.temer = setInterval(() => {
        this.socket && this.socket.send('HEAD')
      }, 10000)

      // 如果已建立websocket, 并且存在重连事件则销毁重连
      if (this.socket && this.reconnectionTimer) {
         clearInterval(this.reconnectionTimer)
      }
    }
    // console.log('--------模拟消息推送 准备')
    // setTimeout(() => {
    //   console.log('--------模拟消息推送')
    //   this.receiveMessage({
    //     data: JSON.stringify({
    //       type: 'warning_event'
    //     })
    //   })
    // }, 10000)
  }

  open(event){

  }

  closeHandler(event){
    console.log(event)
    this.socket = null
    clearInterval(this.temer)
    // 重连
    this.restoreConnection()
  }

  restoreConnection(){
    this.reconnectionTimer = setInterval(() => {
      if (this.socket) {
        clearInterval(this.reconnectionTimer)
        return
      }
      this.init()
    }, 3000)
  }

  receiveMessage(event){
    const data = JSON.parse(event.data)
    this.receiveMessageHanler.forEach(key => {
      Reflect.has(this, key) && this[key](data)
    })
  }

  /**
   * 设置监听收到消息时的事件
   * @param eventName
   * @param handler
   */
  setReceiveMessageHandler(eventName, handler){
    const key = 'set' + eventName.charAt(0).toUpperCase() + eventName.slice(1)
    if (this.receiveMessageHanler.includes(key)) {
      console.log('重复的事件名: ', key)
      return
    }
    this.receiveMessageHanler.push(key)
    this[key] = handler
  }
}

export class VideotapeTask{
  constructor() {
    this.task = [
      // {
      //   id: '',
      //   value: {}
      // }
    ]
  }

  hasTask(){
    return this.task.length > 0
  }

  getTask(id){
    const index = this.task.findIndex(iter => iter.id === id)
    if (index !== -1) {
      const item = this.task[index]
      this.task.splice(index, 1)
      return item
    }
    return null
  }

  setTask(val){
    const index = this.task.findIndex(iter => iter.id === val.id)
    if (index !== -1) {
      this.task.splice(index, 1)
      return
    }
    this.task.push(val)
  }
}

export const messageSocket = new MessageSocket()

export default monitorStore
