<template>
  <div class="mobile-body">
    <div class="admin-content">
      <el-carousel :height="carouselHeight" autoplay class="admin-banner" arrow="never" indicator-position="none">
        <el-carousel-item>
          <img src="@/static/img/admin/index-banner1.png" alt="" @click="toAppointment">
        </el-carousel-item>
      </el-carousel>
      <div class="admin-navs">
        <div class="nav-box">
          <div v-if="hasVideoAuth" class="nav-div linear-blue" @click="toPage('/mobile/admin/monitorVideo')">
            <img src="@/static/img/admin/index-video.png" alt="">
            <div class="name">视频监控</div>
          </div>
          <div v-if="hasVideoAuth" class="nav-div linear-red" @click="toPage('/mobile/admin/warningFile')">
            <img src="@/static/img/admin/index-record.png" alt="">
            <div class="name">预警档案</div>
          </div>
          <div class="nav-div linear-red" @click="toPage('/mobile/admin/appoinment')">
            <img src="@/static/img/admin/index-yuyue.png" alt="">
            <div class="name">实验室预约</div>
          </div>
          <div class="nav-div linear-red" @click="toPage('/mobile/admin/patrolInspection')">
            <img src="@/static/img/admin/index-xunjian.png" alt="">
            <div class="name">巡检管理</div>
          </div>
        </div>
      </div>
      <div class="camera-list">
        <div class="title">摄像头列表</div>
        <div class="content" v-if="dataList.length > 0">
          <div class="camera-div" v-for="item in dataList" :key="item.equipmentId"
               @click="toMonitor(item)">
            <img src="@/static/img/admin/index-camera.png" alt="" class="left-img">
            <div class="right-info">
              <div class="name">{{ item.name || "" }} {{ item.laboratorys.length }}</div>
              <div class="desc">
                <div class="info" v-if="item.laboratorys && item.laboratorys.length>0">
                  <span v-for="(lab,index) in item.laboratorys" :key="index">{{ lab.name }} </span>
                </div>
                <div class="info pt-3">所属楼栋：
                  <span v-for="(building,index) in item.buildings" :key="index">{{ building.name }} </span>
                </div>
              </div>
            </div>
          </div>
          <div class="more flex align-center justify-center" v-show="dataList.length>0"
               @click="toPage('/mobile/admin/monitorVideo')">
            <span>查看更多</span>
            <el-icon>
              <DArrowRight/>
            </el-icon>
          </div>
        </div>
        <div v-else class="more">暂时没有数据</div>
      </div>
    </div>
    <AdminMenu ref="adminMenu"></AdminMenu>
  </div>
</template>
<script>
import AdminMenu from "@/view/mobile/admin/adminMenu.vue"
import {getUserInfo} from "@/api/mobile";
import {mobileMonitorEquipment} from "@/api/monitor";

export default {
  components: {
    AdminMenu,
  },
  data() {
    return {
      user: null,
      // 监控权限
      hasVideoAuth: false,
      dataList: [],
      carouselHeight: '120px'
    }
  },
  mounted() {
    this.$refs.adminMenu.getIndex(0);

    this.getUser();

    this.handleCarouselHeight()
    window.onresize = () => {
      this.handleCarouselHeight()
    }
  },
  methods: {
    /**
     * 前往预约系统
     */
    toAppointment(){
      // this.$router.replace('/mobile/index')
      window.open("/#/mobile/index", '_blank')
    },
    handleCarouselHeight() {
      const width = 343; //设计稿的宽度
      const height = 120; //设计稿的高度
      const res = (document.documentElement.clientWidth - 32) * height / width
      this.$nextTick(() => {
        this.carouselHeight = `${parseInt(res)}px`
      })
    },
    getList() {
      mobileMonitorEquipment().then(res => {
        if (res.success) {
          this.dataList = res?.data || []
        } else {
          console.log('获取摄像头列表失败', res.message)
        }
      })
    },
    toMonitor(item) {
      this.$root.useRouter.push(`/mobile/admin/monitorVideo?id=${item.equipmentId}`);
    },
    toPage(path) {
      if (!path) return
      this.$root.useRouter.push(path);
    },
    // 获取用户信息
    getUser() {
      getUserInfo().then(res => {
        if (res.success) {
          window.localStorage.setItem('adminUser', JSON.stringify(res.data));
          this.user = res?.data || null
          // 监控权限
          this.hasVideoAuth = this.user?.userInfo?.hasVideoAuth || null
          this.hasVideoAuth && this.getList()
        } else {
          console.log('获取当前用户失败', res?.message)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-body {
  background: #F6F6F6;
}

.admin-content {
  padding: 10px 16px 71px 16px;

  .admin-banner {
    width: 100%;
    overflow: hidden;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .admin-navs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;

    .nav-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 8px 0;
      background: #FFFFFF;
      border-radius: 8px;

      .nav-div {
        padding: 8px 0;
        text-align: center;
        box-sizing: border-box;

        img {
          width: 40px;
          height: 40px;
        }

        .name {
          font-size: 13px;
          padding-top: 8px;
          color: #333;
          font-weight: 400;
        }
      }
    }
  }

  .camera-list {
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      text-align: left;
    }

    .content {
      padding: 10px 0;

      .camera-div {
        display: flex;
        background: #fff;
        padding: 12px;
        border-radius: 6px;
        margin-top: 8px;

        &:first-child {
          margin-top: 0;
        }

        .left-img {
          width: 80px;
          height: 80px;
          border-radius: 4px;
        }

        .right-info {
          padding-left: 12px;
          width: calc(100% - 80px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          box-sizing: border-box;


          .name {
            font-size: 15px;
            font-weight: 500;
            color: #333333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .desc {
            width: 100%;

            .info {
              font-size: 13px;
              font-weight: 400;
              color: #666666;
              line-height: 18px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.pt-3 {
                padding-top: 3px;
              }
            }
          }
        }
      }
    }

    .more {
      padding-top: 10px;
      font-size: 13px;
      color: #535356;
      text-align: center;
    }
  }
}
</style>