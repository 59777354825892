<template>
  <div class="edit-div-ahover">
    <div class="fontSize20gay text-left">设备信息</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
      <div style="width: 800px;margin: auto">
        <el-form-item label="设备名称" size="large" label-width="160px">
          <el-input v-model="Info.name" autocomplete="off" placeholder="请输入" class="room-input-cl"/>
        </el-form-item>
        <el-form-item label="设备ID" size="large" label-width="160px">
          <el-input v-model="Info.lockId" autocomplete="off" placeholder="用于接入第三方平台的唯一标识"
                    class="room-input-cl"/>
        </el-form-item>
        <el-form-item label="设备类型" size="large" label-width="160px">
          <el-radio-group v-model="Info.type">
            <el-radio :label="0">摄像头</el-radio>
            <el-radio :label="1">锁</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否有音频" size="large" label-width="160px">
          <el-radio-group v-model="Info.hasAudio">
            <el-radio :label="true">有</el-radio>
            <el-radio :label="false">无</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="所属实验室" size="large" label-width="160px">
          <el-select v-model="Info.laboratoryId" filterable multiple placeholder="请选择一个实验室"
                     class="room-select-tl">
            <el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <!--显示摄像头的楼栋和实验室负责人、联系电话-->
        <div v-if="Info.type == 0 && Info.laboratoryId.length > 0">
          <el-form-item label="所属楼栋" size="large" label-width="160px">
            <span>{{getBuildingNames(Info.laboratoryId)}}</span>
          </el-form-item>
          <el-form-item label="实验室负责人" size="large" label-width="160px">
           <span>{{ getLabUserNames(Info.laboratoryId)}}</span>
          </el-form-item>
        </div>
        <el-form-item label="ip地址" size="large" label-width="160px">
          <el-input v-model="Info.ip" autocomplete="off" class="room-input-cl" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="端口号" size="large" label-width="160px">
          <el-input v-model="Info.port" autocomplete="off" class="room-input-cl" placeholder="请输入"/>
        </el-form-item>
        <div v-if="Info.type == 0">
          <el-form-item label="硬盘录像机" size="large" label-width="160px">
            <el-select v-model="Info.equipmentVideoRecorderId" placeholder="请选择硬盘录像机" class="room-select-tl">
              <el-option v-for="item in hardDisk"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item label="硬盘录像机通道号" size="large" label-width="160px">
            <el-select v-model="Info.videoRecorderChannel" placeholder="请选择硬盘录像机通道号" class="room-select-tl">
              <el-option v-for="item in 128"
                         :key="item"
                         :label="item"
                         :value="item"/>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </div>
    <div class="marginTop15px">
      <button class="subBtn" @click="subData">保存</button>
      <button class="qxBtn" @click="qxBtn">取消</button>
    </div>
  </div>
</template>
<script>
import {editEquipment, getRoomList, queryEquipmentVideoRecorderAll} from "@/api/api";

export default {
  name: "index",
  props: ['Row'],
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        name: '',
        code: '',
        type: 1,
        hasAudio: '',
        laboratoryId: [],
        // 硬盘录像机
        equipmentVideoRecorderId: '',
        // 硬盘录像机通道号
        videoRecorderChannel: '',
      },
      content: [''],
      roomList: [],
      //
      hardDisk: [],
    }
  },
  mounted() {
    this.getList1();
    let data = this.$root.useRoute.query;
    if (data.info) {
      console.log(data);
      this.Info = JSON.parse(data.info);
      let lids = [];
      if (this.Info.laboratory) {
        for (let s in this.Info.laboratory) {
          lids.push(this.Info.laboratory[s].id);
        }
        this.Info.laboratoryId = lids;
      }
    }
  },
  methods: {
    getBuildingNames(ids) {
      let res = []
      for(let item of this.roomList){
        if(ids.indexOf(item.id) > -1){
          res.push(item.building.name ||'')
        }
      }
      return res.join('，')
    },
    getLabUserNames(ids) {
      let res = []
      for(let item of this.roomList){
        if(ids.indexOf(item.id) > -1){
          if(!item.users) continue
          for(let user of item.users){
            res.push(`${user.name}（联系电话：${user.phone || '-'}）`)
          }
        }
      }
      return res.join('，')
    },
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getList1() {
      getRoomList().then((res) => {
        this.roomList = res.data;
      })
      queryEquipmentVideoRecorderAll().then(res => {
        //
        this.hardDisk = res.data
      })
    },
    subData() {//提交
      if (this.Info.type == 1) {
        if ((!this.Info.name) || (!this.Info.lockId)) {
          this.$root.ElMessage.error('设备ID和名称不能为空！');
          return false;
        }
      }
      if (!this.Info.laboratoryId || this.Info.laboratoryId.length < 1) {
        this.$root.ElMessage.error('必须选择一个实验室');
        return false;
      }
      let method = 'post';
      if (this.Info.id) {
        method = 'put';
      }
      editEquipment(this.Info, method).then((res) => {
        if (res.success) {
          this.$root.ElMessage({type: 'success', message: res.message});
          this.$root.useRouter.go(-1);
        } else {
          this.$root.ElMessage.error(res.message);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  display: flex;
  align-items: center;

  .el-form-item__label {
    width: 800px !important;
    color: red;
    padding: 0 15px 0 0;
  }

  .room-select-tl {
    width: 300px;
  }

  .room-input-tl {
    width: 600px;
    height: 40px
  }

  .room-input-cl {
    width: 300px;
    height: 40px
  }

  .room-input-qb {
    width: 100px;
    height: 40px
  }
}
</style>