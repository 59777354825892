<template>
    <div v-if="info" class="mobile-body">
        <header class="border-box flex flex-direction justify-between">
            <div class="header-top flex justify-between align-center">
                <span>实验室预约</span>
                <div class="stateBtnAll" :class="'stateBtnColor' + [info.state]">{{ stateArray[info.state] }}</div>
            </div>
            <div class="header-bottom flex align-center justify-between">
                <div class="header-bottom-people flex align-center">
                    <img src="@/static/img/admin/icon-user.png" alt="">
                    <span class="role">{{ info.type == 1 ? '教师' : '学生' }}</span>
                    <span class="border"></span>
                    <el-tooltip class="box-item" effect="dark" :content="info.user.name" placement="top-start">
                        <span class="name">
                            {{ info.user.name || '' }}
                        </span>
                    </el-tooltip>
                </div>
                <span>{{ info.time || '' }}</span>
            </div>
        </header>
        <main>
            <div v-if="info.state == 1 || info.state == 2 || info.state == 3" class="main-item">
                <div class="title" style="margin-bottom: 0;">
                    备注
                </div>
                <div class="box flex flex-direction justify-between">
                    <div class="content">{{ info.remarks || '-' }}</div>
                </div>
            </div>
            <div class="main-item">
                <div class="title">
                    预约信息
                </div>
                <div class="box flex flex-direction justify-between">
                    <div class="subTitle">实验室名称</div>
                    <div class="content">{{ info.laboratory.name || '' }}</div>
                </div>
                <div class="box flex flex-direction justify-between">
                    <div class="subTitle">楼栋地址</div>
                    <div class="content">{{ info.laboratory.building.name || '' }}</div>
                </div>
                <div class="box flex flex-direction justify-between">
                    <div class="subTitle">授课班级</div>
                    <div class="content">{{ info.className || '' }}</div>
                </div>
                <div class="box flex flex-direction justify-between">
                    <div class="subTitle">学生人数</div>
                    <div class="content">{{ info.studentNum || '' }}</div>
                </div>
                <div class="box flex flex-direction justify-between">
                    <div class="subTitle">预约日期</div>
                    <div class="content">{{ sdfdata(info.reservationTime, info.curriculumIdsStr) || '' }}</div>
                </div>
            </div>
            <div class="main-item">
                <div class="title">
                    耗材信息
                </div>
                <div class="box flex flex-direction justify-between" v-for="(item, index) in consumablesArr" :key="index">
                    <div class="subTitle">{{ item.utensil.name }}</div>
                    <div class="content">数量：{{ item.number }}</div>
                </div>
            </div>
            <div class="main-item">
                <div class="title">
                    器材信息
                </div>
                <div class="box flex flex-direction justify-between" v-for="(item, index) in equipmentArr" :key="index">
                    <div class="subTitle">{{ item.utensil.name }}</div>
                    <div class="content">数量：{{ item.number }}</div>
                </div>
            </div>
        </main>
        <footer class="flex justify-center border-box"
            :style="{ 'background-color': info.state == 0 ? '#fff' : info.state == 1 ? '#fff' : '#f6f6f6' }">
            <button v-if="info.state == 1" @click="submit(3)">退回</button>
            <div v-if="info.state == 0" class="flex justify-between">
                <button @click="submit(2)">不通过</button>
                <button @click="submit(1)">通过</button>
            </div>
        </footer>
    </div>
</template>

<script>
import { getAppointmentInfo, checkAppointment } from '@/api/api'
export default {
    data() {
        return {
            // id: ''
            info: null,
            stateArray: this.$root.stateList,
            // 耗材信息
            consumablesArr: [],
            // 器材信息
            equipmentArr: [],
            reservationExamineBo: {
                remarks: "",
                reservationIds: [],
                state: 0
            }
        }
    },
    created() {
        this.getInfo(this.$route.query.id)
    },
    methods: {
        getInfo(id) {
            getAppointmentInfo(id).then(res => {
                this.info = res.data
                this.reservationExamineBo.reservationIds = [res.data.id]
                res.data.reservationUtensilList.forEach((item, index) => {
                    // 耗材
                    if (item.utensil.type == 0) {
                        this.consumablesArr.push(item)
                    } else {
                        this.equipmentArr.push(item)
                    }
                })
            })
        },
        sdfdata(e, str) {
            let pol = "";
            if (e && str) {
                let s = e.split(",");
                let t = str.split(",");
                for (let i in s) {
                    if (i == 0) { pol = pol + s[i] + " " + t[i]; } else {
                        pol = pol + "," + s[i] + " " + t[i];
                    }
                }
            }
            return pol;
        },
        submit(state) {
            this.$root.ElMessageBox.prompt('备注', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                this.reservationExamineBo.remarks = value
                this.reservationExamineBo.state = state
                checkAppointment(this.reservationExamineBo).then(res => {
                    if (res.success) {
                        this.$root.ElMessage({ type: 'success', message: res.message });
                        this.$router.go(-1)
                    } else {
                        this.$root.ElMessage.error(res.message);
                    }
                })
            }).catch(() => {
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.mobile-body {
    background: #F6F6F6;
}

.border-box {
    box-sizing: border-box;
}

header {
    width: 100%;
    background-color: #fff;
    padding: 16px;

    .header-top {
        >span:first-child {
            font-weight: 600;
        }

        >span:last-child {
            width: 48px;
            height: 20px;
            color: #fff;
            font-size: 11px;
            width: 48px;
            background: #4A72FF;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
        }
    }

    .header-bottom {
        margin-top: 16px;

        &-people {
            font-size: 13px;

            >img {
                width: 16px;
                height: 16px;
            }

            .role {
                flex-shrink: 0;
                padding: 0 8px 0 4px;
            }

            .border {
                width: 1px;
                height: 12px;
                background-color: #ccc;
            }

            .name {
                width: 180px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                margin-left: 8px;
                text-align: left;
            }
        }

        >span {
            flex-shrink: 0;
            color: #666;
            font-size: 12px;
        }
    }

    .header-remarks {}
}

main {
    margin: 8px 0;

    .main-item {
        background-color: #fff;
        padding: 16px;
        text-align: left;

        .title {
            font-weight: 600;
        }

        .box {
            width: 100%;

            .subTitle {
                font-size: 13px;
                color: #666;
            }

            .content {
                font-size: 14px;
                margin-top: 8px;
            }
        }

        >div:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    >div:not(:last-child) {
        margin-bottom: 8px;
    }
}

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 68px;
    background-color: #fff;
    padding: 12px 16px;

    >button {
        width: 164px;
        height: 44px;
        border: 1px solid #EBEBEB;
        border-radius: 6px;
        line-height: 44px;
        color: #666;
        font-size: 16px;
        background-color: transparent;
    }

    >div {
        width: 100%;


        >button {
            width: 164px;
            height: 44px;
            border: 1px solid #EBEBEB;
            border-radius: 6px;
            line-height: 44px;
            color: #666;
            font-size: 16px;
            background-color: transparent;
        }

        >button:last-child {
            background-color: #4A72FF;
            color: #fff;
        }
    }
}
</style>
