import http from '@/utils/request.js'
import {patrolInspectionParams} from "@/utils/common";

/**
 * 分类列表
 */
export function getQuestionType(params){
  return http.get('/question_type/page', params)
}

/**
 * 添加分类
 */
export function setQuestionType(data){
  return http.post('/question_type', data)
}

/**
 * 编辑分类
 */
export function editQuestionType(data){
  return http.put('/question_type', data)
}

/**
 * 获取所有分类
 */
export function getAllQuestionType(params){
  return http.get('/question_type/all', params)
}

/**
 * 获取题目
 */
export function getQuestionPage(params){
  return http.get('/question/page', params)
}

/**
 * 添加题目
 */
export function addQuestion(data){
  return http.post('/question/edit', data)
}

/**
 * 编辑题目
 */
export function editQuestion(data){
  return http.put('/question/edit', data)
}

/**
 * 查询单选题
 */
export function queryQuestion(id){
  return http.get('/question/id/' + id)
}

/**
 * 试卷列表
 */
export function getPaperPage(params){
  return http.get('/paper/page', params)
}

/**
 * 新建试卷
 */
export function addPaper(data){
  return http.post('/paper', data)
}

/**
 * 编辑试卷
 */
export function editPaper(data){
  return http.put('/paper', data)
}

/**
 * 查询单选题
 */
export function queryPaper(id){
  return http.get('/paper/id/' + id)
}

/**
 * 查询试卷题目
 */
export function queryPaperQuestionAll(params){
  return http.get('/paper-question/all/', params)
}

/**
 * 设置试卷题目
 */
export function editPaperQuestion(data){
  return http.post('/paper-question/edit', data)
}

/**
 * 复制试卷
 */
export function paperCopy(data){
  return http.post(`/paper/copy`, data)
}

/**
 * 获取所有试卷
 */
export function getPaperAll(params){
  return http.get('/paper/all', params)
}

/**
 * 考试列表
 */
export function getExamPage(params){
  return http.get('/exam/page/', params)
}

/**
 * 添加考试
 */
export function addExam(data){
  return http.post('/exam', data)
}

/**
 * 编辑考试
 */
export function editExam(data){
  return http.put('/exam', data)
}

/**
 * 发布考试
 */
export function examRelease(id){
  return http.post(`/exam/release/${id}`)
}

/**
 * 考试结果列表
 */
export function getOverPage(params){
  return http.get('/exam/over/page', params)
}

/**
 * 获取所有试卷
 */
export function getExamAll(params){
  return http.get('/exam/all', params)
}

/**
 * 考试用户列表
 */
export function getCheckInfo(id){
  const url = `/examScore/check/info/${id}`
  return function (params){
    return http.get(url, params)
  }
}

/**
 * 证书列表
 */
export function getCertificateInfo(params){
  return http.get('/certificate/page', params)
}

/**
 * 获取考试列表
 */
export function getUserExam(params){
  return http.get('/exam/user/page', params)
}

/**
 * 获取资源树结构
 */
export function getResourceTree(params){
  return http.get('/resource/all', params)
}

/**
 * 创建知识库
 */
export function addResource(data){
  return http.post('/resource', data)
}

/**
 * 编辑知识库
 */
export function editResource(data){
  return http.put('/resource', data)
}

/**
 * 追加资源
 */
export function addResourceFile(data){
  return http.post('/resource-file', data)
}

/**
 * 删除资源
 */
export function removeResourceFile(id){
  return http.delete('/resource-file/' + id)
}

/**
 * 获取考试题目
 */
export function getExamQestion(id){
  return http.get('/exam/question/' + id)
}

/**
 * 交卷
 */
export function examCheck(data){
  return http.post('/exam/check', data)
}

/**
 * 查询所有证书
 */
export function getMyCertificate(params){
  return http.get('/certificate/my-all-certificate', params)
}

/**
 * 查询证书
 */
export function getCertificateById(params){
  return http.get('/certificate/id/' + params)
}

/**
 * 获取子资源
 */
export function getResourceMobile(params){
  return http.get('/resource/data', params)
}

/**
 * 获取所有已看过的资源
 */
export function getStudyListAll(params){
  return http.get('/resource-file-user/study-list', params)
}

/**
 * 用户查询资源
 */
export function viewFile(params){
  return http.get(`/resource-file/view/${params}`)
}

export function getTeacherList(params){
  return http.get('/teacher/all', params)
}

/**
 * 删除知识库
 */
export function deleteResourceDir(id){
  return http.delete(`/resource/${id}`)
}

/**
 * 获取实验室
 */
export function getAllLaboratory(){
  return http.get('/laboratory/all')
}

// ------------------------巡检---------------------------
//
/**
 * 消防栓巡检
 */
export function freeHydrantPage(params){
  return http.get('/xj-hydrant/page/', params)
}
export function addFreeHydrant(data,method){
  return http[method]('/xj-hydrant', patrolInspectionParams(data))
}
export function xjFreeHydrantInfo(id){
  return http.get(`/xj-hydrant/id/${id}`)
}

// ----------------------------实验室设备维修登记----------------------------
export function addMaintenance(data,method){
  return http[method]('/xj-maintenance', patrolInspectionParams(data))
}
export function getMaintenancePage(params){
  return http.get('/xj-maintenance/page', params)
}
export function xjMaintenanceInfo(id){
    return http.get(`/xj-maintenance/id/${id}`)
}

// ----------------------------每日巡检----------------------------
export function getDayPage(params){
  return http.get('/xj-day/page', params)
}

/**
 * 导出
 * @param params
 * @returns {Promise<unknown>}
 */
export function getDayPageExport(params){
  return http.get('/xj-day/export', params)
}
export function setDayPage(data,method){
  return http[method]('/xj-day', patrolInspectionParams(data))
}

export function xjDayinfo(id){
  return http.get(  `/xj-day/id/${id}`)
}

// ----------------------------每周巡检----------------------------
export function getEquipmentPage(params){
  return http.get('/xj-week-equipment/page', params)
}
export function setEquipment(data,method){
  return http[method]('/xj-week-equipment', patrolInspectionParams(data))
}
export function xjWeekEquipmentInfo(id){
    return http.get(`/xj-week-equipment/id/${id}`)
}

// ----------------------------灭火器每日检查----------------------------
export function getExtinguisherPage(params){
  return http.get('/xj-fire-extinguisher/page', params)
}
export function setExtinguisher(data,method){
  return http[method]('/xj-fire-extinguisher', patrolInspectionParams(data))
}
export function xjExtinguisherInfo(id){
    return http.get(`/xj-fire-extinguisher/id/${id}`)
}

// -----------------------------看板数据-----------------------------

/**
 * 获取每日巡检看板数据
 */
export function getXjDataViewDay(params){
  return http.get('/xj-data-view/day', params)
}

/**
 * 获取灭火器巡检看板数据
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getXjDataViewFire(params){
  return http.get('/xj-data-view/fire', params)
}

/**
 * 获取消火栓巡检看板数据
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getXjDataViewHydrant(params){
  return http.get('/xj-data-view/hydrant', params)
}

/**
 * 获取周设备巡检
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getXjDataViewWeekEquipment(params){
  return http.get('/xj-data-view/week-equipment', params)
}

/**
 * 预约签到列表
 */
export function getReservationSignPage(params){
  return http.get('/reservationSign/page', params)
}
