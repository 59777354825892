import http from '@/utils/request.js'
export function userLogin(url,data){
    return http.post(url,data)
}
export function autoLogin(data){
    return http.post('/cas/token?token='+data)
}
export function getUserInfo(){
    return http.get('/info')
}
export function userLoginOut(){
    localStorage.clear();
}
export function mobileAdminLoginOut(){
    localStorage.removeItem('adminUser');
    localStorage.removeItem('adminToken');
}
export function mobileUserLoginOut(){
    localStorage.removeItem('token');
    localStorage.removeItem('user');
}
export function changeUserPass(url,data,type){ // bug：密码中带有#
    return http[type](url+'/update-password?oldPassword='+data.oldPassword+'&newPassword='+data.newPassword+'&twoNewPassword='+data.twoNewPassword)
}
// 修改admin管理员密码
export function changeAdminUserPass(data){
    return http.put('/user/update-password',data)
}
export function getNoticeNew5(){
    return http.get('/notice/new')
}
export function getHotRoom5(){
    return http.get('/laboratory/hot/five')
}
export function getRoomInfo(id){
    return http.get('/laboratory/id/'+id)
}
export function getMyYuYue(data){
    return http.get('/reservation/my',data)
}
export function qxMyYuYue(id){
    return http.post('/reservation/cancel/'+id)
}

export function addRoom(data,type='post'){
    return http[type]('/laboratory',data)
}
export function getEquipmentPass(id){//获取设备密码
    return http.get('/equipment-password/reservation/'+id)
}
export function editUser(url,data,type='post'){
    return http[type](url,data)
}
export function getText(){//获取设备密码
    return http.get('/system/lab/notice')
}