<template>
  <div class="mobile-body">
    <div style="background-color: white;padding: 12px 16px 0 16px">
      <div class="flex justify-between align-center">
        <el-date-picker v-model="pageInfo.reservationTime" style="width: 170px" type="date" placeholder="请选择预约使用日期" size="small" value-format="YYYY-MM-DD" />
        <div class="flex marginLeft10">
          <el-button size="small" type="primary" round @click="searchList">搜索</el-button>
          <el-button size="small" round @click="resetList">重置</el-button>
          <el-button size="small" type="warning" round @click="todayDate">今日</el-button>
        </div>
      </div>
      <div class="flex marginTop20px justify-between">
        <div :class="pageInfo.state===''?'fontSize16BB':'fontSize16gay99'" @click="changeState('')">
          <div>全部</div>
          <div class="step-bottom-div" :class="pageInfo.state===''?'step-bottom-check':''"></div>
        </div>
        <div v-for="(b,index) in stateList" :key="index" :class="index===pageInfo.state?'fontSize16BB':'fontSize16gay99'" @click="changeState(index)">
          <div>{{b}}</div>
          <div class="step-bottom-div" :class="index===pageInfo.state?'step-bottom-check':''"></div>
        </div>
      </div>
    </div>
    <div v-infinite-scroll="loadMore" infinite-scroll-distance="10" style="overflow: auto;height: calc(100vh - 140px);">
      <div class="all-mobile-divClo" v-for="(r,index) in List" :key="index" @click="goInfo(r.id)">
        <div class="flex justify-between align-center"><div class="fontSize16gay">实验室预约</div>
          <div class="stateBtnAll" :class="'stateBtnColor'+r.state">{{stateList[r.state]}}</div></div>
        <div class="text-left">
          <div class="marginTop8px">
            <span class="fontSize13gay66">实验室名称</span>
            <span class="fontSize13gay marginLeft15">{{r.laboratory?r.laboratory.name:''}}</span>
          </div>
          <div class="marginTop8px"><span class="fontSize13gay66">楼栋地址</span><span class="fontSize13gay marginLeft15">{{r.laboratory?(r.laboratory.building?r.laboratory.building.name:''):''}}</span></div>
          <div class="marginTop8px"><span class="fontSize13gay66">预约时间</span><span class="fontSize13gay marginLeft15">{{r.reservationTime}} {{r.curriculum?r.curriculum.name:''}}</span></div>
        </div>
<!--        <div class="marginTop12px text-right" v-if="r.state===0"><el-button @click="qxYuYue(r.id)">取消预约</el-button></div>-->
      </div>
      <div v-if="List.length==0" class="no-data">暂无数据</div>
    </div>
    <menuDiv :Row="menuIndex" @tiaozhuan="tiaozhuan" @getList="getList" ref="menuDiv"></menuDiv>
  </div>
</template>
<script>
import menuDiv from "@/view/mobile/menu.vue"
import {getMyYuYue,qxMyYuYue} from "@/api/mobile";
export default {
  components:{
    menuDiv,
  },
  data(){
    return{
      user:JSON.parse(localStorage.getItem('user')),
      List:[],
      stateList:this.$root.stateList,
      pageInfo:{
        limit:5,
        state:'',
        reservationTime:'',
        page:1,
        pages:1,
        total:0
      },
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    this.getList();
    this.$refs.menuDiv.getIndex(1);//调用子组件方法
  },
  methods:{
    searchList(){
      this.pageInfo.page=1;
      this.pageInfo.pages=1;
      this.pageInfo.total=0;
      this.List=[];
      this.getList();
    },
    resetList(){
      this.pageInfo.reservationTime='';
      this.pageInfo.state='';
      this.searchList();
    },
    todayDate(){
      this.pageInfo.reservationTime=this.$root.moment(new Date()).format('YYYY-MM-DD');//获取当前时间
      this.searchList();
    },
    getList(){
      getMyYuYue(this.pageInfo).then((res)=>{
        this.List=this.List.concat(res.data.records);
        this.pageInfo.pages=res.data.pages;//总页数
      })
    },
    loadMore(){
      if(this.pageInfo.page<this.pageInfo.pages){
        this.pageInfo.page=this.pageInfo.page+1;
        this.getList();
      }
    },
    changeState(state){
      this.pageInfo={
        limit:5,
        state:state,
        page:1,
        total:0
      }
      this.List=[];
      this.getList();
    },
    qxYuYue(id){
      this.$root.ElMessageBox.confirm(
          '你确认要取消预约吗？', 'Warning', {confirmButtonText: '确认', cancelButtonText: '考虑一下', type: 'warning',}
      ).then(() => {
        qxMyYuYue(id).then((res)=>{
          if(res.success){
            this.$root.ElMessage({type:'success',message:res.message});
            this.getList();
          }else{
            this.$root.ElMessage.error(res.message);
          }
        })
      })
    },
    goInfo(id){
      this.$root.useRouter.push({path:"/mobile/room/myYuYueInfo",query:{id:id}});
    }
  },
}
</script>

<style lang="scss" scoped>
  .mobile-login-input{
    background:#F6F6F6;height: 44px;border-radius:4px;height: 44px;display: flex;align-items: center;padding: 10px 16px;margin: auto 16px;margin-top: 20px;
    image{width: 24px;height: 24px}
    input{margin-left: 10px;width: 95%;outline: none;background: transparent;height: 28px;border: 0;font-size: 15px}
  }
</style>