<template>
  <div class="edit-div-ahover">
    <div class="fontSize20gay text-left">用户权限</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
      <div style="width:300px">
        <el-tree-v2
            ref="dictTree"
            :data="menuList"
            :height="500"
            :props="props"
            show-checkbox
            v-model="checkTreeIds"
            :check-on-click-node="true"
            :default-checked-keys="defaultCheckedKeys"
            :default-expanded-keys="defaultExpandedKeys"
            :disabled="[1]"
            :getCheckedKeys="true"
            @check="(click, checked)=>{handleCheckChange(click, checked,index)}"
        />
      </div>
    </div>
    <div class="marginTop15px"><button class="subBtn" @click="subData">保存</button><button class="qxBtn" @click="qxBtn">取消</button></div>
  </div>
</template>
<script>
import {addAdmin,getMenu} from "@/api/api";
export default {
  name: "index",
  props:['Row'],
  data(){
    return{
      token:localStorage.getItem('token'),
      Info:{id:'',menuIds:''},
      menuList:[],
      props:{value:'id',label:'title',children:'child',disabled: 'disabled'},
      checkTreeIds:[],
      defaultCheckedKeys:[],
      defaultExpandedKeys:[],
    }
  },
  mounted() {
    this.getList1();
    let data=this.$root.useRoute.query.info;
    if(data){
      this.Info=JSON.parse(data);
      console.log(this.Info);
      if(this.Info.menuIds) {
        let sid= this.Info.menuIds.split(",");//默认展开
        let intsid=sid.map(function(data){
          return +data;
        });
        this.defaultExpandedKeys=sid;
        setTimeout(res=>{
          this.selMenu(intsid);
        },300)
      }
    }
  },
  methods:{
    qxBtn(){
      this.$root.useRouter.go(-1);
    },
    getList1(){
      getMenu().then((res)=>{
        this.menuList=res.data;
        this.menuList.find((res,i)=>{
          if(res.name=='Index'){
            this.menuList[i].disabled='disabled';
          }
        })
      })
    },
    handleCheckChange(click, checked,index){
      console.log(checked);
      let s1=checked.checkedKeys.join(",");
      let s2=checked.halfCheckedKeys.join(",");
      this.Info.menuIds=s1+','+s2;
    },
    selMenu(so){
      let IndexId='';
      for(let i in this.menuList){
        let l=this.menuList[i];
        console.log(so);
        if(this.menuList[i].name=='Index'){
          IndexId=this.menuList[i].id;
        }
        if(so.includes(l.id)){
          so.splice(so.indexOf(l.id),1);
        }
      }
      if(IndexId){so.push(IndexId);}
      this.defaultCheckedKeys=so;
    },
    subData(){//提交
      let method='put';
      addAdmin(this.Info,method).then((res)=>{
        if(res.success){
          this.$root.ElMessage({type:'success',message:res.message});
          this.$root.useRouter.go(-1);
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-form-item{
    display: flex;align-items: center;
    .el-form-item__label{width: 800px !important;color: red;padding: 0 15px 0 0;}
    .room-select-tl{width: 300px;}
    .room-input-tl{width: 600px;height: 40px}
    .room-input-cl{width: 300px;height: 40px}
    .room-input-qb{width: 100px;height: 40px}
  }
</style>