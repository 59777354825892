<template>
  <div class="preview-video-container"
       :class="{'show-preview-video':show}"
       @click.stop="show = false">
    <div class="video-container"
         @click.stop="() => {}">
      <div>
        <div class="choice-item choice-item-1" @click="close('preview')">在线学习</div>
        <div class="choice-item" @click="close('download')">下载学习</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PdfPreviewPrompt',
  data(){
    return {
      show: false,
      url: '',
    }
  },
  methods: {
    preview(url){
      this.url = url
      this.show = true
    },
    close(label){
      this.show = false
      this.$emit('change', {
        label,
        url: this.url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-video-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  .video-container{
    margin-top: 50%;
    width: 80%;
    background-color: #fff;
    border-radius: 8px;
    .choice-item{
      padding: 2.67vw 0;
      text-align: center;
    }
    .choice-item-1{
      border-bottom: 1px solid #D9D9D9;
    }
  }
}
.show-preview-video{
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  //background-color: #000;
}
</style>