<template>
  <div class="mobile-body">
    <div style="padding: 16px;background-color: white">
      <div class="flex justify-between align-center">
        <div class="fontSize16gay">实验室预约</div>
        <div class="stateBtnAll" :class="'stateBtnColor'+Info.state">{{stateList[Info.state]}}</div>
      </div>
      <div class="flex justify-between align-center marginTop15px">
        <div class="fontSize13gay flex align-center"><img src="@/static/img/mobile/room/yuyueUser.png" class="img16"><span class="marginLeft5">{{user.userInfo?user.userInfo.name:''}}</span></div>
        <div class="fontSize12gay66">{{Info.time}}</div>
      </div>
      <div v-if="Info.state===1" class="text-left marginTop16px">
        <div class="fontSize14gay66" v-if="lockInfo.length>0">动态密码</div>
        <div v-for="(s,index) in lockInfo" :key="index" class="marginTop5">
          <div class="fontSize12gay99">
            <span class="fontSize14gayBB" style="color: #4A72FF">{{s.password}}</span>
            <span class="marginLeft10">{{s.equipment?s.equipment.name:''}}</span>
            <span class="marginLeft5">{{s.reservationTime}} {{s.curriculum?s.curriculum.name:''}}({{s.curriculum?s.curriculum.start:''}}-{{s.curriculum?s.curriculum.end:''}})</span></div>
        </div>
      </div>
      <div v-if="Info.state>0" class="text-left marginTop16px">
        <div class="fontSize14gay66">备注</div>
        <div class="fontSize14gay marginTop8px">{{Info.remarks}}</div>
      </div>
    </div>
    <div style="padding: 16px;background-color: white" class="text-left marginTop8px">
      <div class="fontSize16gay">预约信息</div>
      <div class="marginTop16px">
        <div class="fontSize14gay66">实验室名称</div>
        <div class="fontSize14gay marginTop8px">
          {{Info.laboratory?Info.laboratory.name:''}}<span class="marginLeft15">{{Info.laboratory?Info.laboratory.address:''}}</span>
        </div>
      </div>
      <div class="marginTop16px">
        <div class="fontSize14gay66">楼栋地址</div>
        <div class="fontSize14gay marginTop8px">{{Info.laboratory?(Info.laboratory.building?Info.laboratory.building.name:''):''}}</div>
      </div>
      <div class="marginTop16px">
        <div class="fontSize14gay66">预约日期</div>
        <div class="fontSize14gay marginTop8px">
          {{sdfdata(Info.reservationTime,Info.curriculumIdsStr) || ''}}
        </div>
      </div>
      <div class="marginTop16px">
        <div class="fontSize14gay66">用途说明</div>
        <div class="fontSize14gay marginTop8px">
          {{Info.use}}
        </div>
      </div>
      <div class="marginTop16px">
        <div class="fontSize14gay66">授课班级</div>
        <div class="fontSize14gay marginTop8px">
          {{Info.className}}
        </div>
      </div>
      <div class="marginTop16px">
        <div class="fontSize14gay66">学生人数</div>
        <div class="fontSize14gay marginTop8px">
          {{Info.studentNum}}
        </div>
      </div>
      <div class="marginTop16px">
        <div class="fontSize14gay66">联系电话</div>
        <div class="fontSize14gay marginTop8px">
          {{Info.studentPhone}}
        </div>
      </div>
    </div>
    <div style="padding: 16px;background-color: white" class="text-left marginTop8px" v-if="Info.reservationUtensilList">
      <div class="fontSize16gay">资产信息</div>
      <div class="marginTop16px" v-for="(a,index) in Info.reservationUtensilList" :key="index">
        <div class="fontSize14gay66">[{{a.utensil?(a.utensil.type===1?'器材':'耗材'):''}}] {{a.utensil?a.utensil.name:''}}</div>
        <div class="fontSize14gay marginTop8px">数量：{{a.number}}</div>
      </div>
    </div>
    <div class="mobile-sub-div" v-if="Info.state===0 || Info.state===1">
      <div class="mobile-qx-btn" @click="qxYuYue">取消预约</div>
    </div>
  </div>
</template>
<script>
import {qxMyYuYue,getEquipmentPass} from "@/api/mobile";
import {getAppointmentInfo} from "@/api/api";
export default {
  data(){
    return{
      user:JSON.parse(localStorage.getItem('user')),
      Info:{id:''},
      lockInfo:[],
      stateList:this.$root.stateList,
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    this.Info.id=this.$root.useRoute.query.id;
    if(this.Info.id) {
      this.getInfo();
    }
  },
  methods:{
    getInfo(){
      getAppointmentInfo(this.Info.id).then((res)=>{
        this.Info=res.data;
        this.getLockPass();
      })
    },
    getLockPass(){
      getEquipmentPass(this.Info.id).then((res)=>{
        this.lockInfo=res.data;
      })
    },
    qxYuYue(){
      let id=this.Info.id;
      this.$root.ElMessageBox.confirm(
          '你确认要取消预约吗？', 'Warning', {confirmButtonText: '确认', cancelButtonText: '考虑一下', type: 'warning',}
      ).then(() => {
        qxMyYuYue(id).then((res)=>{
          if(res.success){
            this.$root.ElMessage({type:'success',message:res.message});
            this.$root.useRouter.push({path:"/mobile/room/myYuYue"});
          }else{
            this.$root.ElMessage.error(res.message);
          }
        })
      })
    },
    goYuyue(id){
      this.$root.useRouter.push({path:"/mobile/room/yuyueInfo",query:{id:id}});
    },
    sdfdata(e,str){
      let pol = "";
      if(e && str) {
        let s = e.split(",");
        let t = str.split(",");
        for (let i in s) {
          if(i==0){pol = pol +s[i] + " " + t[i];}else {
            pol = pol + "," + s[i] + " " + t[i];
          }
        }
      }
      return pol;
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobile-login-input{
    background:#F6F6F6;height: 44px;border-radius:4px;height: 44px;display: flex;align-items: center;padding: 10px 16px;margin: auto 16px;margin-top: 20px;
    image{width: 24px;height: 24px}
    input{margin-left: 10px;width: 95%;outline: none;background: transparent;height: 28px;border: 0;font-size: 15px}
  }
</style>