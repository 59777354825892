<template>
  <div class="mobile-body" style="height: 100vh; width: 100vw;overflow: hidden;">
    <div class="flex" v-if="user"
         style="padding: 60px 16px 24px 16px;background: linear-gradient(180deg, #E8EDFF 0%, rgba(232,237,255,0) 100%);">
      <div><img src="@/static/img/logo-icon.png" class="img56"></div>
      <div class="marginLeft15 text-left">
        <div class="fontSize20gay">{{ user.userInfo ? user.userInfo.name : '' }}</div>
        <div class="fontSize14gay66 marginTop8px">电话：{{ user.userInfo ? user.userInfo.phone : '' }}</div>
      </div>
    </div>
    <div
        style="border-radius: 6px;box-shadow: 0px 8px 24px -4px rgba(0,0,0,0.04);background-color: white;margin: 0 16px">
      <div class="flex align-center justify-between" style="padding: 17px 12px"
           @click="toPage('/mobile/admin/userInfo')">
        <div class="align-center flex">
          <img src="@/static/img/mobile/user/userInfo.png" class="img20">
          <div class="marginLeft10 fontSize16gay">个人中心</div>
        </div>
        <div><img src="@/static/img/mobile/index/right.png" class="img16"></div>
      </div>
      <div class="flex align-center justify-between" style="padding: 17px 12px"
           @click="toPage('/mobile/admin/userPassword')">
        <div class="align-center flex">
          <img src="@/static/img/admin/icon-password.png" class="img20">
          <div class="marginLeft10 fontSize16gay">修改密码</div>
        </div>
        <div><img src="@/static/img/mobile/index/right.png" class="img16"></div>
      </div>
    </div>
    <div v-if="user"
         style="background: #FFF5F4;color: #FF574D;font-size: 16px;font-weight: 500;margin: 24px 16px;padding: 11px 0"
         class="text-center" @click="loginOut">退出登录
    </div>
    <AdminMenu ref="adminMenu"></AdminMenu>
  </div>
</template>
<script>
import AdminMenu from "@/view/mobile/admin/adminMenu.vue"
import {getUserInfo, mobileAdminLoginOut} from "@/api/mobile";

export default {
  components: {
    AdminMenu,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('adminUser')) || null,
    }
  },
  mounted() {
    document.title = '个人中心'
    this.$refs.adminMenu.getIndex(1);//调用子组件方法
    this.getUser();
  },
  methods: {
    // 获取用户信息
    getUser() {
      getUserInfo().then(res => {
        if (res.success) {
          window.localStorage.setItem('adminUser', JSON.stringify(res.data));
          this.user = res?.data || null
        } else {
          console.log('获取当前用户失败', res?.message)
        }
      })
    },
    toPage(path) {
      if(!path) return
      this.$root.useRouter.push(path);
    },
    loginOut() {
      this.$root.ElMessageBox.confirm(
          '你确认要退出登录吗？', '提示', {
            confirmButtonText: '确认', cancelButtonText: '取消', type: 'warning', center: true,
          }
      ).then(() => {
        mobileAdminLoginOut();
        this.$root.useRouter.replace("/mobile/admin/login");
      })
    }
  },
}
</script>
