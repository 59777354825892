<template>
  <div class="mobile-body">
    <div style="padding-top: 12px;padding-bottom: 80px;">
      <div class="form-title">灭火器每日检查</div>
      <div class="page-form">
        <div class="flex align-center page-form-item" style="width: 100%;">
          <div class="label">
            <span class="color-red">*</span><span> 日期</span>
          </div>
          <el-date-picker v-model="pageForm.date" type="date" style="flex: 1;" placeholder="请选择日期"
                          value-format="YYYY-MM-DD">
          </el-date-picker>
          <el-icon color="#999">
            <ArrowRight/>
          </el-icon>
        </div>
        <div class="flex align-center page-form-item" style="width: 100%;">
          <div class="label"><span class="color-red">*</span><span> 实验室</span></div>
          <el-select v-model="pageForm.laboratoryId" filterable style="flex: 1;" placeholder="选择实验室">
            <el-option v-for="item in laboratoryList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id"
            />
          </el-select>
          <el-icon color="#999">
            <ArrowRight/>
          </el-icon>
        </div>
        <div class="flex align-center page-form-item" style="width: 100%;">
          <div class="label"><span class="color-red">*</span><span>灭火箱</span></div>
          <el-input v-model="pageForm.fireBox" placeholder="请输入" type="number"/>
        </div>
        <div class="flex align-center page-form-item" style="width: 100%;">
          <div class="label"><span class="color-red">*</span><span>灭火器</span></div>
          <el-input v-model="pageForm.fireExtinguisher" placeholder="请输入" type="number"/>
        </div>
        <div class="flex align-center page-form-item" style="width: 100%;">
          <div class="label"><span class="color-red">*</span><span>压力情况(绿色段)</span></div>
          <el-radio-group v-model="pageForm.pressure" class="flex-1" style="justify-content: flex-end">
            <el-radio label="1" size="large">合格</el-radio>
            <el-radio label="0" size="large">不合格</el-radio>
          </el-radio-group>
        </div>
        <div class="flex align-center page-form-item" style="width: 100%;">
          <div class="label"><span class="color-red">*</span><span>外观完整性</span></div>
          <el-radio-group v-model="pageForm.appearance" class="flex-1" style="justify-content: flex-end">
            <el-radio label="1" size="large">合格</el-radio>
            <el-radio label="0" size="large">不合格</el-radio>
          </el-radio-group>
        </div>
        <div class="flex align-center page-form-item" style="width: 100%;">
          <div class="label"><span class="color-red">*</span><span>喷嘴完整性</span></div>
          <el-radio-group v-model="pageForm.spray" class="flex-1" style="justify-content: flex-end">
            <el-radio label="1" size="large">合格</el-radio>
            <el-radio label="0" size="large">不合格</el-radio>
          </el-radio-group>
        </div>
        <div class="flex align-center page-form-item" style="width: 100%;">
          <div class="label"><span class="color-red">*</span><span>保险栓</span></div>
          <el-radio-group v-model="pageForm.insure" class="flex-1" style="justify-content: flex-end">
            <el-radio label="1" size="large">合格</el-radio>
            <el-radio label="0" size="large">不合格</el-radio>
          </el-radio-group>
        </div>
        <div class="flex align-center page-form-item" style="width: 100%;">
          <div class="label"><span class="color-red">*</span><span>时效性</span></div>
          <el-radio-group v-model="pageForm.ageing" class="flex-1" style="justify-content: flex-end">
            <el-radio label="1" size="large">合格</el-radio>
            <el-radio label="0" size="large">不合格</el-radio>
          </el-radio-group>
        </div>
        <div class="flex align-center page-form-item" style="width: 100%;position: relative;">
          <div class="label"><span class="color-red">*</span><span>巡检员</span></div>
          <el-input @change="getUserList" v-model="keyword" @input=" searchInput"
                    placeholder="请输入巡检员搜索" clearable></el-input>
          <div class="search-res" v-if="showSearchRes">
            <span class="el-popper__arrow"></span>
            <div class="item-box" v-if="teacherList.length">
              <div v-for="t in teacherList" :key="'teacher-'+ t.id" class="res-item" @click.stop="chooseUser(t)">
                {{ t.name }}
              </div>
            </div>
            <div v-else class="no-data">暂时没有数据</div>
          </div>
          <el-icon color="#999">
            <ArrowRight/>
          </el-icon>
        </div>
        <div class="page-form-item">
          <div class="label"><span>现场照片</span></div>
          <div class="pic-div">
            <div class="img" v-for="(item,index) in imgs" :key="item">
              <el-image :src="projectName + item" alt=""
                        fit="cover" class="con"/>
              <el-icon class="close" @click="delImg(index)">
                <CircleCloseFilled/>
              </el-icon>
            </div>
            <el-upload accept="image/*"
                       action="-"
                       :limit="9"
                       :show-file-list="false"
                       class="upload-card"
                       :http-request="uploadImage">
              <div style="border-radius: 6px;">
                <el-icon>
                  <Plus/>
                </el-icon>
                <div class="color-999 fontSize12">上传图片</div>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="page-form-item">
          <div class="label"><span>备注</span></div>
          <el-input
              v-model="pageForm.notes"
              :autosize="{ minRows: 4, maxRows: 8}"
              type="textarea"
              placeholder="请输入"
          />
        </div>
      </div>
      <div class="fixed-edit flex justify-center align-center">
        <div class="cancel-btn" @click="qxBtn">取消</div>
        <div class="edit-btn" @click="subData">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAllLaboratory, getTeacherList, xjExtinguisherInfo, setExtinguisher} from "@/api/examination";
import ImageList from "@/view/patrolInspection/ImageList";
import {uploadFile} from "@/api/notice";
import {projectName} from "@/utils/config";

export default {
  name: "fireExtinguisherForm",
  components: {ImageList},
  data() {
    return {
      projectName: projectName,
      teacherList: [],
      teacher: {},
      laboratoryList: [],
      pageForm: {
        date: '',
        laboratoryId: '',
        fireBox: '',
        fireExtinguisher: '',
        pressure: '1',
        appearance: '1',
        spray: '1',
        insure: '1',
        ageing: '1',
        userId: '',
        notes: '',
        img: '',
      },
      imgs: [],
      id: null,
      keyword: '',
      showSearchRes: false,
    }
  },
  watch: {
    'keyword': {
      handler(val) {
        if (!val) this.showSearchRes = false
      },
      deep: true
    }
  },
  mounted() {
    this.id = this.$route?.query?.id || null
    this.getLabs();
    if (this.id > 0) {
      this.getInfo()
    }
  },
  methods: {
    getInfo() {
      xjExtinguisherInfo(this.id).then(res => {
        if (res.success && res.data) {
          this.pageForm = {
            id: res.data.id,
            date: res.data.date,
            laboratoryId: res.data.laboratoryId,
            fireBox: res.data.fireBox,
            fireExtinguisher: res.data.fireExtinguisher,
            pressure: res.data.pressure,
            appearance: res.data.appearance,
            spray: res.data.spray,
            insure: res.data.insure,
            ageing: res.data.ageing,
            userId: res.data.userId,
            notes: res.data.notes,
            img: res.data.img,
          }
          this.imgs = !this.pageForm.img ? [] : this.pageForm.img.split(',')
          this.teacher = res.data.teacher || {}
          this.keyword = this.teacher.name || ''
          this.teacherList = [this.teacher]
        }
      })
    },
    // 获取实验室列表
    getLabs() {
      getAllLaboratory().then(res => {
        if (res.success) {
          this.laboratoryList = res.data
        } else {
          this.$root.ElMessage.error(res.message)
        }
      })
    },
    // 搜索管理员（巡检员）
    getUserList(keyword) {
      if (this.keyword) {
        getTeacherList({name: this.keyword}).then(res => {
          this.teacherList = res?.data || []
          this.showSearchRes = true
        })
      } else {
        this.showSearchRes = false
      }
    },
    chooseUser(t) {
      this.pageForm.userId = t.id
      this.teacher = t
      this.keyword = t.name
      this.showSearchRes = false
    },
    searchInput() {
      if (this.keyword && this.teacherList.length) {
        this.showSearchRes = true
      }
    },
    // 上传图片
    uploadImage(e) {
      if (e && e.file && e.file.type && e.file.type.includes('image')) {
        const formData = new FormData()
        formData.append('file', e.file)
        uploadFile(formData).then(res => {
          if (res.success) {
            this.imgs.push(res.data)
          } else {
            console.log('上传图片失败')
          }
        })
      } else {
        this.$message.error('只能上传图片')
      }
    },
    delImg(idx) {
      this.imgs.splice(idx, 1)
    },
    subData() {
      if (!this.pageForm.date) return this.$message.error('请选择日期')
      if (this.pageForm.laboratoryId < 1) return this.$message.error('请选择实验室')
      if (!this.pageForm.fireBox) return this.$message.error('请输入灭火箱数量')
      if (!this.pageForm.fireExtinguisher) return this.$message.error('请输入灭火器数量')
      if (this.pageForm.userId < 1) return this.$message.error('请选择巡检员')
      let method = this.pageForm.id > 0 ? 'put' : 'post';
      this.pageForm.img = this.imgs.join(',')
      setExtinguisher(this.pageForm, method).then(res => {
        if (res.success) {
          this.$root.ElMessage({type: 'success', message: res.message});
          setTimeout(() => {
            this.$root.useRouter.go(-1);
          }, 500)
        } else {
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped>
.mobile-body {
  background: #f6f6f6;
}

.form-title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
  text-align: left;
  background: #fff;
  padding: 12px 16px;
}

.page-form {
  padding: 0 16px 16px 16px;
  background: #fff;

  .color-red {
    color: #D23C1E;
  }

  .color-999 {
    color: #999;
    border-radius: 6px;
  }

  /deep/ .el-input__suffix,
  /deep/ .el-input__prefix {
    display: none;
  }

  /deep/ .el-input__inner {
    text-align: right;
  }

  /deep/ .el-input__wrapper {
    box-shadow: none;
    padding-right: 3px;
  }

  /deep/ .el-textarea__inner {
    background: #f6f6f6;
    border-radius: 6px;
    box-shadow: none;
  }

  /deep/ .el-select .el-input.is-focus .el-input__wrapper {
    box-shadow: none !important;
  }

  /deep/ .el-select,
  /deep/ .el-input,
  /deep/ .el-input.is-focus,
  /deep/ .el-input__wrapper,
  /deep/ .el-input__wrapper.is-focus {
    border: none !important;
    outline: none !important;
    padding: unset !important;
    box-shadow: none !important;
  }

  /deep/ .el-popper {
    width: 98% !important;
  }

  .page-form-item {
    & + .page-form-item {
      border-top: 1px solid #f6f6f6;
    }

    .label {
      padding: 16px 0;
      box-sizing: border-box;
      font-size: 14px;
      text-align: left;
      line-height: 20px;
      width: 100px;
    }

    .pic-div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-bottom: 16px;

      .img {
        width: 80px;
        height: 80px;
        margin-top: 8px;
        margin-left: 5px;
        border-radius: 4px;
        border: 1px dashed #EBEBEB;
        position: relative;

        &:nth-child(4n+1) {
          margin-left: 0;
        }

        .con {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }

        .close {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 22;
          color: #52656D;
        }
      }

      .upload-card {
        width: 80px;
        height: 80px;
        border: 1px dashed #EBEBEB;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 6px;
        margin-top: 8px;
        margin-left: 5px;
      }
    }
  }
}


.fixed-edit {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 16px;
  z-index: 50;
  height: 68px;
  box-sizing: border-box;
  background: #ffffff;

  & > div {
    height: 44px;
    line-height: 44px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    flex: 1;
  }

  .cancel-btn {
    color: #666;
    border: 1px solid #EBEBEB;
    margin-right: 8px;
  }

  .edit-btn {
    color: #fff;
    background: #4A72FF;
    border: 1px solid #4A72FF;
    margin-left: 8px;
  }
}

.search-res {
  position: absolute;
  top: 52px;
  width: 95%;
  left: 0;
  z-index: 50;
  background: #ffffff;
  text-align: left;
  padding: 0 10px;
  border-radius: 6px;
  box-shadow: var(--el-box-shadow-light);
  border: 1px solid var(--el-border-color-light);

  /deep/ .el-popper__arrow {
    position: absolute;
    top: -3px;
    right: 30px;
    z-index: 30;
  }

  /deep/ .el-popper__arrow::before {
    background: #fff;
  }

  .item-box {
    min-height: 100px;
    max-height: 170px;
    overflow-y: scroll;
    padding: 0 10px;

    .res-item {
      line-height: 34px;
      height: 34px;
      font-size: 14px;
      color: #606266;
    }
  }

  .no-data {
    padding: 20px 10px;
    text-align: center;
    font-size: 14px;
    color: #999;
  }
}

</style>