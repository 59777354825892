<template>
  <div class="mobile-body">
    <div style="height: 10px;width: 100%"></div>
    <div v-infinite-scroll="loadMore" infinite-scroll-distance="10" style="overflow: auto;height: calc(100vh - 85px);">
        <div class="all-mobile-divClo text-left" v-for="(r,index) in List" :key="index" @click="goNoticeInfo(r.id)">
            <div class="fontSize16gayBB">{{r.title}}</div>
            <div class="flex justify-between align-center fontSize12gay99 marginTop12px">
              <div class="flex align-center"><img src="@/static/img/logo-icon.png" class="img16">
                <span class="marginLeft5">{{r.user?r.user.name:''}}</span>
              </div>
              <div>{{r.time}}</div>
            </div>
        </div>
        <div v-if="List.length==0" class="no-data">暂无数据</div>
    </div>
    <menuDiv :Row="menuIndex" @tiaozhuan="tiaozhuan" @getList="getList" ref="menuDiv"></menuDiv>
  </div>
</template>
<script>
import menuDiv from "@/view/mobile/menu.vue"
import {getNoticePage} from "@/api/api";
export default {
  components:{
    menuDiv,
  },
  data(){
    return{
      user:JSON.parse(localStorage.getItem('user')),
      List:[],
      stateList:this.$root.stateList,
      pageInfo:{
        limit:10,
        state:'',
        page:1,
        pages:1,
        total:0
      },
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    this.getList();
  },
  methods:{
    getList(){
      getNoticePage(this.pageInfo).then((res)=>{
        this.List=this.List.concat(res.data.records);
        this.pageInfo.pages=res.data.pages;//总页数
      })
    },
    loadMore(){
      if(this.pageInfo.page<this.pageInfo.pages){
        this.pageInfo.page=this.pageInfo.page+1;
        this.getList();
      }
    },
    goNoticeInfo(id){
      this.$root.useRouter.push({path:"/mobile/mobileNotice/noticeMobileInfo",query:{id:id}});
    }
  },
}
</script>

<style lang="scss" scoped>
  .mobile-login-input{
    background:#F6F6F6;height: 44px;border-radius:4px;height: 44px;display: flex;align-items: center;padding: 10px 16px;margin: auto 16px;margin-top: 20px;
    image{width: 24px;height: 24px}
    input{margin-left: 10px;width: 95%;outline: none;background: transparent;height: 28px;border: 0;font-size: 15px}
  }
</style>