<template>
  <el-main>
    <div class="index-data-all">
      <div class="index-data-sv">
        <div style="text-align: left" class="fontSize20gay">数据概览</div>
        <div class="flex justify-between marginTop30px">
          <div class="border-radius12 flex justify-between align-center index-1-side" style="background-color:#F2F5FF">
            <div>
              <div class="fontSize16gay">实验室总数（个）</div>
              <div class="data-num marginTop8px">{{indexInfo.labQuantity}}</div>
            </div>
            <div><img src="@/static/img/index/index1.png"></div>
          </div>
          <div class="border-radius12 flex justify-between align-center index-1-side" style="background-color:#E8FDF9">
            <div>
              <div class="fontSize16gay">实验室总使用率（%）</div>
              <div class="data-num marginTop8px" style="color:#1EDCC5">{{indexInfo.usageTate}}%</div>
            </div>
            <div><img src="@/static/img/index/index2.png"></div>
          </div>
          <div class="border-radius12 flex justify-between align-center index-1-side" style="background-color:#F3FAFF">
            <div>
              <div class="fontSize16gay">楼栋数（个）</div>
              <div class="data-num marginTop8px" style="color: #3CAAFA">{{indexInfo.buildingQuantity}}</div>
            </div>
            <div><img src="@/static/img/index/index3.png"></div>
          </div>
        </div>
      </div>
      <div class="index-data-build flex-wrap justify-between">
        <div class="border-radius12 index-2-side" v-for="(b,index) in indexInfo.buildingInfos" :key="index">
          <div style="text-align: left" class="fontSize20gay">{{b.name}}</div>
          <div class="flex justify-center" style="margin-top: 80px;">
            <div class="text-bob" style="width: 50%;border-right: 1px solid #EBEBEB;">
                <div class="left-num-color">{{b.labQuantity}}</div><div class="sv-name-color">实验室（个）</div>
            </div>
            <div class="text-bob" style="width: 50%;">
              <div class="right-num-color">{{b.userTate}}%</div><div sv-name-color>实验室使用率</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
import {getIndexData} from "@/api/api";
export default {
  name: "index",
  data(){
    return{
      indexInfo:{buildingInfos:[]}
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    getList(){
      getIndexData().then(res=>{
        this.indexInfo=res.data;
      })
    }
  }
}
</script>

<style scoped>
  .index-data-all .index-data-sv{
      background: white;border-radius: 12px;padding: 24px;
  }
  .index-1-side{width: calc(33.33% - 136px);padding: 30px 60px}
  .index-2-side{width: calc(33.33% - 60px);padding: 24px;background: white;background: white;margin-bottom: 24px}
  .data-num{font-size: 38px;font-weight: 600;color: #4A72FF;}
  .index-data-build{
    margin-top: 24px;
  }
  .left-num-color{color: #FC8415;font-weight: 600;font-size: 30px}
  .right-num-color{color: #11A75C;font-weight: 600;font-size: 30px}
  .sv-name-color{color: #666666;font-size: 16px;}
  .text-bob{padding: 48px 0}
</style>