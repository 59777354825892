<template>
  <div class="mobile-body">
    <div style="width: calc(100% - 32px);margin: auto 16px;padding:20px 0 70px 0">
      <div style="background: white;border-radius: 0px 0px 6px 6px;">
        <div @click="goNoticeList"><img src="@/static/img/mobile/index/index-notice.png" style="width: 100%"></div>
        <div class="flex align-center fontSize13gay66" style="height: 40px">
          <img src="@/static/img/mobile/index/notice.png">
          <div style="width:100%;margin-left: 5px;" class="text-left">
            <el-carousel height="20px" direction="vertical" :autoplay="true">
              <el-carousel-item v-for="item in noticeList" :key="item">
                <div text="2xl" justify="center" class="text-out-hide" @click="goNoticeInfo(item.id)">{{item.title}}</div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <img src="@/static/img/mobile/index/right.png">
        </div>
      </div>
      <!--<div class="marginTop15px" @click="goRoomList"><img src="@/static/img/mobile/index/yu-room.png" style="width: 100%"></div>-->
      <div class="fun-arr-card marginTop15px">
        <el-row gutter="10">
          <el-col v-for="iter in navList"
                  :key="iter.label"
                  :span="6"
                  @click="goRoomList(iter.path)">
            <div class="fun-arr-card-item ">
              <div class="image-box">
                <img :src="iter.src" alt="">
              </div>
              <div style="margin-top: 8px;font-size: 12px;" class="text-center">{{ iter.label }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div>
        <div class="fontSize16gayBB marginTop20px text-left">热门实验室</div>
        <div class="text-left marginTop15px" style="background: white" v-for="(r,index) in roomList" :key="index">
          <div><img :src="r.img?projectName+r.img:defaultRoomImg" style="width: 100%;height: 180px"></div>
          <div style="padding: 12px">
            <div class="flex justify-between align-start">
              <div class="fontSize14gayBB">{{r.name}}</div>
              <div><button class="yuyueBtn" @click.stop="goYuyue(r.id)">立即预约</button></div>
            </div>
            <div class="flex align-center"><img src="@/static/img/mobile/index/time.png">
              <span class="fontSize13gay66 marginLeft5">开放时间：提前{{r.openBeforeDayMin}}~{{r.openBeforeDayMax}}天可预约</span>
            </div>
            <div class="flex align-center marginTop5"><img src="@/static/img/mobile/index/address.png">
              <span class="fontSize13gay66 marginLeft5">所属楼栋：{{r.building?r.building.name:''}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <menuDiv :Row="menuIndex" @tiaozhuan="tiaozhuan" @getList="getList" ref="menuDiv"></menuDiv>
  </div>
</template>
<script>
import menuDiv from "@/view/mobile/menu.vue"
import {getNoticeNew5,getHotRoom5,getUserInfo} from "@/api/mobile";
import {projectName} from "@/utils/config"
export default {
  components:{
    menuDiv,
  },
  data(){
    return{
      projectName:projectName,
      defaultRoomImg:require('@/static/img/mobile/index/hot-room.png'),
      noticeList:[],
      roomList:[],
      navList: [
        {
          label: '实验室预约',
          path: '/mobile/room/roomList',
          src: require('@/static/img/exam/index-nav-1.png'),
        },
        {
          label: '知识学习',
          path: '/mobile/user/resources/list',
          src: require('@/static/img/exam/index-nav-2.png'),
        },
        {
          label: '在线考试',
          path: '/mobile/user/examList',
          src: require('@/static/img/exam/index-nav-3.png'),
        },
        {
          label: '证书查询',
          path: '/mobile/user/certificate',
          src: require('@/static/img/exam/index-nav-4.png'),
        },
      ],

    }
  },
  created() {
    this.snmUrl();
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    this.getInfo();
    this.getNoticeNew5();
    this.getHotRoom5();
    this.$refs.menuDiv.getIndex(0);//调用子组件方法
  },
  methods:{
    snmUrl(){//修饰链接 取消？参数部分
      let url = window.location.href ;             //获取当前url
      let dz_url = url.split('#')[0];                //获取#/之前的字符串
      let cs = dz_url.split('?')[1];                //获取?之后的参数字符串
      if(!cs){return false;}
      let newUrl=dz_url.split('?')[0]+'#'+url.split('#')[1];
      window.location.replace(newUrl);
    },
    getInfo(){
      getUserInfo().then((res)=>{
        if(res.success) {
          window.localStorage.setItem('user', JSON.stringify(res.data));
          /*if(res.data.userType=='student'){
            if(res.data.userInfo) {
              if(!res.data.userInfo.school || !res.data.userInfo.major || !res.data.userInfo.clasz) {
                this.$root.ElMessage.warning({message: '请先完善院校、专业、班级信息'});
                this.$root.useRouter.push({path: "/mobile/user/userInfo"});
              }
            }
          }*/
        }
      })
    },
    goRoomList(path){
      if (!path) {
        this.$root.$message.warning({ message: '暂未开放' })
        return
      }
      this.$root.useRouter.push({ path })
    },
    getNoticeNew5(){
      getNoticeNew5().then(res=>{
        this.noticeList=res.data;
      })
    },
    getHotRoom5(){
      getHotRoom5().then(res=>{
        this.roomList=res.data;
      })
    },
    goYuyue(id){
      this.$root.useRouter.push({path:"/mobile/room/yuyueInfo",query:{id:id}});
    },
    goNoticeList(){
      this.$root.useRouter.push({path:"/mobile/mobileNotice/noticeMobileList"});
    },
    goNoticeInfo(id){
      this.$root.useRouter.push({path:"/mobile/mobileNotice/noticeMobileInfo",query:{id:id}});
    }
  },
}
</script>

<style lang="scss" scoped>
.fun-arr-card{
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: #fff;
  box-sizing: border-box;
  .fun-arr-card-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    .image-box{
      width: 40px;
      height: 40px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.mobile-login-input{
  background:#F6F6F6;height: 44px;border-radius:4px;height: 44px;display: flex;align-items: center;padding: 10px 16px;margin: auto 16px;margin-top: 20px;
  image{width: 24px;height: 24px}
  input{margin-left: 10px;width: 95%;outline: none;background: transparent;height: 28px;border: 0;font-size: 15px}
}
</style>