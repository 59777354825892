<template>
  <div class="mobile-body">
    <div style="background-color: white;padding: 12px 16px 0 16px">
      <div style="background-color: #F6F6F6;border-radius: 4px;padding: 8px 12px" class="flex justify-start align-center">
        <input placeholder="请输入搜索关键字" class="room-search-value" v-model="pageInfo.name">
        <el-icon style="font-size: 18px" @click="resetData"><Search /></el-icon>
      </div>
      <div class="flex marginTop20px building-title" style="width: 100%;overflow-x: scroll">
        <div style="margin-right: 24px;flex-shrink: 0;" :class="!pageInfo.buildingId?'fontSize16BB':'fontSize16gay99'" @click="changeBuild('')">
          <div>全部</div>
          <div class="step-bottom-div" :class="!pageInfo.buildingId?'step-bottom-check':''"></div>
        </div>
        <div v-for="(b,index) in buildingList" :key="index" style="margin-right: 24px;flex-shrink: 0;" :class="b.id==pageInfo.buildingId?'fontSize16BB':'fontSize16gay99'" @click="changeBuild(b.id)">
          <div>{{b.name}}</div>
          <div class="step-bottom-div" :class="b.id==pageInfo.buildingId?'step-bottom-check':''"></div>
        </div>
      </div>
    </div>
    <div v-infinite-scroll="loadMore" infinite-scroll-distance="10" style="overflow: auto;height: calc(100vh - 180px);">
      <div class="room-list-ser" v-for="(r,index) in List" :key="index" @click="goYuyue(r.id)">
        <div style="border-bottom: 1px solid #F6F6F6;padding-bottom: 10px" class="flex">
          <div><img :src="r.img?projectName+r.img:defaultRoomImg" class="img72"></div>
          <div class="marginLeft10 text-left">
            <div>{{r.name}}</div>
            <div class="flex align-center justify-between marginTop25px" style="width: 240px">
              <div class="flex align-center">
                <img src="@/static/img/mobile/room/dz.png">
                <span class="fontSize13gay66">{{r.building?r.building.name:''}}</span>
                <span class="fontSize13gay marginLeft15">{{r.address}}</span>
              </div>
              <button style="height: 20px;background: #4A72FF;border-radius:2px;color: white;font-size: 12px;border: 0">去预约</button>
            </div>
          </div>
        </div>
        <div class="flex justify-between marginTop15px">
<!--          <div><span class="fontSize13gay99">已使用课时数：</span><span class="fontSize13gay">{{r.useSize}}</span></div>
          <div><span class="fontSize13gay99">使用率：</span><span class="fontSize13gay">{{r.usageTate}}%</span></div>-->
          <div><span class="fontSize13gay"> 提前{{r.openBeforeDayMin}}~{{r.openBeforeDayMax}}天 可预约，学生每天最多可预约{{r.studentDayMax}}节课</span></div>
        </div>
      </div>
      <div v-if="List.length==0" class="no-data">暂无数据</div>
    </div>
    <menuDiv :Row="menuIndex" @tiaozhuan="tiaozhuan" @getList="getList" ref="menuDiv"></menuDiv>
  </div>
</template>
<script>
import menuDiv from "@/view/mobile/menu.vue"
import {getBuildingList, getRoomPage} from "@/api/api";
import {projectName} from "@/utils/config"
export default {
  components:{
    menuDiv,
  },
  data(){
    return{
      projectName:projectName,
      defaultRoomImg:require('@/static/img/mobile/index/hot-room.png'),
      user:JSON.parse(localStorage.getItem('user')),
      List:[],
      buildingList:[],
      pageInfo:{
        limit:5,
        name:'',
        buildingId:'',
        page:1,
        total:0,
        pages:1,
      },
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    if(this.user.userType=='teacher'){this.pageInfo.open=true;}
    if(this.user.userType=='student'){this.pageInfo.open=true;this.pageInfo.openStudent=true;}
    this.getBuildingList();
    this.getList();
    this.$refs.menuDiv.getIndex(1);//调用子组件方法
  },
  methods:{
    resetData(){
      this.pageInfo.limit=5;
      this.pageInfo.page=1;
      this.pageInfo.total=0;
      this.List=[];
      this.getList();
    },
    getList(){
      getRoomPage(this.pageInfo).then((res)=>{
        this.List=this.List.concat(res.data.records);
        this.pageInfo.pages=res.data.pages;//总页数
      })
    },
    loadMore(){
      if(this.pageInfo.page<this.pageInfo.pages){
        this.pageInfo.page=this.pageInfo.page+1;
        this.getList();
      }
    },
    getBuildingList(){
      getBuildingList().then((res)=>{
        this.buildingList=res.data;
      })
    },
    changeBuild(id){
      this.pageInfo.buildingId=id;
      this.resetData();
    },
    goYuyue(id){
      this.$root.useRouter.push({path:"/mobile/room/yuyueInfo",query:{id:id}});
    }
  },
}
</script>

<style lang="scss" scoped>
  .mobile-login-input{
    background:#F6F6F6;height: 44px;border-radius:4px;height: 44px;display: flex;align-items: center;padding: 10px 16px;margin: auto 16px;margin-top: 20px;
    image{width: 24px;height: 24px}
    input{margin-left: 10px;width: 95%;outline: none;background: transparent;height: 28px;border: 0;font-size: 15px}
  }
  .building-title::-webkit-scrollbar{display: none}
  .room-list-ser{background-color: white;margin: 12px 16px 0 16px;padding: 12px;}
  .room-search-value{background-color: transparent;border: 0;margin-left: 5px;width: 90%;outline: none}
</style>