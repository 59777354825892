<template>
  <div class="exam-container">
    <div style="padding: 0 16px;">
      <div class="question-item"
           v-for="(iter, index) in questionList"
           :key="iter.id">
        <div class="question-box flex">
          <div class="label">{{ index + 1 }}. </div>
          <div class="label marginLeft5">{{ iter.title }}</div>
        </div>
        <div class="option">
          <div class="flex align-center marginTop16px"
               v-for="item in iter.options"
               :key="item.id"
               @click="selectOptions(item, iter.options)">
            <div class="option-label">
              <img v-show="item.selected" src="@/static/img/exam/exam/exam-selected.png" alt="">
              <img v-show="!item.selected" src="@/static/img/exam/exam/exam-select.png" alt="">
            </div>
            <div class="option-value">
              {{ indexMapper(item.sort) }}.{{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="submit-button-outer flex justify-center align-center"
         v-show="questionList.length > 0">
      <button @click="submit">提交</button>
    </div>
    <el-dialog v-model="examResultShow"
               destroy-on-close
               :show-close="false"
               :close-on-click-modal="false"
               class="result-container">
      <template #header>
      </template>
      <div class="result-content text-center">
        {{ examResultInfo.certificate
          ? `答题完成, 您的分数是: ${examResultInfo.score}分, 成绩合格`
          :  `答题完成, 您的分数是: ${examResultInfo.score}分, 未达到合格分数`}}
      </div>
      <template #footer>
        <div class="result-footer flex">
          <div class="button flex justify-center align-center"
               style="color: #666666;"
               :style="{width: examResultInfo.certificate ? '50%' : '100%'}"
               @click="closeExam(false)">关闭</div>
          <div v-show="examResultInfo.certificate"
               class="button flex justify-center align-center"
               style="color: #d8837b;border-left: 1px solid #f2f2f2;"
               @click="closeExam(true)">查看证书</div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {examCheck, getExamQestion} from "@/api/examination";

export default {
  name: "exam",
  data(){
    return {
      questionList: [],
      id: null,
      submitLoading: false,
      // 测验分数弹窗
      examResultShow: false,
      examResultInfo: {},
    }
  },
  mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      this.getList()
    }
  },
  methods: {
    getList(){
      getExamQestion(this.id).then(res => {
        if (res.success) {
          this.questionList = res.data.questions
          this.questionList.map(iter => {
            iter.options.map(item => {
              item.selected = false
            })
          })
          // console.log(this.questionList, '------this.questionList--');
        } else {
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    /**
     * 提交考试
     */
    submit(){
      // 是否作答完
      const incomplete = []
      let answerStatus = true
      this.questionList.forEach(iter => {
        let optionsStatus = false
        iter.options.forEach(item => {
          if (item.selected) {
            optionsStatus = true
          }
        })
        if (optionsStatus === false) {
          answerStatus = false
          incomplete.push(iter)
        }
      })

      /**
       * 提交试卷
       */
      const handler = () => {
        if (this.submitLoading) {
          return
        }
        this.submitLoading = true

        const params = {
          examId: this.id,
          questionInfo: [],
        }
        this.questionList.forEach(iter => {
          iter.options.forEach(item => {
            if (item.selected) {
              params.questionInfo.push({
                answerId: item.id,
                questionId: iter.id
              })
            }
          })
        })

        examCheck(params).then(res => {
          if(res.success){
            this.examResultInfo = res.data
            this.examResultShow = true
          }else{
            this.$root.ElMessage.error(res.message);
          }
        }).finally(() => {
          this.submitLoading = false
        })
      }

      if (answerStatus) {
        handler()
      } else {
        this.$messageBox.confirm(
            `你还有${incomplete.length}道题未作答, 是否继续交卷?`,
            '温馨提示',
            {
              confirmButtonText: '交卷',
              cancelButtonText: '继续答题',
              type: 'warning',
            }
        ).then(() => {
          handler()
        }).catch(() => {

        })
      }
    },
    /**
     * 关闭考试
     */
    closeExam(qualified=false){
      // 查看证书
      if (qualified && this.examResultInfo.certificate) {
        this.$root.useRouter.replace('/mobile/user/certificate/query/' + this.examResultInfo.certificate.id)
        return
      }
      // 关闭考试
      this.$root.useRouter.replace('/mobile/user/examList')
    },
    /**
     * 选择答案
     */
    selectOptions(item, options){
      options.map(iter => iter.selected = false)
      item.selected = true
    },
    /**
     * 选项映射
     */
    indexMapper(index){
      return String.fromCharCode(index + 65)
    },
  }
}
</script>

<style>
.exam-container .result-container{
  min-width: 250px;
  max-width: 300px;
  border-radius: 8px;
}
.exam-container .el-dialog__footer{
  padding: 0;
  border-top: 1px solid #f2f2f2;
}
</style>

<style lang="scss" scoped>
.result-title{

}
.result-content{

}
.result-footer{
  width: 100%;
  .button{
    margin: 0 auto;
    width: 50%;
    padding: 10px;
  }
}

.exam-container{
  padding: 24px 0 0;
  box-sizing: border-box;
  text-align: left;
  .question-item{
    margin-bottom: 24px;
    .question-box{
      .label{
        font-size: 16px;
        font-weight: 600;
      }
    }
    .option{
      .option-label{
        margin-right: 8px;
        width: 16px;
        height: 16px;
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .option-value{
        font-size: 14px;
        color: #333333;
        vertical-align: center;
      }
    }
  }
  .submit-button-outer{
    //position: fixed;
    //left: 0;
    //bottom: 0;
    height: 68px;
    width: 100vw;
    background-color: #fff;
    button{
      width: 90%;
      height: 44px;
      background-color: #4A72FF;
      border-radius: 6px;
      border: none;
      outline: none;
      font-size: 16px;
      color: #FFFFFF;
    }
  }
}
</style>