<template>
  <div>
    <el-upload v-model:file-list="imgs"
               accept="image/*"
               action=""
               list-type="picture-card"
               :limit="9"
               :on-preview="handlePictureCardPreview"
               :on-remove="handleRemove"
               :http-request="uploadImage">
      <el-icon><Plus /></el-icon>
    </el-upload>
    <el-dialog v-model="dialogVisible"
               title="照片预览"
               class="info-div700"
               destroy-on-close
               style="padding: 24px;text-align: left">
      <div>
        <img style="width: 100%;min-height: 306px;" :src="dialogImageUrl" alt="">
      </div>
      <template #footer>
      <span class="dialog-footer">
        <button class="qxBtn" @click="dialogVisible = false">关闭</button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {uploadFile} from "@/api/notice";
import {projectName} from "@/utils/config";

export default {
  name: 'ImageList',
  data(){
    return {
      // 现场照片
      imgs: [],
      dialogVisible: false,
      dialogImageUrl: '',
    }
  },
  methods: {
    /**
     * 预览
     */
    handlePictureCardPreview(e){
      this.dialogImageUrl = e.url
      this.dialogVisible = true
    },
    /**
     * 移除
     */
    handleRemove(e){
      console.log(e);
    },
    /**
     * 图片文件上传
     */
    uploadImage(e){
      this.imgs = this.imgs.filter(iter => iter.status === 'success')

      const formData = new FormData()
      formData.append('file', e.file)
      uploadFile(formData).then(res => {
        this.imgs.push({
          name: '',
          url: projectName + res.data,
          value: res.data,
        })
      })
    },
    /**
     * 获取上传图片
     */
    getImg(){
      let img = []
      this.imgs.forEach(iter => img.push(iter.value))
      return img.join(',')
    },
    /* 回显图片 */
    setImageInfo(img){
      this.imgs=[];
      if(img){
        let imgArr=img.split(',');
        for(let i in imgArr){this.imgs.push({name:'',url:projectName + imgArr[i],value:imgArr[i]})}
      }
    }
  }
}
</script>

<style scoped>

</style>