import http from '@/utils/request.js'
export function login(data){
    return http.post('/admin/login?'+data)
}
export function loginOut(){
    localStorage.clear();
}
export function getMenu(){
    return http.get('/menu/all')
}
export function getStudentPage(data){
    return http.get('/student/page',data)
}
export function addStudent(data,type='post'){
    return http[type]('/student',data)
}
export function getTeacherPage(data){
    return http.get('/teacher/page',data)
}
export function addTeacher(data,type='post'){
    return http[type]('/teacher',data)
}
export function getAdminPage(data){
    return http.get('/user/page',data)
}
export function getAdminList(data){
    return http.get('/user/all',data)
}
export function addAdmin(data,type='post'){
    return http[type]('/user',data)
}
export function getBuildingPage(data){
    return http.get('/building/page',data)
}
export function getBuildingList(){
    return http.get('/building/all')
}
export function addBuilding(data,type='post'){
    return http[type]('/building',data)
}
export function getRoomPage(data){
    return http.get('/laboratory/page',data)
}
export function addRoom(data,type='post'){
    return http[type]('/laboratory',data)
}
export function getCoursePage(){
    return http.get('/curriculum/all')
}
export function getAssetPage(data){
    return http.get('/utensil/page',data)
}
export function getAssetList(id){//获取实验室的器材和耗材
    return http.get('/utensil/list/by-laboratory/'+id)
}
export function addAsset(data,type='post'){
    return http[type]('/utensil',data)
}
export function uploadFile(data){//上传图片
    return http.file('/file/upload',data)
}