<template>
    <div>
        <el-dialog v-model="dialog_visible" modal-class="monitor-dialog-modal" custom-class="voice-dialog"
            :close-on-click-modal="false" :close-on-press-escape="false" align-center :lock-scroll="false"
            :show-close="false" :destroy-on-close="true" @close="beforeClose"
            :style="'background-image: url(' + monitor_dialog_bg + ');background-size: cover'">
            <template #header>
                <div class="monitor-dialog-header">
                    <div class="monitor-dialog-title">语音助手</div>
                    <div class="monitor-dialog-close" @click="close"></div>
                </div>
            </template>
            <div class="monitor-dialog-content flex-direction">
                <div @click="tell" class="main" :class="{ 'isVoice': isVoice }">
                    <img src="../../../static/img/monitor/voice.png" alt="">
                </div>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import { HZRecorder } from "@/utils/translate";
import { blobToFile } from "@/utils/common";
import { speechToText } from "@/api/monitor"
import { ref } from "vue"
import { ElMessageBox, ElMessage } from 'element-plus'
export default {
    name: 'voiceDialog',
    data() {
        return {
            monitor_dialog_bg: require('@/static/img/monitor/voiceBg.png'),
            isVoice: false,
            timer: null
        }
    },
    setup(prop, ctx) {
        let dialog_visible = ref(true)
        let keyWord = ref('')
        var recorder;
        function startRecording() {
            HZRecorder.get(function (rec) {
                recorder = rec;
                recorder.start();
            });
        }

        function stopRecording() {
            recorder.stop();
            let blob = recorder.getBlob()
            let file = blobToFile(blob)
            var formData = new FormData();
            formData.append('file', file);
            speechToText(formData).then(res => {
                let path = window.location.hash.split("?")[0]
                if (res.data == "我不知道" || !res.data) {
                    ElMessage.error("未能识别，请重新录入")
                    return
                }
                keyWord.value = res.data
                ElMessageBox.confirm(
                    `是否搜索实验室“${keyWord.value}”?`,
                    {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'info',
                    }
                ).then(() => {
                    if (path == "#/visualization/video/monitor") {
                        ctx.emit('close', keyWord.value)
                        return
                    }
                    ctx.emit('toPage', keyWord.value)
                }).catch(() => {
                })
            })
        }
        return {
            startRecording,
            stopRecording,
            dialog_visible,
            keyWord
        }
    },
    created() {
    },
    methods: {
        close() {
            this.$emit('close')
        },
        tell() {
            this.isVoice = !this.isVoice
            if (this.isVoice) {
                this.startRecording()
                // 最长录制十秒
                this.timer = setTimeout(() => {
                    // 暂停动画
                    this.isVoice = false
                    console.log('暂停');
                    this.stopRecording()
                }, 10000)
                console.log('录音开始----------');
            } else {
                clearTimeout(this.timer)
                this.timer = null
                this.stopRecording()
                console.log('录音结束----------');
            }
        }
    }
}
</script>

<style lang="scss">
.voice-dialog {
    width: 800px !important;
    height: 450px;
    --el-dialog-bg-color: transparent !important;
    --el-box-shadow: none;

    .el-dialog__body {
        padding: 0 !important;
    }
}

.monitor-small-dialog {
    --el-dialog-width: 800px;
    height: 450px;
    --el-dialog-bg-color: transparent;
    --el-box-shadow: none;

    .el-dialog__body {
        padding: 0 !important;
    }
}
</style>
<style scoped lang="scss">
@keyframes ellipseAnimate {
    0% {
        transform: translateX(-50%) scale(0);
        box-shadow: inset 0 0 25px 21px rgba(0, 157, 255, 0.7);
    }

    70% {
        transform: translateX(-50%) scale(1);
        box-shadow: inset 0 0 25px 21px rgba(0, 157, 255, 0.7);
    }

    100% {
        transform: translateX(-50%) scale(1.2);
        box-shadow: inset 0 0 25px 21px rgba(255, 56, 107, 0);
    }
}

.isVoice {
    &::after {
        animation-name: ellipseAnimate;
    }
}

.monitor-dialog-header {
    position: relative;
    width: 100%;
    height: 64px;

    .monitor-dialog-title {
        position: absolute;
        top: 9px;
        left: 20px;
        font-size: 24px;
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        color: #009DFF;
        line-height: 32px;
        letter-spacing: 1px;
    }

    .monitor-dialog-close {
        position: absolute;
        right: -16px;
        top: 22px;
        width: 42px;
        height: 42px;
        cursor: pointer;
    }
}

.monitor-dialog-content {
    width: 100%;
    height: calc(450px - 64px);
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    display: flex;
    background-color: transparent;
    align-items: center;
    justify-content: center;

    .main {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #009DFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        >img {
            width: 48px;
            height: 48px;
        }

        &::after {
            z-index: 10;
            position: absolute;
            left: 40px;
            transform: translateX(-50%);
            top: -20px;
            content: ' ';
            display: flex;
            width: 120px;
            height: 120px;
            border-radius: 50%;

            opacity: 0.5;
            box-shadow: inset 0 0 25px 21px rgba(0, 157, 255, 1);

            animation-iteration-count: infinite;
            animation-duration: 1.5s;
            // 用warning-tips类激活
            // animation-name: ellipseAnimate;
            animation-timing-function: linear;
        }
    }
}
</style>