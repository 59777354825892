<template>
  <div class="certificate-container">
    <div ref="certificate" class="certificate-outer">
      <img class="bg" src="@/static/img/exam/certificate/bg.png" alt="">
      <img class="hw" src="@/static/img/exam/certificate/hw.png" alt="">
      <div class="content">
        <div class="c-title text-center">合格证书</div>
        <div class="inner-box">
          <div class="c-text c-text-bold">{{ certificateInfo?.user?.name }}，{{ certificateInfo?.user?.username }}</div>
          <div class="c-text">于 {{ dateFormat(certificateInfo?.exam?.startTime, true) }} - {{ dateFormat(certificateInfo?.exam?.endTime, true) }}</div>
          <div class="c-text">参加 <span class="c-text-bold">{{ certificateInfo?.exam?.name }}</span></div>
          <div class="c-text">获得 {{ certificateInfo.score }}分，成绩 <span class="c-text-bold">合格</span>，颁发此证。</div>
          <div class="c-text text-right">教学资源中心</div>
        </div>
        <div class="flex justify-between">
          <div class="c-text">证书编号: {{ certificateInfo.id }}</div>
          <div class="c-text">发证日期: {{ dateFormat(certificateInfo.time) }}</div>
        </div>
      </div>
    </div>
    <div class="certificate-button flex justify-center align-center">
      <button @click="downLoad">点击下载</button>
    </div>
  </div>
</template>

<script>
import {getCertificateById} from "@/api/examination";
import html2canvas from "html2canvas";

export default {
  name: 'userCertificateQuery',
  data(){
    return {
      id: null,
      certificateInfo: {}
    }
  },
  methods: {
    /**
     * 下载证书
     */
    downLoad(){
      html2canvas(this.$refs.certificate, {
        useCORS: true,
      }).then((canvas) => {
        const link = canvas.toDataURL("image/jpg");
        this.exportPicture(link);
      });
    },
    /**
     * 导出图片
     */
    exportPicture(link, name = "合格证书") {
      const file = document.createElement("a");
      file.style.display = "none";
      file.href = link;
      file.download = decodeURI(name);
      document.body.appendChild(file);
      file.click();
      document.body.removeChild(file);
    },
    dateFormat(time, hour=false){
      // todo "2023-09-16 00:00:00"
      if (time) {
        let fa = time.split(' ')
        let prefix = fa[0].split('-')
        let suffix = fa[1].split(':')
        if (hour) {
          return `${prefix[0]}年${prefix[1]}月${prefix[2]}日 ${suffix[0]}:${suffix[1]}`
        } else {
          return `${prefix[0]}年${prefix[1]}月${prefix[2]}日`
        }
      }
      return time
    }
  },
  mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      getCertificateById(this.id).then(res => {
        if (res.success) {
          this.certificateInfo = res.data
        } else {
          this.$root.ElMessage.error(res.message);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.certificate-container{
  min-height: calc(100vh - 68px);
  background-color: #f6f6f6;
  text-align: left;
  box-sizing: border-box;
  .certificate-outer{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0 0;
    grid-auto-flow: row;

    padding: 16px;
    width: 100vw;
    box-sizing: border-box;
    background-color: #fff;

    .bg {
      grid-area: 1 / 1 / 2 / 2;
      width: 100%;
      height: 100%;
    }
    .hw {
      grid-area: 1 / 1 / 2 / 2;
      margin: 7.47vw auto 0;
      width: 44.53vw;
      height: 5.33vw;
    }
    .content {
      grid-area: 1 / 1 / 2 / 2;
      padding-bottom: 8vw;
      margin: 13.33vw auto 0;
      width: 74.4vw;
      .c-title{
        font-size: 4.27vw;
        color: #A77868;
      }
      .c-text{
        margin-bottom: 1.6vw;
        font-size: 2.4vw;
        color: #A77868;
      }
      .c-text-bold{
        font-weight: bold;
      }
      .inner-box{
        margin: 1.07vw auto 0;
        width: 67.47vw;
      }
    }
  }
  .certificate-button{
    position: fixed;
    left: 0;
    bottom: 0;
    height: 68px;
    width: 100vw;
    background-color: #fff;
    button{
      width: 90%;
      height: 44px;
      background-color: #4A72FF;
      border-radius: 6px;
      border: none;
      outline: none;
      font-size: 16px;
      color: #FFFFFF;
    }
  }
}
</style>