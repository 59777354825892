<template>
  <div class="mobile-body" style="background: #FFFFFF;">
    <div class="fontSize14gay" style="margin: 0 16px">
      <div class="flex justify-between" style="padding: 16px 0">
        <div>账号</div><div>{{Info.username}}</div>
      </div>
      <div class="flex justify-between" style="padding: 16px 0" v-if="userType=='student'">
        <div>院校</div><div><input v-model="Info.school" class="input-right"></div>
      </div>
      <div class="flex justify-between" style="padding: 16px 0" v-if="userType=='student'">
        <div>专业</div><div><input v-model="Info.major" class="input-right"></div>
      </div>
      <div class="flex justify-between" style="padding: 16px 0" v-if="userType=='student'">
        <div>班级</div><div><input v-model="Info.clasz" class="input-right"></div>
      </div>
      <div class="flex justify-between" style="padding: 16px 0" v-if="userType=='teacher'">
        <div>单位</div><div><input v-model="Info.department" placeholder="请输入单位" class="input-right"></div>
      </div>
      <div class="flex justify-between" style="padding: 16px 0;border-bottom: 1px solid #F6F6F6">
        <div>姓名</div><div><input v-model="Info.name" class="input-right"></div>
      </div>
      <div class="flex justify-between" style="padding: 16px 0;border-bottom: 1px solid #F6F6F6">
        <div>电话</div><div><input v-model="Info.phone" class="input-right" maxlength="11"></div>
      </div>
      <div class="flex justify-between" style="padding: 16px 0;border-bottom: 1px solid #F6F6F6">
        <div>邮箱</div><div><input v-model="Info.email" class="input-right"></div>
      </div>
    </div>
    <div class="mobile-sub-div">
      <div class="mobile-sub-btn" @click="subData">保存</div>
    </div>
  </div>
</template>
<script>
import {getUserInfo,editUser} from "@/api/mobile";
export default {
  data(){
    return{
      Info:{id:'',name:'',phone:'',email:''},
      userType:''
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    this.getInfo();
  },
  methods:{
    getInfo(){
      getUserInfo().then((res)=>{
        if(res.success) {
          this.Info=res.data.userInfo;
          this.userType=res.data.userType;
          window.localStorage.setItem('user', JSON.stringify(res.data));
        }
      })
    },
    subData(){
      if(this.userType=='student'){
        if(!this.Info.school || !this.Info.major || !this.Info.clasz){
          this.$root.ElMessage.error({message: '院校、专业、班级必填'});
          return false;
        }
      }
      let url='/'+this.userType;
      editUser(url,this.Info,'put').then((res)=>{
        if(res.success){
          this.$root.ElMessage({message: res.message,type:'success'});
          this.getInfo();
          this.$root.useRouter.push("/mobile/user/userIndex");
        }else{
          this.$root.ElMessage.error({message: res.message});
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
  .mobile-login-input{
    background:#F6F6F6;height: 44px;border-radius:4px;height: 44px;display: flex;align-items: center;padding: 10px 16px;margin: auto 16px;margin-top: 20px;
    image{width: 24px;height: 24px}
    input{margin-left: 10px;width: 95%;outline: none;background: transparent;height: 28px;border: 0;font-size: 15px}
  }
  .input-right{border: 0;outline: none;text-align: right}
</style>