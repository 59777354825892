export  default  [
    {
        path: '/monitor/monthlyOperation',
        name: 'monthlyOperation',
        component: () => import('@/view/monitor/monthly/monthlyOperation.vue')
    },
    {
        path: '/monitor/screenshots',
        name: 'screenshotList',
        component: () => import('@/view/monitor/screenshot/screenshotList.vue')
    },
    {
        path: '/monitor/boardSetting',
        name: 'boardSetting',
        component: () => import('@/view/monitor/boardSetting/index.vue')
    },
    {
        path: '/monitor/warnings',
        name: 'Warnings',
        component: () => import('@/view/monitor/warnings/index.vue')
    },
    {
        path: '/monitor/warningSettings',
        name: 'WarningSettings',
        component: () => import('@/view/monitor/warningSettings/index.vue')
    },    
    {
        path: '/monitor/interfaces',
        name: 'interfaces',
        component: () => import('@/view/monitor/interface/interfaces.vue')
    },
    {
        path: '/monitor/hardDisk',
        name: 'hardDisk',
        component: () => import('@/view/monitor/hardDisk/list.vue')
    },
    {
        path: '/monitor/hardDisk/edit/:id?',
        name: 'hardDiskEdit',
        component: () => import('@/view/monitor/hardDisk/create.vue')
    },
]