import http from '@/utils/request.js'
export function login(data){
    return http.post('/admin/login',data)
}
export function loginOut(){
    localStorage.clear();
}
export function isMobile() {//判断当前设备是否是移动端
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}
export function changePass(data){
    return http.put('/user/update-password',data);
}
export function resetStudentPassword(data){
    return http.put('/student/reset-password/'+data);
}
export function resetTeacherPassword(data){
    return http.put('/teacher/reset-password/'+data);
}
export function resetUserPassword(data){
    return http.put('/user/reset-password/'+data);
}
export function getIndexData(){
    return http.get('/index/data')
}
export function getAdminInfo(){
    return http.get('/info')
}
export function getMenu(){
    return http.get('/menu/all')
}
export function getMyMenu(){
    return http.get('/menu/user')
}
export function getStudentPage(data){
    return http.get('/student/page',data)
}
export function addStudent(data,type='post'){
    return http[type]('/student',data)
}
export function getTeacherPage(data){
    return http.get('/teacher/page',data)
}
export function addTeacher(data,type='post'){
    return http[type]('/teacher',data)
}
export function getAdminPage(data){
    return http.get('/user/page',data)
}
export function getAdminList(data){
    return http.get('/user/all',data)
}
export function addAdmin(data,type='post'){
    return http[type]('/user',data)
}
export function getBuildingPage(data){
    return http.get('/building/page',data)
}
export function getBuildingList(){
    return http.get('/building/all')
}
export function addBuilding(data,type='post'){
    return http[type]('/building',data)
}
export function getRoomPage(data){ //获取所有实验室
    return http.get('/laboratory/page',data)
}
export function getMyRoomPage(data){
    return http.get('/laboratory/my-data',data)
}
export function getRoomList(){
    return http.get('/laboratory/my/all')
}

export function hsiangHsienEquipment(id, number){
    return http.post(`/utensil-record/replay/${id}/${number}`)
}

export function addRoom(data,type='post'){
    return http[type]('/laboratory',data)
}
export function resetRoomData(){
    return http.post('/laboratory/reset-date')
}
export function getRoomYuYueInfo(id){
    return http.get('/reservation/lab/info/'+id)
}

export function getCourseList(){
    return http.get('/curriculum/all')
}
export function getAssetPage(data){
    return http.get('/utensil/page',data)
}
export function getAssetList(id){//获取实验室的器材和耗材 分类
    return http.get('/utensil/list/by-laboratory/'+id)
}
export function getAssetHistory(data){
    return http.get('/utensil-record/page',data)
}
export function getAssetListAll(id){//获取实验室的器材和耗材不分类
    return http.get('/utensil/list/by-laboratory/all/'+id)
}
export function addAsset(data,type='post'){
    return http[type]('/utensil',data)
}
export function getAppoinmentPage(data){
    return http.get('/reservation/page',data)
}

export function getJwCourseData(data){
    return http.get('/get-jw-course-data',data)
}

export function setJwData(data){
    return http.post('/reservation/export-jw-data',data)
}

export function getStudentList(){
    return http.get('/student/all')
}
export function getTeacherList(){
    return http.get('/teacher/all')
}
export function getAppointCourseInfo(id){
    return http.get('/reservation/list/'+id);
}
export function getNoticePage(data){
    return http.get('/notice/page',data);
}
export function addNotice(data,type='post'){
    return http[type]('/notice',data)
}
export function getNoticeInfo(id){
    return http.get('/notice/id/'+id);
}

export function addAppointment(data,type='post'){
    return http[type]('/reservation/check',data)
}
export function checkAppointment(data){
    return http.post('/reservation/examine',data)
}

/**
 * 预约签到
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function reservationSignCheck(data){
    return http.post('/reservationSign/check',data)
}

export function getAppointmentInfo(id){
    return http.get('/reservation/id/'+id);
}

export function getSystemContent(){//获取告知书
    return http.get('/system/lab/notice');
}
export function editSystemContent(data,type){//编辑告知书
    return http[type]('/system',data);
}

export function getSystemTermStart(){
    return http.get('/system/term/start');
}

export function setSystemTermStart(data){
    return http.put('/system/term/start',data);
}

export function getEquipmentPage(data){//获取告知书
    return http.get('/equipment/page',data);
}
export function editEquipment(data,type){//编辑告知书
    return http[type]('/equipment',data);
}

/**
 * 获取硬盘列表
 */
export function queryEquipmentVideoRecorderAll(){//获取告知书
    return http.get('/equipment-video-recorder/all');
}

/* 公用方法 */
export function uploadFile(data){//上传图片
    return http.file('/file/upload',data)
}
export function downFile(name){//下载模板
    return http.down('/'+name+'/download?Authorization=Bearer '+localStorage.getItem('token'));
}
export function exportFile(url){//导出
    return http.down(url);
}

/**
 * 获取短信验证码
 * @returns {*}
 */
export function queryMessageCode(){
    return http.get('/get-set-video-message-code');
}

/**
 * 设置权限
 * @param data
 * @returns {*}
 */
export function setVideoAuth(data){
    return http.put('/user/set-video-auth',data);
}
