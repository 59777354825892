<template>
  <div class="mobile-body">
    <div style="padding: 12px 16px">
      <div v-if="pageErr" class="text-center padding20">{{ pageErr }}</div>
      <div style="padding-bottom: 68px">
        <!--每日巡检-->
        <div v-if="type === 'patrolInspection' && info" class="info-wrapper">
          <div class="title">每日巡检自查</div>
          <div class="content flex justify-between align-center">
            <span>日期</span>
            <span>{{ info.date }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>实验室</span>
            <span style="flex: 1;">{{ info?.laboratory.name }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>设备仪器状态</span>
            <span>{{ info.equipmentState == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>电源</span>
            <span>{{ info.power == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>水源</span>
            <span>{{ info.water == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>门窗</span>
            <span>{{ info.door == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>卫生</span>
            <span>{{ info.health == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>桌椅</span>
            <span>{{ info.chair == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>环境</span>
            <span>{{ info.environment == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>照明</span>
            <span>{{ info.light == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>巡检员</span>
            <span>{{ info?.teacher?.name }}</span>
          </div>
          <div class="content-pic">
            <div class="name">现场照片</div>
            <div class="pic-div">
              <el-image :src="item" alt="" v-for="(item,index) in getImgArr(info.img)" :key="'img-'+index"
                        fit="cover" class="img"
                        :preview-src-list="getImgArr(info.img)"
                        :initial-index="Number(index)"/>
            </div>
          </div>
          <div class="content flex justify-between align-center">
            <span>备注</span>
            <span>{{ info.notes }}</span>
          </div>
        </div>
        <!--灭火器每日检查 -->
        <div v-if="type === 'fireExtinguisher' && info" class="info-wrapper">
          <div class="title">灭火器每日检查</div>
          <div class="content flex justify-between align-center">
            <span>日期</span>
            <span>{{ info.date }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>实验室</span>
            <span>{{ info?.laboratory.name }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>灭火箱</span>
            <span>{{ info.fireBox }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>灭火器</span>
            <span>{{ info.fireExtinguisher }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>压力情况（绿色段）</span>
            <span>{{ info.pressure == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>外观完整性</span>
            <span>{{ info.appearance == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>喷嘴完整性</span>
            <span>{{ info.spray == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>保险栓</span>
            <span>{{ info.insure == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>时效性</span>
            <span>{{ info.ageing == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>巡检员</span>
            <span>{{ info?.teacher?.name }}</span>
          </div>
          <div class="content-pic">
            <div class="name">现场照片</div>
            <div class="pic-div">
              <el-image :src="item" alt="" v-for="(item,index) in getImgArr(info.img)" :key="'img-'+index"
                        fit="cover" class="img"
                        :preview-src-list="getImgArr(info.img)"
                        :initial-index="Number(index)"/>
            </div>
          </div>
          <div class="content flex justify-between align-center">
            <span>备注</span>
            <span>{{ info.notes }}</span>
          </div>
        </div>
        <!--消火栓日常巡查 -->
        <div v-if="type === 'fireHydrants' && info" class="info-wrapper">
          <div class="title">消火栓日常巡查</div>
          <div class="content flex justify-between align-center">
            <span>日期</span>
            <span>{{ info.date }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>实验室</span>
            <span>{{ info?.laboratory.name }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>接口无滴水</span>
            <span>{{ info.drip == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>灭火器（2个/组）</span>
            <span>{{ info.fireExtinguisher == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>枪头（2个/组）</span>
            <span>{{ info.head == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>栓箱完好</span>
            <span>{{ info.hydrant == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>联网正常</span>
            <span>{{ info.networking == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>盘管无破损</span>
            <span>{{ info.tube == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>水袋完好</span>
            <span>{{ info.waterTape == 1 ? '合格' : '不合格' }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>巡检员</span>
            <span>{{ info?.teacher?.name }}</span>
          </div>
          <div class="content-pic">
            <div class="name">现场照片</div>
            <div class="pic-div">
              <el-image :src="item" alt="" v-for="(item,index) in getImgArr(info.img)" :key="'img-'+index"
                        fit="cover" class="img"
                        :preview-src-list="getImgArr(info.img)"
                        :initial-index="Number(index)"/>
            </div>
          </div>
          <div class="content flex justify-between align-center">
            <span>备注</span>
            <span>{{ info.notes }}</span>
          </div>
        </div>
        <!--每周设备巡检 -->
        <div v-if="type === 'equipmentWeek' && info" class="info-wrapper">
          <div class="title">每周设备巡检</div>
          <div class="content flex justify-between align-center">
            <span>日期</span>
            <span>{{ info.date }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>周次</span>
            <span>第{{ info.week }}周</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>实验室</span>
            <span>{{ info?.laboratory.name }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>设备名称</span>
            <span>{{ info.equipmentName }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>巡查仪器状态</span>
            <span>{{ info.xcyState }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>巡查仪器检查结果</span>
            <span>{{ info.xcyResult }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>巡检员</span>
            <span>{{ info?.teacher?.name }}</span>
          </div>
          <div class="content-pic">
            <div class="name">现场照片</div>
            <div class="pic-div">
              <el-image :src="item" alt="" v-for="(item,index) in getImgArr(info.img)" :key="'img-'+index"
                        fit="cover" class="img"
                        :preview-src-list="getImgArr(info.img)"
                        :initial-index="Number(index)"/>
            </div>
          </div>
          <div class="content flex justify-between align-center">
            <span>备注</span>
            <span>{{ info.notes }}</span>
          </div>
        </div>
        <!--实验室设备维修登记 -->
        <div v-if="type === 'laboratoryEquipment' && info" class="info-wrapper">
          <div class="title">实验室设备维修登记</div>
          <div class="content flex justify-between align-center">
            <span>日期</span>
            <span>{{ info.date }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>实验室</span>
            <span>{{ info?.laboratory.name }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>维修仪器名称</span>
            <span>{{ info.equipmentName }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>维修前设备仪器状态</span>
            <span>{{ info.equipmentBeforeState }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>维修内容</span>
            <span>{{ info.content }}</span>
          </div>
          <div class="content flex justify-between align-start">
            <span>维修后设备仪器状态</span>
            <span>{{ info.equipmentAfterState }}</span>
          </div>
          <div class="content flex justify-between align-center">
            <span>巡检员</span>
            <span>{{ info?.teacher?.name }}</span>
          </div>
          <div class="content-pic">
            <div class="name">现场照片</div>
            <div class="pic-div">
              <el-image :src="item" alt="" v-for="(item,index) in getImgArr(info.img)" :key="'img-'+index"
                        fit="cover" class="img"
                        :preview-src-list="getImgArr(info.img)"
                        :initial-index="Number(index)"/>
            </div>
          </div>
          <div class="content flex justify-between align-center">
            <span>备注</span>
            <span>{{ info.notes }}</span>
          </div>
        </div>
        <!--编辑按钮-->
        <div class="fixed-edit" v-show="info && todayDate === info.date">
          <div class="edit-btn" @click="toEdit">编辑</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  xjDayinfo,
  xjExtinguisherInfo,
  xjFreeHydrantInfo,
  xjMaintenanceInfo,
  xjWeekEquipmentInfo,
} from "@/api/examination";
import {projectName} from "@/utils/config";
import dayjs from "dayjs";

export default {
  data() {
    return {
      id: null,
      type: null,
      typeValue: {
        patrolInspection: {name: '每日巡检自查', func: xjDayinfo},
        fireExtinguisher: {name: '灭火器每日检查', func: xjExtinguisherInfo},
        fireHydrants: {name: '消火栓日常巡查', func: xjFreeHydrantInfo},
        equipmentWeek: {name: '每周设备巡检', func: xjWeekEquipmentInfo},
        laboratoryEquipment: {name: '实验室设备维修登记', func: xjMaintenanceInfo},
      },
      pageErr: null,
      info: null,
      todayDate: '',
    }
  },
  mounted() {
    this.todayDate = dayjs().format("YYYY-MM-DD")
    const query = this.$route.query
    this.id = query.id
    this.type = query.type
    if (this.id < 1) {
      this.pageErr = '巡检参数错误'
      return
    }
    if (!this.type || !Object.keys(this.typeValue).includes(this.type)) {
      this.pageErr = '选件类型不存在'
      return;
    }
    document.title = (this.typeValue[this.type].name || '') + '详情'
    this.getInfo()
  },
  methods: {
    getInfo() {
      const func = this.typeValue[this.type].func
      func(this.id).then(res => {
        if (res.success) {
          this.info = res.data
        } else {
          this.pageErr = '获取详情失败'
        }
      })
    },
    getImgArr(imgStr) {
      if (!imgStr) return []
      let arr = []
      imgStr.split(',').forEach(iter => arr.push(projectName + iter))
      return arr
    },
    toEdit() {
      if (this.info && this.info.date === this.todayDate) {
        this.$root.useRouter.push({path: `/mobile/admin/${this.type}Form`, query: {id: this.id}});
      }
    },
  }
}

</script>

<style scoped lang="scss">
.info-wrapper {
  background: #fff;
  padding: 16px 12px;
  border-radius: 6px;

  .title {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }

  .content {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    text-align: left;

    & > span {
      word-break: break-word;

      &:last-child {
        flex: 1;
        padding-left: 20px;
        text-align: right;
        color: #333;

      }
    }
  }

  .content-pic {
    margin-top: 16px;

    .name {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      text-align: left !important;
    }

    .pic-div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .img {
        width: calc((100% - 24px) / 4);
        height: 74px;
        margin-top: 8px;
        margin-left: 5px;
        border-radius: 4px;

        &:nth-child(4n+1) {
          margin-left: 0;
        }
      }
    }
  }
}

.fixed-edit {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 16px;
  z-index: 10;
  height: 68px;
  box-sizing: border-box;
  background: #ffffff;

  .edit-btn {
    width: 100%;
    height: 44px;
    line-height: 44px;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    background: #4A72FF;
  }
}

</style>