// 手机端-管理端
import AdminIndex from '@/view/mobile/admin/index.vue'
import AdminUserIndex from '@/view/mobile/admin/user/index.vue'
import AdminMonitorVideo from '@/view/mobile/admin/monitorVedio.vue'
import AdminUserInfo from '@/view/mobile/admin/user/info.vue'
import AdminPassword from '@/view/mobile/admin/user/password.vue'
import AdminWarningFile from '@/view/mobile/admin/warningFile.vue'
import AdminWarningDetail from '@/view/mobile/admin/warningDetail.vue'
import AdminLogin from '@/view/mobile/admin/adminLogin.vue'
import InspectionList from '@/view/mobile/admin/patrolInspection/list.vue'
import InspectionInfo from '@/view/mobile/admin/patrolInspection/info.vue'
import patrolInspectionForm from '@/view/mobile/admin/patrolInspection/patrolInspection.vue'
import fireExtinguisherForm from '@/view/mobile/admin/patrolInspection/fireExtinguisher.vue'
import fireHydrantsForm from '@/view/mobile/admin/patrolInspection/fireHydrants.vue'
import equipmentWeekForm from '@/view/mobile/admin/patrolInspection/equipmentWeek.vue'
import laboratoryEquipmentForm from '@/view/mobile/admin/patrolInspection/laboratoryEquipment.vue'
import AdminPatrolInspection from '@/view/mobile/admin/patrolInspection/index.vue'
import AdminAppoinment from '@/view/mobile/admin/appoinment/index.vue'
import AdminAppoinmentInfo from '@/view/mobile/admin/appoinmentInfo/index.vue'

export default [
    {
        path: '/mobile/admin/login',
        name: 'AdminLogin',
        component: AdminLogin
    },
    {
        path: '/mobile/admin/index',
        name: 'AdminIndex',
        component: AdminIndex
    },
    {
        path: '/mobile/admin/userIndex',
        name: 'AdminUserIndex',
        component: AdminUserIndex
    },
    {
        path: '/mobile/admin/monitorVideo',
        name: 'AdminMonitorVideo',
        component: AdminMonitorVideo,
    },
    {
        path: '/mobile/admin/userInfo',
        name: 'AdminUserInfo',
        component: AdminUserInfo,
    },
    {
        path: '/mobile/admin/userPassword',
        name: 'AdminPassword',
        component: AdminPassword,
    },
    {
        path: '/mobile/admin/warningFile',
        name: 'AdminWarningFile',
        component: AdminWarningFile,
    },
    {
        path: '/mobile/admin/warningDetail',
        name: 'AdminWarningDetail',
        component: AdminWarningDetail,
    },
    {
        path: '/mobile/admin/patrolInspection',
        name: 'AdminPatrolInspection',
        component: AdminPatrolInspection,
    },
    {
        path: '/mobile/admin/appoinment',
        name: 'AdminAppoinment',
        component: AdminAppoinment,
    },
    {
        path: '/mobile/admin/appoinmentInfo',
        name: 'AdminAppoinmentInfo',
        component: AdminAppoinmentInfo,
    },
    {
        path: '/mobile/admin/inspectionList',
        name: 'AdminInspectionList',
        component: InspectionList,
    },
    {
        path: '/mobile/admin/inspectionInfo',
        name: 'AdminInspectionInfo',
        component: InspectionInfo,
    },
    {
        path: '/mobile/admin/patrolInspectionForm',
        name: 'PatrolInspectionForm',
        component: patrolInspectionForm,
    },
    {
        path: '/mobile/admin/fireExtinguisherForm',
        name: 'FireExtinguisherForm',
        component: fireExtinguisherForm,
    },
    {
        path: '/mobile/admin/fireHydrantsForm',
        name: 'FireHydrantsForm',
        component: fireHydrantsForm,
    },
    {
        path: '/mobile/admin/equipmentWeekForm',
        name: 'EquipmentWeekForm',
        component: equipmentWeekForm,
    },
    {
        path: '/mobile/admin/laboratoryEquipmentForm',
        name: 'LaboratoryEquipmentForm',
        component: laboratoryEquipmentForm,
    },
]