<!-- 预警档案 -->
<template>
    <div class="mobile-body">
        <div v-infinite-scroll="loadMore" infinite-scroll-distance="100" class="container">
            <template v-if="warningEventList.length > 0">
                <div class="container-item flex justify-between" v-for="(item, index) in warningEventList" :key="index"
                    @click="warningEventDetail(item.id)">
                    <div class="container-item-left flex">
                        <img :src="projectName + item.imgPath" alt="">
                        <div class="main">
                            <div class="title">{{ item.warningEventType.name }}</div>
                            <div class="address flex align-center">
                                <img src="@/static/img/admin/adress.png" alt="">
                                <span>{{ item.building.name }}</span>
                            </div>
                            <div class="time flex align-center">
                                <img src="@/static/img/admin/time.png" alt="">
                                <span>{{ item.time }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="container-item-right" :class="{ 'changeBg': item.state }">
                        {{ item.state ? "已解除" : "预警中" }}
                    </div>
                </div>
            </template>
            <div v-else class="more">暂时没有数据</div>
        </div>
    </div>
</template>
<script>
import { warningEventPage } from "@/api/monitor"
import { projectName } from "@/utils/config";

export default {
    data() {
        return {
            projectName,
            warningEventList: [],
            pageInfo: {
                limit: 9,
                page: 1,
                pages: 1,
            },
        }
    },
    mounted() {
        this.getAllEventList()
    },
    methods: {
        // 获取预警档案
        async getAllEventList() {
            let res = await warningEventPage(this.pageInfo)
            this.pageInfo.pages = res.data.pages
            this.warningEventList = [...this.warningEventList, ...res.data.records]
        },
        warningEventDetail(id) {
            this.$router.push(`/mobile/admin/warningDetail?id=${id}`)
        },
        // 下拉加载
        loadMore() {
            if (this.pageInfo.page < this.pageInfo.pages) {
                this.pageInfo.page += 1
                this.getAllEventList()
            }
        },
    }

}
</script>
<style lang="scss" scoped>
.container {
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    background-color: #F6F6F6;
    padding: 12px 16px;

    &-item {
        height: 96px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        box-sizing: border-box;
        padding: 12px;
        margin-bottom: 12px;

        &-left {
            box-sizing: border-box;

            >img {
                width: 72px;
                height: 72px;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                margin-right: 12px;
            }

            .main {
                text-align: left;

                .title {
                    font-size: 15px;
                    color: #333333;
                    margin-bottom: 10px;
                }

                .address,
                .time {
                    >span {
                        color: #666;
                        font-size: 13px;
                    }

                    >img {
                        margin-right: 2px;
                        width: 14px;
                        height: 14px;
                    }
                }
            }

        }

        &-right {
            width: 48px;
            height: 20px;
            background: #FF574D;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            color: #fff;
            font-size: 11px;
            line-height: 20px;

        }

        .changeBg {
            background: #1DB469;
        }
    }

    .more {
        font-size: 13px;
        color: #535356;
    }
}
</style>