<template>
  <div style="background: #ffffff;border-radius: 8px;padding: 12px 24px 24px 24px">
    <div class="fontSize20gay text-left">资产信息</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
      <div style="width: 800px;margin: auto">
        <el-form-item label="资产编号" size="large" label-width="120px">
          <el-input v-model="Info.code" autocomplete="off" :disabled="Info.id?true:false" placeholder="请输入" class="room-input-tl" />
        </el-form-item>
        <el-form-item label="资产名称" size="large" label-width="120px">
          <el-input v-model="Info.name" autocomplete="off" placeholder="请输入" class="room-input-tl" />
        </el-form-item>
        <el-form-item label="资产类型" size="large" label-width="120px">
          <el-radio-group v-model="Info.type">
            <el-radio :label="0">耗材</el-radio><el-radio :label="1">器具</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="所属实验室" size="large" label-width="120px">
          <el-select v-model="Info.laboratoryId" filterable placeholder="请选择一个实验室" class="room-select-tl">
            <el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="库存数量" size="large" label-width="120px">
          <el-input v-model="Info.number" autocomplete="off" oninput="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" class="room-input-cl" placeholder="请输入库存"/>
        </el-form-item>

      </div>
    </div>
    <div class="marginTop15px"><button class="subBtn" @click="subData">保存</button><button class="qxBtn" @click="qxBtn">取消</button></div>
  </div>
</template>
<script>
import {addAsset,getRoomList} from "@/api/api";
export default {
  name: "index",
  props:['Row'],
  data(){
    return{
      token:localStorage.getItem('token'),
      Info:{id:'',name:'',code:'',type:0},
      content:[''],
      roomList:[]
    }
  },
  mounted() {
    this.getList1();
    let data=this.$root.useRoute.query;
    if(data.info){
      console.log(data);
      this.Info=JSON.parse(data.info);
    }
  },
  methods:{
    qxBtn(){
      this.$root.useRouter.go(-1);
    },
    getList1(){
      getRoomList().then((res)=>{
        this.roomList=res.data;
      })
    },
    subData(){//提交
      if((!this.Info.name) || (!this.Info.code)){
        this.$root.ElMessage.error('编号和名称不能为空！');
        return false;
      }
      if(!this.Info.laboratoryId){
        this.$root.ElMessage.error('必须选择一个实验室');
        return false;
      }
      let method='post';
      if(this.Info.id){method='put';}
      addAsset(this.Info,method).then((res)=>{
        if(res.success){
          this.$root.ElMessage({type:'success',message:res.message});
          this.$root.useRouter.go(-1);
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-form-item{
    display: flex;align-items: center;
    .el-form-item__label{width: 800px !important;color: red;padding: 0 15px 0 0;}
    .room-select-tl{width: 300px;}
    .room-input-tl{width: 600px;height: 40px}
    .room-input-cl{width: 300px;height: 40px}
    .room-input-qb{width: 100px;height: 40px}
  }
</style>