<template>
  <div ref="editor" style="width: 100%;text-align: left;"></div>
</template>
<script>
import E from 'wangeditor'
import {uploadFile} from "@/api/api.js"
import {projectName} from '@/utils/config'
var editor;
export default {
  name: 'editor',
  props:['get-full-text','content','no'], //回调方法
  mounted() {
    editor = new E(this.$refs.editor);
    editor.config.onchange = (html) => {
      //console.log(html)
      this.getFullText1(html);
    };
    //editor.config.height=600;
    //editor.$textContainer('height','700px !important')
    editor.config.border = 0;
    editor.config.minHeight = '16vw';
    editor.config.maxHeight = '16vw';
    editor.config.height = '16vw';
    editor.config.uploadImgServer ='/file/upload';
    editor.config.uploadFileName = 'file';
    editor.config.zIndex = 100;
    editor.config.uploadImgFns= function onload(resultText, xhr) {
      console.log(resultText,xhr);
    }
    editor.config.customUploadImg =async (resultFiles,insertImg) => {
      let file = resultFiles[0];
      console.log(file);
      let param = new FormData(); // 创建form对象
      param.append("file", file); // 通过append向form对象添加数据
      uploadFile(param).then(res=>{
        insertImg('/'+projectName+res.data);
      })
    }
    editor.txt.html(this.content);
    editor.create();
    //如果默认传递content值则渲染默认内容
  },
  methods: {
    getFullText1(html){
      this.$emit('getText',html)
    },
    setText(text){
      editor.txt.html('');
      editor.txt.html(text)
    }
  }
}
</script>
<style lang="scss">
  #wangeditor .w-e-text-container{
      z-index:1;
      height: 18vw !important;
    }
  #wangeditor .w-e-toolbar{
      flex-wrap: wrap;
  }
  #wangeditor .w-e-menu{
    z-index: auto !important;
  }
  #wangeditor .w-e-menu .w-e-droplist{
    z-index:2 !important;
  }
  /* 编辑器 粗体 */
  .w-e-text {
  p{
  b{
    font-weight: bold !important;
  }
  i{
    font-style:italic !important;
  }
  }
  }
</style>