import {createRouter,createWebHashHistory} from 'vue-router' //createWebHashHistory
import Login from '@/view/login.vue'
import Home from '@/view/home.vue'
import Index from '@/view/index.vue'
import roomEdit from '@/view/room/roomEdit.vue'
import assetEdit from '@/view/asset/assetEdit.vue'
import appoinmentEdit from '@/view/room/appoinmentEdit.vue'
import sysConfig from '@/view/adminUser/sysConfig.vue'
import noticeEdit from '@/view/notice/noticeEdit.vue'
import equipmentEdit from '@/view/equipment/equipmentEdit.vue'
import userPower from '@/view/adminUser/userPower.vue'
/* 用户移动端静态 */
import Agree from '@/view/mobile/agree.vue'
import MobileLogin from '@/view/mobile/login.vue'
import MobileIndex from '@/view/mobile/index.vue'
import MobileRoomList from '@/view/mobile/room/roomList.vue'
import MobileUserIndex from '@/view/mobile/user/userIndex.vue'
import MobileYuyueInfo from '@/view/mobile/room/yuyueInfo.vue'
import myYuYue from '@/view/mobile/room/myYuYue.vue'
import myYuYueInfo from '@/view/mobile/room/myYuYueInfo.vue'
import noticeMobileList from '@/view/mobile/mobileNotice/noticeMobileList.vue'
import noticeMobileInfo from '@/view/mobile/mobileNotice/noticeMobileInfo.vue'
import userInfo from '@/view/mobile/user/userInfo.vue'
import userPass from '@/view/mobile/user/userPass.vue'
import examList from "@/view/mobile/exam/examList.vue";
import exam from "@/view/mobile/exam/exam.vue";
import certificateList from "@/view/mobile/exam/certificateList.vue";
import certificate from "@/view/mobile/exam/certificate.vue";
import resourcesList from "@/view/mobile/exam/resourcesList.vue";
import pdfPreview from "@/view/mobile/exam/pdfPreview.vue";

// 实验室监控路由
import monitor from "@/router/monitor";
// 实验室可视化路由
import visualization from "@/router/visualization";
import VisualizationMonitor from "@/view/visualizationMonitor.vue"
// 手机端-后台
import adminRouter from '@/router/admin'
import {isExeMode} from "@/utils/config";

/*
import AuthorizationList from '@/view/rent/authorizationList.vue'
import AccountList from '@/view/charging/accountList.vue'
import InformationList from '@/view/notice/informationList.vue'
import IntroduceList from '@/view/notice/introduceList.vue'
import InformationTypeList from '@/view/notice/informationTypeList.vue'*/
export default createRouter({
    history:createWebHashHistory(),
    //动态注册路由
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
            beforeEnter: (to, from, next) => {
                if (isExeMode()) {
                    next({
                        path: '/visualization/login'
                    })
                } else {
                    next()
                }
            }
        },
        {
            path: '/visualization',
            name: 'VisualizationMonitor',
            component: VisualizationMonitor,
            redirect: '/visualization/index',
            children: [
                ...visualization
            ]
        },
        {
            path: '/visualization/login',
            name: 'visualizationLogin',
            meta: {title: '智能监控登录'},
            component: () => import('@/view/visualization/login.vue')
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            redirect:'/index',
            children:[
                ...monitor,
                {
                    path: '/index',
                    name: 'Index',
                    component: Index
                },
                {
                    path: '/room/roomEdit',
                    name: 'roomEdit',
                    component: roomEdit
                },
                {
                    path: '/room/appoinmentEdit',
                    name: 'appoinmentEdit',
                    component: appoinmentEdit
                },
                {
                    path: '/asset/assetEdit',
                    name: 'assetEdit',
                    component: assetEdit
                },
                {
                    path: '/adminUser/sysConfig',
                    name: 'sysConfig',
                    component: sysConfig
                },
                {
                    path: '/notice/noticeEdit',
                    name: 'noticeEdit',
                    component: noticeEdit
                },
                {
                    path: '/equipment/equipmentEdit',
                    name: 'equipmentEdit',
                    component: equipmentEdit
                },
                {
                    path: '/adminUser/userPower',
                    name: 'userPower',
                    component: userPower
                },
                {
                    path: '/room/statisticsList',
                    name: 'statisticsList',
                    component: () => import('@/view/room/statisticsList.vue')
                },
                // 题库
                {
                    path: '/question/list',
                    name: 'questionList',
                    component: () => import('@/view/exam/question/list.vue')
                },
                {
                    path: '/question/add',
                    name: 'questionAdd',
                    component: () => import('@/view/exam/question/create.vue')
                },
                {
                    path: '/question/add/:id',
                    name: 'questionEdit',
                    component: () => import('@/view/exam/question/create.vue')
                },
                // 分类
                {
                    path: '/question/classification',
                    name: 'classification',
                    component: () => import('@/view/exam/question/classification.vue')
                },
                // 试卷
                {
                    path: '/examPaper/list',
                    name: 'examPaperList',
                    component: () => import('@/view/exam/examPaper/list.vue')
                },
                {
                    path: '/examPaper/add/:id',
                    name: 'examPaperAdd',
                    component: () => import('@/view/exam/examPaper/create.vue')
                },
                // 考试
                {
                    path: '/exam/list',
                    name: 'examList',
                    component: () => import('@/view/exam/exam/list.vue')
                },
                {
                    path: '/examResult/list',
                    name: 'examResultList',
                    component: () => import('@/view/exam/exam/resultList.vue')
                },
                {
                    path: '/examUser/list/:id',
                    name: 'examUserList',
                    component: () => import('@/view/exam/exam/examUserList.vue')
                },
                // 证书列表
                {
                    path: '/examCertificate/list',
                    name: 'examCertificateList',
                    component: () => import('@/view/exam/exam/examCertificateList.vue')
                },
                // 知识库
                {
                    path: '/resource/list',
                    name: 'resourceList',
                    component: () => import('@/view/exam/resources/list.vue')
                },
                // 巡检
                // 巡检看板
                {
                    path: '/patrolInspection/statistics',
                    name: 'statistics',
                    component: () => import('@/view/patrolInspection/statistics.vue')
                },
                // 消防栓
                {
                    path: '/patrolInspection/fireHydrantsList',
                    name: 'fireHydrantsList',
                    component: () => import('@/view/patrolInspection/fireHydrantsList.vue')
                },
                {
                    path: '/patrolInspection/fireHydrants/:id?',
                    name: 'fireHydrantsEdit',
                    component: () => import('@/view/patrolInspection/fireHydrants.vue')
                },
                // 实验室设备维修登记
                {
                    path: '/patrolInspection/laboratoryEquipmentList',
                    name: 'laboratoryEquipmentList',
                    component: () => import('@/view/patrolInspection/laboratoryEquipmentList.vue')
                },
                {
                    path: '/patrolInspection/laboratoryEquipment/:id?',
                    name: 'laboratoryEquipment',
                    component: () => import('@/view/patrolInspection/laboratoryEquipment.vue')
                },
                // 每日巡检
                {
                    path: '/patrolInspection/patrolInspectionList',
                    name: 'patrolInspectionList',
                    component: () => import('@/view/patrolInspection/patrolInspectionList.vue')
                },
                {
                    path: '/patrolInspection/patrolInspection/:id?',
                    name: 'patrolInspection',
                    component: () => import('@/view/patrolInspection/patrolInspection.vue')
                },
                // 每周巡检
                {
                    path: '/patrolInspection/equipmentWeekList',
                    name: 'equipmentWeekList',
                    component: () => import('@/view/patrolInspection/equipmentWeekList.vue')
                },
                {
                    path: '/patrolInspection/equipmentWeek/:id?',
                    name: 'equipmentWeek',
                    component: () => import('@/view/patrolInspection/equipmentWeek.vue')
                },
                // 灭火器巡检
                {
                    path: '/patrolInspection/fireExtinguisherList',
                    name: 'fireExtinguisherList',
                    component: () => import('@/view/patrolInspection/fireExtinguisherList.vue')
                },
                {
                    path: '/patrolInspection/fireExtinguisher/:id?',
                    name: 'fireExtinguisher',
                    component: () => import('@/view/patrolInspection/fireExtinguisher.vue')
                },
                // 教务处排课信息
                {
                    path: '/room/scheduling/list',
                    name: 'schedulingList',
                    component: () => import('@/view/room/schedulingList.vue')
                },
            ]
        },
        {
            path: '/mobile/agree',
            name: 'Agree',
            component: Agree
        },
        {
            path: '/mobile/login',
            name: 'MobileLogin',
            component: MobileLogin
        },
        {
            path: '/mobile/index',
            name: 'MobileIndex',
            component: MobileIndex
        },
        {
            path: '/mobile/room/roomList',
            name: 'MobileRoomList',
            component: MobileRoomList
        },
        {
            path: '/mobile/user/userIndex',
            name: 'MobileUserIndex',
            component: MobileUserIndex
        },
        {
            path: '/mobile/room/yuyueInfo',
            name: 'MobileYuyueInfo',
            component: MobileYuyueInfo
        },
        {
            path: '/mobile/room/myYuYue',
            name: 'myYuYue',
            component: myYuYue
        },
        {
            path: '/mobile/mobileNotice/noticeMobileList',
            name: 'noticeMobileList',
            component: noticeMobileList
        },
        {
            path: '/mobile/mobileNotice/noticeMobileInfo',
            name: 'noticeMobileInfo',
            component: noticeMobileInfo
        },
        {
            path: '/mobile/room/myYuYueInfo',
            name: 'myYuYueInfo',
            component: myYuYueInfo
        },
        {
            path: '/mobile/user/userInfo',
            name: 'userInfo',
            component: userInfo
        },
        {
            path: '/mobile/user/userPass',
            name: 'userPass',
            component: userPass
        },
        // 考试列表
        {
            path: '/mobile/user/examList',
            name: 'userExamList',
            component: examList
        },
        {
            path: '/mobile/user/exam/:id',
            name: 'userExam',
            component: exam
        },
        // 证书列表
        {
            path: '/mobile/user/certificate',
            name: 'userCertificate',
            component: certificateList
        },
        {
            path: '/mobile/user/certificate/query/:id',
            name: 'userCertificateQuery',
            component: certificate
        },
        // 知识学习
        {
            path: '/mobile/user/resources/list',
            name: 'resourcesList',
            component: resourcesList
        },
        {
            path: '/mobile/user/pdf/preview',
            name: 'pdfPreview',
            component: pdfPreview
        },
        ...adminRouter,
    ],
/*    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            redirect:'/rent/visitorList',
            children:[
                {
                    path: '/rent/visitorList',
                    name: 'VisitorList',
                    component: VisitorList
                },
                {
                    path: '/rent/checkList',
                    name: 'CheckList',
                    component: CheckList
                },
                {
                    path: '/rent/authorizationList',
                    name: 'AuthorizationList',
                    component: AuthorizationList
                },
                {
                    path: '/charging/accountList',
                    name: 'AccountList',
                    component: AccountList
                },
                {
                    path: '/notice/informationList',
                    name: 'InformationList',
                    component: InformationList
                },
                {
                    path: '/notice/introduceList',
                    name: 'IntroduceList',
                    component: IntroduceList
                },
                {
                    path: '/notice/informationTypeList',
                    name: 'InformationTypeList',
                    component: InformationTypeList
                },
                {
                    path: '/rent/checkServerEdit',
                    name: 'CheckServerEdit',
                    component: CheckServerEdit
                }
            ]
        },
    ]*/
})

/**
 * 全局前置守卫
 * 用于限制在exe模式下不跳转除了监控面板之外的页面
 */
export const beforeEachHandler = function (to, from, next){
    // 监控看板页面
    const monitoringPanelRouters = [
        // -监控登陆
        '/visualization/login',
        // -监控首页
        '/visualization',
        // -监控首页
        '/visualization/index',
        // -警告列表
        '/visualization/warning/list',
        // -监控列表
        '/visualization/video/monitor'
    ]
    // exe部分过滤
    if (isExeMode()) {
        if (monitoringPanelRouters.includes(to.path)) {
            next()
        } else {
            next({ path: '/visualization/login' })
        }
    }
    // 非exe部分
    else {
        next()
    }
}
