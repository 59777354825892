<template>
  <div>
    <div class="info-div1200" style="width:62.5%">
      <div style="margin-top: 19%">
        <div class="login-title">实验室综合管理平台</div>
        <div style="background: #3769B9" class="flex">
          <div style="margin: 16px 30px"><img src="@/static/img/login/login-img.png"></div>
          <div class="loginForm-css">
              <div><img src="@/static/img/logo.png"></div>
            <div class="form-input flex align-center marginTop40">
              <img src="@/static/img/login/user.png"><input placeholder="账号" v-model="loginInfo.username">
            </div>
            <div class="form-input flex align-center marginTop20px">
              <img src="@/static/img/login/psd.png"><input placeholder="密码" type="password" v-model="loginInfo.password">
            </div>
            <div><button @click="onSubmit">登录</button></div>
<!--           <div @click="autoUserLogin('1321321sadsads')">1221</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {login} from "@/api/api";
import {autoLogin} from "@/api/mobile";
export default {
  data(){
    return{
      type:1,
      loginInfo:{
        username:'',
        password:''
      },
      autoLogin:false,
      clientHeight:'',
      clientWidth:'',
      marginTOP:300,
      ticket:'',
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    this.getUrlParam();//获取参数,用于移动端 用户三方登录
    let that=this;
    this.clientHeight = `${document.documentElement.clientHeight}`;
    this.clientWidth = `${document.documentElement.clientWidth}`;
    let s=(this.clientHeight-500)/2;
    if(s<0){s=0;}
    this.marginTOP=s;
    window.onresize = function() {
      this.clientHeight = `${document.documentElement.clientHeight}`;
      this.clientWidth = `${document.documentElement.clientWidth}`;
      let s=(this.clientHeight-500)/2;
      if(s<0){s=0;}
      this.marginTOP=s;
      that.$forceUpdate();
    }
  },
  methods:{
    getUrlParam(){//获取参数
      var url = window.location.href ;             //获取当前url
      var dz_url = url.split('#')[0];                //获取#/之前的字符串
      var cs = dz_url.split('?')[1];                //获取?之后的参数字符串
      if(!cs){return false;}
      var cs_arr = cs.split('&');                    //参数字符串分割为数组
      var cs={};
      for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
        cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      }
      this.ticket = cs.ticket;                                           //这样就拿到了参数中的数据
      if(this.ticket){
        this.autoUserLogin();
      }
    },
    autoUserLogin(){//用户端三方登录
      autoLogin(this.ticket).then((res)=>{
        if(res.data){
          this.$root.ElMessage({message: res.message,type:'success'});
          window.localStorage.setItem('token',res.data);
          this.$root.useRouter.push("/mobile/index");
        }else{
          this.$root.ElMessage.warning({message: '第三方登录已失效'});
          this.$root.useRouter.push("/mobile/login");
        }
      })
    },

    onSubmit(){
      if(!this.loginInfo.username){
        this.$root.ElMessage.error({message: '请输入用户名'});
        return false;
      }
      if(!this.loginInfo.password){
        this.$root.ElMessage.error({message: '请输入密码'});
        return false;
      }
      //this.$root.useRouter.push("/home");//路由跳转
      //let datafp='username='+this.loginInfo.username+'&password='+this.loginInfo.password;
      localStorage.removeItem('menuUrl')
      login(this.loginInfo).then(res=>{
        if(res.success){
          this.$root.ElMessage({message: res.message,type:'success'});
          window.localStorage.setItem('token',res.data);
          this.$root.getMenuList();
          this.$root.useRouter.push("/home");
        }else{
          this.$root.ElMessage.error({message: res.message});
        }
      })
    }
  },
  wacth:{
    clientHeight(){
      this.changeFixed(this.clientHeight)
    }
  }
}
</script>

<style lang="scss" scoped>
.login-title{
  color: #3769B9;font-size: 30px;font-weight: 500;text-align: left;padding-bottom: 20px;
}
.loginForm-css{
  position: relative;top:-58px;
  background: white;box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.06);
  border-radius: 12px;border: 1px solid #F4F5F6;
  width: 28%;
  padding: 40px 3%;
  margin-left: 4%;
  height: 330px;
  .form-input{
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    height: 48px;
    padding: 0 16px;
    background: white;
    input{
      border: 0;outline: none;
      line-height: 45px;
      padding: 0 3px;
      width:100%;
    }
  }
  button{
    height: 48px;color: white;width: 100%;background: #3769B9;border-radius: 8px;border: 0;margin-top: 40px;
  }
}
</style>