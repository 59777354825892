<template>
  <div class="mobile-body">
    <div style="padding:12px 16px;" class="text-left">
        <div class="fontSize20gayBB">{{Info.title}}</div>
        <div class="flex justify-between align-center fontSize12gay99 marginTop15px">
          <div class="flex align-center"><img src="@/static/img/logo-icon.png" class="img16"><span class="marginLeft5">实验室预约平台</span></div>
          <div>{{Info.time}}</div>
        </div>
        <div class="marginTop20px fontSize14" style="width: 100%;word-break:break-all" v-html="Info.content"></div>
      <div class="mobile-pre-btn" style="margin: 60px 0" @click="goNoticeList">查看更多</div>
    </div>
  </div>
</template>
<script>
import {getNoticeInfo} from "@/api/api";
export default {
  data(){
    return{
      Info:{id:''}
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    this.Info.id=this.$root.useRoute.query.id;
    if(this.Info.id) {
      this.getInfo();
    }
  },
  methods:{
    getInfo(){
      getNoticeInfo(this.Info.id).then((res)=>{
        this.Info=res.data;
      })
    },
    goNoticeList(){
      this.$root.useRouter.push({path:"/mobile/mobileNotice/noticeMobileList"});
    }
  },
}
</script>

<style lang="scss" scoped>
  .mobile-login-input{
    background:#F6F6F6;height: 44px;border-radius:4px;height: 44px;display: flex;align-items: center;padding: 10px 16px;margin: auto 16px;margin-top: 20px;
    image{width: 24px;height: 24px}
    input{margin-left: 10px;width: 95%;outline: none;background: transparent;height: 28px;border: 0;font-size: 15px}
  }
</style>