<template>
  <div class="mobile-body" style="background: #FFFFFF;">
    <div class="fontSize14gay" style="margin: 0 16px">
      <div class="flex justify-between align-center solid-bottom" style="padding: 16px 0">
        <div>账号</div>
        <div>{{ Info.username }}</div>
      </div>
      <div class="flex justify-between solid-bottom" style="padding: 16px 0">
        <div>账户类型</div>
        <div>{{ Info.type == 1 ? '超级管理员' : '普通管理员' }}</div>
      </div>
      <div class="flex justify-between solid-bottom" style="padding: 16px 0;">
        <div>姓名</div>
        <div><input v-model="Info.name" class="input-right fontSize14"></div>
      </div>
      <div class="flex justify-between solid-bottom" style="padding: 16px 0;">
        <div>电话</div>
        <div><input v-model="Info.phone" class="input-right fontSize14" maxlength="11"></div>
      </div>
    </div>
    <div class="mobile-sub-div" style="padding-bottom: 46px; border-top:1px solid #F1F3F6">
      <div class="mobile-sub-btn" @click="subData">保存</div>
    </div>
  </div>
</template>
<script>
import {getUserInfo, editUser} from "@/api/mobile";

export default {
  data() {
    return {
      Info: {
        id: '',
        type: '', // 0普通 1超级
        username: '',
        name: '',
        phone: '',
      },
      userType: ''
    }
  },
  mounted() {
    document.title = '个人中心'
    this.getInfo();
  },
  methods: {
    getInfo() {
      getUserInfo().then((res) => {
        if (res.success) {
          this.Info = {
            id: res?.data?.userInfo?.id || '',
            type: res?.data?.userInfo?.type || '',
            username: res?.data?.userInfo?.username || '',
            name: res?.data?.userInfo?.name || '',
            phone: res?.data?.userInfo?.phone || '',
          };
          this.userType = res.data.userType;
          window.localStorage.setItem('adminUser', JSON.stringify(res.data));
        }
      })
    },
    subData() {
      let url = '/user';
      editUser(url, this.Info, 'put').then((res) => {
        if (res.success) {
          this.$root.ElMessage({message: res.message, type: 'success'});
          this.getInfo();
          this.$root.useRouter.push("/mobile/admin/userIndex");
        } else {
          this.$root.ElMessage.error({message: res.message});
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.solid-bottom {
  border-bottom: 1px solid #F6F6F6
}

.mobile-login-input {
  background: #F6F6F6;
  height: 44px;
  border-radius: 4px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  margin: auto 16px;
  margin-top: 20px;

  image {
    width: 24px;
    height: 24px
  }

  input {
    margin-left: 10px;
    width: 95%;
    outline: none;
    background: transparent;
    height: 28px;
    border: 0;
    font-size: 15px
  }
}

.input-right {
  border: 0;
  outline: none;
  text-align: right
}
</style>
