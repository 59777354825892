<template>
  <div class="mobile-body" style="background: #FFFFFF;">
    <div class="fontSize14gay" style="margin: 0 16px">
      <div class="flex justify-between" style="padding: 16px 0;border-bottom: 1px solid #F6F6F6">
        <div>原密码</div><div><input v-model="passInfo.oldPassword" type="password" placeholder="请输入原密码" style="border: 0;outline: none;text-align: right"></div>
      </div>
      <div class="flex justify-between" style="padding: 16px 0;border-bottom: 1px solid #F6F6F6">
        <div>新密码</div><div><input v-model="passInfo.newPassword" type="password" placeholder="请输入新密码" style="border: 0;outline: none;text-align: right"></div>
      </div>
      <div class="flex justify-between" style="padding: 16px 0;border-bottom: 1px solid #F6F6F6">
        <div>确认密码</div><div><input v-model="passInfo.twoNewPassword" type="password" placeholder="请确认密码" style="border: 0;outline: none;text-align: right"></div>
      </div>
    </div>
    <div class="mobile-sub-div">
      <div class="mobile-sub-btn" @click="subData">保存</div>
    </div>
  </div>
</template>
<script>
import {getUserInfo,userLoginOut,changeUserPass} from "@/api/mobile";
export default {
  data(){
    return{
      passInfo:{oldPassword:'',newPassword:'',twoNewPassword:''},
      userType:''
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    this.getInfo();
  },
  methods:{
    getInfo(){
      getUserInfo().then((res)=>{
        if(res.success) {
          this.userType=res.data.userType;
        }
      })
    },
    subData(){
      if(!this.passInfo.oldPassword || !this.passInfo.newPassword || !this.passInfo.twoNewPassword){this.$root.ElMessage.error('密码不能为空');return false;}
      if(this.passInfo.newPassword!=this.passInfo.twoNewPassword){this.$root.ElMessage.error('两次密码不一致');return false;}
      let url='/'+this.userType;
      changeUserPass(url,this.passInfo,'put').then((res)=>{
        if(res.success){
          this.$root.ElMessage({message: '密码修改成功，请重新登录',type:'success'});
          userLoginOut();
          this.$root.useRouter.push("/mobile/login");
        }else{
          this.$root.ElMessage.error({message: res.message});
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
  .mobile-login-input{
    background:#F6F6F6;height: 44px;border-radius:4px;height: 44px;display: flex;align-items: center;padding: 10px 16px;margin: auto 16px;margin-top: 20px;
    image{width: 24px;height: 24px}
    input{margin-left: 10px;width: 95%;outline: none;background: transparent;height: 28px;border: 0;font-size: 15px}
  }
</style>