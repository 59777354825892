import http from '@/utils/request.js'

/**
 * 获取所有楼栋
 * @param params
 */
export function getAllBuilding(params = {}) {
    return http.get('/monitor/data/view/building', params)
}

/**
 * 获取全部预警事件类型
 * @param params
 */
export function allWarningEventType() {
    return http.get('/warning-event-type/all')
}

/**
 * 数据概览，预警概况，预警占比，预警趋势
 * @param params
 */
export function queryIndexDataView(params = {}) {
    return http.get('/monitor/data/view/data-view', params)
}

/**
 * 获取某一个预警详细数据
 * @param params
 */
export function queryIndexOnlyDataView(laboratoryId) {
    return http.get(`/monitor/data/view/data-view/${laboratoryId}`)
}

/**
 * 获取所有的预警详情数据
 * @param params
 */
export function queryIndexAllDataView(params={}) {
    return http.get('/monitor/data/view/data-view/all', params)
}

/**
 * 预警事件列表-分页
 * @param params
 */
export function warningEventPage(params) {
    return http.get('/warning-event/new-page', params)
}

/**
 * 根据实体id查询事件详情
 * @param params
 */
export function warningEventDetail(id) {
    return http.get(`/warning-event/id/${id}`)
}

/**
 * 获取面板数据
 * @param params
 */
export function queryDataViewSetting(params = {}) {
    return http.get('/data-view-setting', params)
}

/**
 * 所有预制
 * @returns {Promise | Promise<unknown>}
 */
export function warningEventOpinionAll() {
    return http.get('/warning-event-opinion/all')
}

/**
 * 修改限制
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function updateWarningEventOpinion(data = {}) {
    return http.put('/warning-event-opinion', data)
}

/**
 * 删除限制
 * @param id
 * @returns {Promise | Promise<unknown>}
 */
export function deleteWarningEventOpinion(id) {
    return http.delete(`/warning-event-opinion/${id}`)
}

/**
 * 新增预制
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function warningEventOpinion(data = {}) {
    return http.post('/warning-event-opinion', data)
}

/**
 * 保存面板数据
 * @param data
 */
export function setDataViewSetting(data = {}) {
    return http.post('/data-view-setting', data)
}

/**
 * 手动解除预警
 * @param params
 */
export function warningEventClose(params) {
    return http.put('/warning-event/close', params)
}

/**
 * 预警事件对应楼栋的预警设置
 * @param warningEventTypeId
 * @param buildingId
 * @returns {Promise<unknown>}
 */
export function warningEventPlan(warningEventTypeId, buildingId) {
    return http.get(`/warning-event-plan/${warningEventTypeId}/${buildingId}`)
}

/**
 * 保存预警设置
 * @param data
 * @returns {Promise<unknown>}
 */
export function saveWarningEventPlan(data) {
    return http.post(`/warning-event-plan/save`, data)
}

/**
 * 获取预警声音或者预警图标 type: 0 预警音频 1 预警图标
 * @param type
 * @returns {Promise<unknown>}
 */
export function getSysFileListByType(type) {
    return http.get(`/system-file/list/${type}`)
}

/**
 * 保存文件
 * @param data
 * @returns {Promise<unknown>}
 */
export function saveMonitorSysFile(data) {
    return http.post(`/system-file`, data)
}

/**
 * 硬盘录像机列表
 * @param params
 */
export function queryEquipmentVideoRecorderPage(params = {}) {
    return http.get('/equipment-video-recorder/page', params);
}

/**
 * 硬盘录像机详情
 * @param id
 */
export function queryEquipmentVideoRecorderById(id) {
    return http.get(`/equipment-video-recorder/id/${id}`);
}

//
/**
 * 添加录像机
 */
export function addEquipmentVideoRecorder(data = {}) {
    return http.post(`/equipment-video-recorder`, data);
}

/**
 * 修改录像机
 */
export function editEquipmentVideoRecorder(data = {}) {
    return http.put(`/equipment-video-recorder`, data);
}

/**
 * 移动端-获取摄像头列表
 */
export function mobileMonitorEquipment() {
    return http.get(`/monitor/mobile/equipment`);
}

/**
 * 保存截图
 * @param data
 */
export function systemFileScreenshot(data = {}) {
    return http.post(`/system-file/screenshot`, data);
}

/**
 * 保存录屏
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function systemFileSaveVideo(data = {}) {
    return http.post(`/system-file/save-video`, data);
}

/**
 * 截图录像-列表
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function screenshotVideoPage(params = {}) {
    return http.get(`/system-file/screnshot-video`, params);
}

/**
 * 截图录像-删除
 * @param id
 * @returns {Promise | Promise<unknown>}
 */
export function deleteScreenshotVideo(id) {
    return http.delete(`/system-file/${id}`);
}


/**
 * 标准化接口-列表
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function interfacePage(params = {}) {
    return http.get(`/interface/page`, params);
}

/**
 * 保存接口
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function saveInterface(methods, data = {}) {
    return http[methods](`/interface`, data);
}

/**
 * 语音转文字
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function speechToText(data) {
    return http.file(`/monitor/data/view/voice-to-text`, data);
}

/**
 * 检测监控是否存在
 * @param equipmentId
 * @param params
 */
export function monitorExistence(equipmentId, params = {}){
    return http.get(`/equipment-video-recorder/get-recorder/${equipmentId}`, params);
}

export function getEquipmentById(id){
    return http.get(`/equipment/id/${id}`);
}

/**
 * 获取正在预警的楼栋
 * @param params
 */
export function queryWarningEvent( params = {}){
    return http.get(`/warning-event/event`, params);
}

/**
 * 删除预警事件
 * @param id
 * @returns {Promise | Promise<unknown>}
 */
export function deleteWarningEvent(id){
    return http.delete(`/warning-event/${id}`)
}

/**
 * 月度实验室预警总次数及时间分布情况
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function warningEventDataViewTime( params = {}){
    return http.get(`/warning-event-data-view/time`, params);
}

/**
 * 月度实验室预警类型情况及数量情况
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function warningEventDataViewType( params = {}){
    return http.get(`/warning-event-data-view/type`, params);
}

// 饼
export function warningEventDataViewOpinion( params = {}){
    return http.get(`/warning-event-data-view/opinion`, params);
}
