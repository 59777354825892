<template>
  <div class="flex text-center menu-div-bo">
    <div v-for="(m,index) in menus" :key="index" @click="tiaozhuan(index,m.path)" style="width: 33.3%" :class="checkIndex===index?'fontSize10gayA1':'fontSize10gayB1'">
      <div><img :src="checkIndex===index?m.imgCheck:m.img"></div>
      <div>{{m.name}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props:['menuIndex'],
  data(){
    return{
      menus:[
          {id:1,name:'首页',img:require('@/static/img/mobile/menu/index.png'),imgCheck:require('@/static/img/mobile/menu/index-check.png'),path:'/mobile/index'},
          {id:2,name:'实验室',img:require('@/static/img/mobile/menu/yuyue.png'),imgCheck:require('@/static/img/mobile/menu/yuyue-check.png'),path:'/mobile/room/roomList'},
          {id:3,name:'个人中心',img:require('@/static/img/mobile/menu/user.png'),imgCheck:require('@/static/img/mobile/menu/user-check.png'),path:'/mobile/user/userIndex'}
      ],
      checkIndex:0,
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
  },
  methods:{
    getIndex(index){
      this.checkIndex=index;
    },
    tiaozhuan(index,path){
      this.checkIndex=index;
      this.$root.useRouter.push(path);
    }
  },
}
</script>

<style lang="scss" scoped>
  .menu-div-bo{width:100%;background: white;height: 50px;padding-top: 6px;position: fixed;bottom: 0;left: 0}
</style>