<template>
  <div class="mobile-body" style="padding-bottom: 20px">
    <div class="fixed-search">
      <div class="search-box">
        <img src="@/static/img/admin/icon-search.png" alt="" style="width: 16px;height: 16px;" @click="resetAll">
        <el-input @change="getUserList" @input="searchInput" @focus="searchInput" v-model="keyword" placeholder="请输入巡检员搜索"
                  clearable></el-input>
        <div class="search-res" v-if="showSearchRes">
          <span class="el-popper__arrow"></span>
          <div class="item-box" v-if="teacherList.length">
            <div v-for="t in teacherList" :key="'teacher-'+ t.id" class="res-item" @click.stop="chooseUser(t)">
              {{ t.name }}
            </div>
          </div>
          <div v-else class="text-center" style="color: #999;padding:30px 10px;font-size: 14px">暂时没有数据
          </div>
        </div>
      </div>
      <div style="padding-left: 8px">
        <img src="@/static/img/admin/icon-filter.png" alt="" style="width: 24px;height: 24px;"
             @click="doShowDialog">
      </div>
    </div>
    <!--列表-->
    <div v-infinite-scroll="loadMore" infinite-scroll-distance="10" class="patrol-list">
      <div class="patrol-item" v-for="(item,index) in List" :key="index" @click="goInfo(item.id)">
        <div class="up-box">
          <img v-if="item.firstImg" :src="projectName+item.firstImg" class="image">
          <div v-else class="no-image">无图片</div>
          <div class="content">
            <div class="name">{{ item?.laboratory?.name || '' }}</div>
            <div class="text-left" style="font-size: 13px;">
              <span style="color: #666;">巡检日期</span>
              <span style="color: #333;padding-left: 12px">{{ item.date || '' }}</span>
            </div>
          </div>
        </div>
        <div class="flex justify-between align-center">
          <div class="flex align-center">
            <el-icon color="#4A72FF">
              <UserFilled/>
            </el-icon>
            <span style="color: #333;font-size: 12px;padding-left: 4px;">
              {{ item?.teacher?.name || '' }}
            </span>
          </div>
          <div style="color: #666;font-size: 12px;">{{ item.time }}</div>
        </div>
      </div>
      <div v-if="List.length==0" class="no-data">暂无数据</div>
    </div>

    <div class="add-box" @click="toAdd">
      <img src="@/static/img/admin/icon-add.png" alt="">
    </div>

    <el-drawer v-model="showDialog" title="" :with-header="false" direction="btt"
               modal-class="bbt-modal-wrapper" :show-close="false"
               style="border-radius: 20px 20px 0 0;">
      <div>
        <div>
          <div class="title">
            <div class="qx" @click="dateReset">重置</div>
            <div class="name">巡检日期</div>
            <div class="qd" @click="doSearch">确定</div>
          </div>
          <div class="paddingTop20">
            <div>
              <div class="flex align-center marginTop20px">
                <div>开始日期:</div>
                <el-date-picker class="marginLeft10"
                                v-model="pageInfo.startDate"
                                :editable="false"
                                type="date"
                                value-format="YYYY-MM-DD"
                                placeholder="选择开始日期"/>
              </div>
              <div class="flex align-center marginTop20px">
                <div>截止日期:</div>
                <el-date-picker class="marginLeft10"
                                v-model="pageInfo.endDate"
                                :editable="false"
                                type="date"
                                placeholder="选择截止日期"
                                value-format="YYYY-MM-DD"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

  </div>
</template>
<script>
import {
  getDayPage,
  freeHydrantPage,
  getEquipmentPage,
  getExtinguisherPage,
  getMaintenancePage,
  getTeacherList,
} from "@/api/examination";
import {projectName} from "@/utils/config";

export default {
  data() {
    return {
      projectName: projectName,
      type: null,
      typeValue: {
        patrolInspection: {name: '每日巡检自查', func: getDayPage},
        fireExtinguisher: {name: '灭火器每日检查', func: getExtinguisherPage},
        fireHydrants: {name: '消火栓日常巡查', func: freeHydrantPage},
        equipmentWeek: {name: '每周设备巡检', func: getEquipmentPage},
        laboratoryEquipment: {name: '实验室设备维修登记', func: getMaintenancePage},
      },
      List: [],
      pageInfo: {
        limit: 10,
        page: 1,
        total: 0,
        pages: 0, //总页数
        startDate: '',
        endDate: '',
        userId: '',
      },
      teacherList: [], // 巡检人
      showDialog: false,
      keyword: '',
      showSearchRes: false,
    }
  },
  watch: {
    'keyword': {
      handler(val) {
        if (!val) this.showSearchRes = false
      },
      deep: true
    }
  },
  mounted() {
    const query = this.$route.query
    this.type = query.type
    if (!this.type) {
      this.$router.go(-1)
      return
    }
    document.title = this.typeValue[this.type].name
    this.getList();
  },
  methods: {
    getList() {
      const func = this.typeValue[this.type].func
      func(this.pageInfo).then((res) => {
        if (res.success) {
          if (res.data.records && res.data.records.length) {
            res.data.records.map(item => {
              item.firstImg = !item.img ? null : (item.img.split(',')[0] || null);
            })
          }
          this.List = this.List.concat(res.data.records);
          this.pageInfo.pages = res.data.pages;
        }
      })
    },
    doShowDialog() {
      // this.pageInfo.startDate = ''
      // this.pageInfo.endDate = ''
      this.showDialog = true
    },
    // 搜索老师
    getUserList() {
      if (this.keyword) {
        getTeacherList({name: this.keyword}).then(res => {
          this.teacherList = res.success ? res.data : [];
          this.showSearchRes = true
        })
      } else {
        this.showSearchRes = false
      }
    },
    chooseUser(t) {
      this.pageInfo.userId = t.id
      this.keyword = t.name
      this.showSearchRes = false
      if (this.pageInfo.userId > 0) {
        this.resetData()
      }
    },
    searchInput() {
      if (this.keyword && this.teacherList.length) {
        this.showSearchRes = true
      }
      // console.log('搜索结果',this.teacherList.length)
    },
    doSearch() {
      if (!this.pageInfo.startDate) {
        return this.$message.error('请选择开始时间')
      }
      if (!this.pageInfo.endDate) {
        return this.$message.error('请选择截止时间')
      }
      this.showDialog = false
      this.resetData()
    },
    dateReset() {
      this.pageInfo.startDate = ''
      this.pageInfo.endDate = ''
      this.resetData()
    },
    resetAll() {
      this.pageInfo.startDate = ''
      this.pageInfo.endDate = ''
      this.pageInfo.userId = ''
      this.resetData()
    },
    // 重置
    resetData() {
      this.pageInfo.page = 1;
      this.pageInfo.pages = 0;
      this.pageInfo.total = 0;
      this.List = [];
      this.getList();
    },
    // 分页
    loadMore() {
      if (this.pageInfo.page < this.pageInfo.pages) {
        this.pageInfo.page++;
        this.getList();
      }
    },
    goInfo(id) {
      this.$root.useRouter.push({path: "/mobile/admin/inspectionInfo", query: {id: id, type: this.type}});
    },
    toAdd() {
      this.$root.useRouter.push({path: `/mobile/admin/${this.type}Form`});
    },
  },
}
</script>

<style lang="scss" scoped>
.fixed-search {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  background: #ffffff;
  box-sizing: border-box;

  .search-box {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #F6F6F6;
    border-radius: 4px;
    padding: 9px 12px;
    position: relative;

    /deep/ .el-popper__arrow {
      position: absolute;
      top: -3px;
      left: 30px;
      z-index: 30;
    }

    /deep/ .el-popper__arrow::before {
      background: #fff;
    }

    /deep/ .el-input__wrapper,
    /deep/ .el-input__wrapper.is-focus {
      box-shadow: none;
      background-color: #F6F6F6;
      height: 20px;
      color: #999;
      font-size: 14px;
      padding-right: 0;
    }

    .search-res {
      position: absolute;
      top: 48px;
      width: 100%;
      left: 0;
      z-index: 50;
      background: #ffffff;
      text-align: left;
      padding: 0 10px;
      border-radius: 6px;
      box-shadow: var(--el-box-shadow-light);
      border: 1px solid var(--el-border-color-light);

      .item-box {
        min-height: 150px;
        max-height: 250px;
        overflow-y: scroll;
        padding: 10px;

        .res-item {
          line-height: 34px;
          height: 34px;
          font-size: 14px;
          color: #606266;
        }
      }

    }
  }
}

.patrol-list {
  height: calc(100vh - 62px);
  padding: 12px 16px;
  overflow: auto;

  .patrol-item {
    padding: 12px;
    background: #fff;
    border-radius: 6px;

    & + .patrol-item {
      margin-top: 12px;
    }

    .up-box {
      display: flex;
      align-items: center;
      padding-bottom: 12px;

      .image {
        width: 72px;
        height: 72px;
        object-fit: contain;
      }

      .no-image {
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        border: 1px solid #eee;
        color: #888;
        font-size: 12px;
      }

      .content {
        flex: 1;
        height: 72px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-left: 12px;
        text-align: left;

        .name {
          font-size: 16px;
          color: #333;
          font-weight: 500;
          text-align: left;
          width: 100%;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }

    .color666 {
      color: #666666;
    }

  }
}

/deep/ .el-drawer {
  border-radius: 20px 20px 0 0 !important;
  height: 40% !important;
}

.bbt-modal-wrapper {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;


    .qx {
      font-size: 16px;
      color: #999999;
    }

    .name {
      font-size: 17px;
      font-weight: 600;
      color: #333333;
    }

    .qd {
      font-size: 16px;
      color: #4A72FF;
    }
  }
}


.add-box {
  position: fixed;
  right: 16px;
  bottom: 15%;
  z-index: 10;
  background: #4A72FF;
  width: 36px;
  height: 36px;
  padding: 6px;
  border-radius: 5000px;
  box-sizing: border-box;

  & > img {
    width: 24px;
    height: 24px;
  }
}
</style>