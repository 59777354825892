<template>
    <div v-if="eventDetail" class="container">
        <div class="container-screen">
            <img :src="projectName + eventDetail.imgPath" alt="">
        </div>
        <div class="container-center">
            <div class="container-center-main">
                <div class="container-center-main-item flex justify-between">
                    <span>预警事件</span>
                    <span>{{ eventDetail.warningEventType.name }}</span>
                </div>
                <div class="container-center-main-item flex justify-between">
                    <span>楼栋</span>
                    <span>{{ eventDetail.laboratory.building.name }}</span>
                </div>
                <div class="container-center-main-item flex justify-between">
                    <span>实验室</span>
                    <span>{{ eventDetail.laboratory.name }}</span>
                </div>
                <div class="container-center-main-item flex justify-between">
                    <span>预警时间</span>
                    <span>{{ eventDetail.time }}</span>
                </div>
                <div class="container-center-main-item flex justify-between">
                    <span>状态</span>
                    <span :class="{ 'status': eventDetail.state, 'changeBg': !eventDetail.state }" style="line-height: 20px;">{{
                        state[String(eventDetail.state)]
                    }}</span>
                </div>
                <div class="container-center-main-item flex justify-between">
                    <span>解除方式</span>
                    <span>{{ type[String(eventDetail.secureType)] || '' }}</span>
                </div>
                <div class="container-center-main-item flex justify-between">
                    <span>解除时间</span>
                    <span>{{ eventDetail.secureTime || '' }}</span>
                </div>
                <div class="container-center-main-item flex justify-between">
                    <span>处理意见</span>
                    <span>{{ eventDetail.closeMsg || '' }}</span>
                </div>
            </div>
        </div>
        <div class="container-footer ">
            <div v-if="!eventDetail.state" class="wrap flex justify-between">
                <div @click="lookMonitor">查看监控</div>
                <div @click="plan">应急预案</div>
                <div @click="showDialog = true">解除预警</div>
            </div>
            <div v-else class="look" @click="lookMonitor">查看监控</div>
        </div>
        <div v-if="planShow" class="plan">
            <div class="wrap">
                <span>应急预案</span>
                <span @click="planShow = false"></span>
            </div>
            <div v-html="eventDetail.warningEventPlan.plan || '无'"></div>
        </div>
        <el-drawer v-model="showDialog" title="" :with-header="false" direction="btt" modal-class="bbt-modal-wrapper"
            :show-close="false" style="border-radius: 20px 20px 0 0;">
            <div>
                <div class="title">
                    处理意见：
                </div>
                <el-input v-model="planData.closeMsg" :rows="6" type="textarea" placeholder="请输入处理意见" maxlength="100"
                    resize="none" />
            </div>
            <template #footer>
                <el-button @click="showDialog = false">取消</el-button>
                <el-button type="primary" @click="closeWarning">确定</el-button>
            </template>

        </el-drawer>
    </div>
</template>
<script>
import { warningEventDetail, warningEventClose } from "@/api/monitor"
import { WARNING_EVENT_STATE, WARNING_EVENT_SECURE_TYPE, projectName } from "@/utils/config"
import { ElMessage } from "element-plus"

export default {
    data() {
        return {
            projectName,
            eventDetail: null,
            // 状态 预警中/已解除
            state: WARNING_EVENT_STATE,
            // 解除方式
            type: WARNING_EVENT_SECURE_TYPE,
            planShow: false,
            planData: {
                closeMsg: '',
                warningEventId: null
            },
            showDialog: false
        }
    },
    created() {
        this.id = this.$route.query.id
        this.getWarningEventDetail()
    },
    methods: {
        async getWarningEventDetail() {
            let res = await warningEventDetail(this.id)
            if (res.code == 200) {
                this.eventDetail = res.data
                this.planData.warningEventId = res.data.id
            }
        },
        // 查看监控
        lookMonitor() {
            this.$router.push(`/mobile/admin/monitorVideo?id=${this.eventDetail.equipmentId}`)
        },
        plan() {
            this.planShow = true
        },
        async closeWarning() {
            if (this.planData.closeMsg == '') {
                ElMessage.error("处理意见不能为空")
                return
            }
            let res = await warningEventClose(this.planData)
            if (res.code == 200) {
                this.$root.ElMessage.success("操作成功");
                this.showDialog = false
                this.getWarningEventDetail()
            } else {
                this.$root.ElMessage.error(res.message);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.container {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: #f6f6f6;

    &-screen {
        width: 100%;
        height: 210px;

        >img {
            width: 100%;
            height: 100%;
        }
    }

    &-center {
        padding: 12px 16px;

        &-main {
            padding: 16px 12px 0.1px 12px;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 6px 6px 6px 6px;
            opacity: 1;

            &-item {
                font-size: 14px;
                margin-bottom: 16px;

                >span:first-child {
                    color: #666666;
                }

                >span:last-child {
                    color: #333333;
                }

                .status {
                    width: 48px;
                    height: 20px;
                    background: #1DB469;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    color: #fff !important;
                    font-size: 11px;
                }

                .changeBg {
                    width: 48px;
                    height: 20px;
                    background: #FF574D;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    color: #fff !important;
                    font-size: 11px;
                }
            }
        }
    }

    &-footer {
        width: 100%;
        height: 68px;
        box-sizing: border-box;
        padding: 12px 16px;
        position: fixed;
        bottom: 0;
        background-color: #fff;

        .wrap {
            >div {
                width: 106px;
                height: 44px;
                background: #4A72FF;
                border-radius: 6px 6px 6px 6px;
                opacity: 1;
                color: #fff;
                font-size: 16px;
                line-height: 44px;
            }
        }

        .look {
            width: 100%;
            height: 44px;
            background-color: #4A72FF;
            border-radius: 6px 6px 6px 6px;
            line-height: 44px;
            color: #fff;
        }
    }

    .plan {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #fff;
        bottom: 0;
        left: 0;

        .wrap {
            width: 100%;
            height: 40px;
            line-height: 40px;
            position: relative;
            border-bottom: 1px solid #999;

            >span:last-child {
                position: absolute;
                width: 24px;
                height: 24px;
                right: 10px;
                top: 5px;
                color: #ccc;
                background: url(../../../static/img/mobile/room/x.png) no-repeat;
                background-size: cover;
            }
        }

        >div:last-child {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 20px;
        }
    }

    .bbt-modal-wrapper {
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0 8px 0;
        }
    }
}
</style>