<template>
  <div class="visualization-monitor-index-outer">
    <div :style="'background-image: url(' + pageBg + ');background-size: cover'" class="visualization-monitor-index"
      id="main">
      <div :style="'background-image: url(' + headBg + ');background-size: cover'" class="vis-header-warp">
        <div class="left">
          <img src="@/static/img/monitor/logo.png" alt="" class="logo" @click="toIndex">
          <img :src="currWarningIcon.src" alt=""
               style="height: 40px;"
               class="warning-icon"
               v-show="hasEarlyWarning"
               @click="warningEventList">
        </div>
        <div class="center">
          <span>{{ title }}</span>
        </div>
        <div class="right">
          <div class="content">
            <span class="date">{{ date }}</span>
            <span>&nbsp;&nbsp;</span>
            <span class="time">{{ time }}</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="week">{{ week }}</span>
          </div>
          <div class="action flex">
            <div>
              <img @click="voiceDialogShow = true" src="@/static/img/monitor/speech.png" alt="">
            </div>
            <div ref="changeimg" style="margin-left: 20px;">
              <img @click="switchFullScreen" src="../static/img/monitor/fullScreen.png" alt="">
            </div>
            <div v-if='once' style="margin-left: 20px;">
              <img v-if="is_full_screen" @click="switchFullScreen" src="../static/img/monitor/fullScreen.png" alt="">
              <img v-else @click="switchFullScreen" src="../static/img/monitor/unFullScreen.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div id="RecordingShow"></div>
      <template v-if="admin">
        <router-view></router-view>
      </template>
      <warning-dialog ref="warningDialog" @close="warningEventHandler"></warning-dialog>
      <voice-dialog v-if="voiceDialogShow" @close="voiceDialogClose" @toPage="toPage"></voice-dialog>
      <recording-show v-if="screenshotImage"
                      :src="screenshotImage"
                      @close="screenshotImage = ''" />
    </div>
  </div>
</template>

<script>

import {getAdminInfo} from "@/api/api";
import dayjs from "dayjs";
import warningDialog from "@/view/visualization/components/warningDialog.vue"
import voiceDialog from "@/view/visualization/components/voiceDialog.vue"
import {computed, watch} from "vue";
import monitorStore, {messageSocket} from "@/utils/monitor";
import {queryWarningEvent} from "@/api/monitor";
import {isExeMode, projectName} from "@/utils/config";
import RecordingShow from "@/view/visualization/components/RecordingShow";

const WEEK_MAP = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']

export default {
  name: 'visualizationMonitor',
  components: {
    RecordingShow,
    warningDialog,
    voiceDialog
  },
  data() {
    return {
      // require
      pageBg: require('@/static/img/bg.jpg'),
      headBg: require('@/static/img/monitor/headBg.png'),
      //
      admin: null,
      is_full_screen: false,
      date_time: null,
      date: null,
      time: null,
      week: null,
      date_sh: null,
      voiceDialogShow: false,
      keyWord: '',
      once: false,
      // 当前已处理的id
      warningHandlerId: '',
      // 当前是否有预警
      hasEarlyWarning: false,
      // 警告图标显示切换
      warningIcons: [],
      currWarningIcon: {},
      currWarningIconTimer: null,
      // 录屏封面截图
      screenshotImage: '',
    }
  },
  computed: {
    title() {
      return this.$route.meta.title
    }
  },
  provide() {
    return {
      indexVoiceInput: computed(() => this.keyWord),
      // -i 5
      warningHandlerId: computed(() => this.warningHandlerId)
    }
  },
  created() {
    messageSocket.init()
  },
  mounted() {
    this.getAdmin();
    //
    this.listenFullScreen()
    this.initDateTimeSh()
    // 执行自适应
    this.handleScreenAuto();
    //绑定自适应函数 ---防止浏览器栏变化后不再适配
    window.onresize = () => this.handleScreenAuto();
    // 监听预警事件
    watch(() => monitorStore.getWarningEvent(), val => {
      this.getWarningEventList(true, val)
    })
    // 监听录屏结束事件
    watch(() => monitorStore.getShowImgBase64(), val => {
      this.screenshotImage = val
    })
    // 监听 websocket message 推送事件处理
    messageSocket.setReceiveMessageHandler('warningEventHandler', ({ type }) => {
      // 获取预警事件
      type === 'warning_event' && this.getWarningEventList(true)
    })
  },
  onBeforeUnmount(){
    clearInterval(this.currWarningIconTimer)
  },
  methods: {
    /**
     * 预警事件处理
     */
    warningEventHandler(id){
      this.warningHandlerId = id
      this.getWarningEventList(false)
    },
    /**
     * 获取预警事件
     * @param verification 是否开启弹层
     * @param data 预警事件
     */
    getWarningEventList(verification = true, data = null){
      const handler = res => {
        if (res.success) {
          if (res.data.length > 0) {
            // 刷新顶层预警图标
            res.data.forEach(iter => {
              iter.settings.forEach(item => {
                const path = item?.warningEventPlanVo?.iconPath
                if (path) {
                  this.warningIcons.push({
                    src: projectName + path,
                  })
                } else  {
                  this.warningIcons.push({
                    src: require('@/static/img/monitor/alert.png')
                  })
                }
              })
            })

            this.warningIcons.map((iter, index) => {
              iter.nextIndex = index + 1
            })
            if (this.warningIcons.length > 0) {
              this.warningIcons[this.warningIcons.length-1].nextIndex = 0
            }
            if (this.warningIcons.length > 0) {
              this.currWarningIcon = this.warningIcons[0]
              this.currWarningIconTimer && clearInterval(this.currWarningIconTimer)
              this.currWarningIconTimer = setInterval(() => {
                if (this.currWarningIcon) {
                  // 获取下一个 并轮播切换
                  const nextElementIndex = this.currWarningIcon.nextIndex
                  this.currWarningIcon = this.warningIcons[nextElementIndex]
                }
              }, 500)
            }

            // 开启弹层
            if (verification) {
              this.$nextTick(() => {
                this.$refs.warningDialog.open()
              })
            }
            this.hasEarlyWarning = true
          } else {
            this.hasEarlyWarning = false
          }
        }
      }
      if (data) {
        handler(data)
      } else {
        queryWarningEvent().then(res => {
          handler(res)
        })
      }
    },
    /**
     * 前往首页
     */
    toIndex() {
      // 仅浏览器端可跳转到实验室系统
      if (!isExeMode()) {
        window.localStorage.setItem('menuUrl', '/index')
        window.open(`/#/index`, '_blank')
      }
    },
    getAdmin() {
      getAdminInfo().then(res => {
        this.admin = res.data.userInfo;
        window.localStorage.setItem('admin', res.data.userInfo);
      })
    },
    /**
     * 自动缩放
     */
    handleScreenAuto() {
      const designDraftWidth = 1920; //设计稿的宽度
      const designDraftHeight = 1080; //设计稿的高度
      //根据屏幕的变化适配的比例
      const scale = document.documentElement.clientWidth / document.documentElement.clientHeight < designDraftWidth / designDraftHeight
        ? document.documentElement.clientWidth / designDraftWidth
        : document.documentElement.clientHeight / designDraftHeight;
      //缩放比例
      document.querySelector('#main').style.transform = `scale(${scale}) translate(-50%,-50%)`;
    },
    listenFullScreen() {
      document.addEventListener('fullscreenchange', function () {
        this.is_full_screen = document.fullscreenElement ? true : false
        console.log('fullscreenchange', this.is_full_screen)
      })
      document.addEventListener('fullscreenerror', function (err) {
        console.log('fullscreenerror', err)
      })
    },
    switchFullScreen() {

      console.log(this.$refs["changeimg"]);
      this.$refs['changeimg'].style.display = 'none'
      this.once = true
      if (document.fullscreenEnabled) {
        this.is_full_screen = document.fullscreenElement ? true : false
        if (this.is_full_screen) {
          document.exitFullscreen()
        } else {
          document.body.requestFullscreen()
        }
      } else {
        this.$root.ElMessage.error('你的浏览器现在无法使用全屏模式');
      }
    },
    initDateTimeSh() {
      let sh = setInterval(() => {
        let now = dayjs()
        this.date = now.format('YYYY-MM-DD')
        this.time = now.format('HH:mm:ss')
        let week_index = parseInt(now.format('d'))
        this.week = WEEK_MAP[week_index]
      })
      this.date_sh = sh
    },
    warningEventList() {
      if (this.hasEarlyWarning) {
        this.$nextTick(() => {
          this.$refs.warningDialog.open()
        })
      }
    },
    voiceDialogClose(val) {
      this.keyWord = val
      this.voiceDialogShow = false
    },
    toPage(val) {
      this.voiceDialogShow = false
      this.$router.push(`/visualization/video/monitor?keyword=${val}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.visualization-monitor-index-outer {
  height: 100vh;
  overflow: hidden;
  background-color: #000;

  .visualization-monitor-index {
    background-color: #090e24;
    width: 1920px;
    height: 1080px;
    color: #FFFFFF;
    text-align: center;
    // 缩放
    overflow: hidden;
    transform-origin: 0 0;
    position: relative;
    left: 50%;
    top: 50%;

    img {
      -webkit-user-drag: none;
    }

    .vis-header-warp {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 86px;
      background-color: #053552;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      .left {
        width: 480px;
        display: flex;
        justify-content: space-between;

        .logo {
          margin-left: 40px;
          cursor: pointer;
        }

        .warning-icon {
          margin-right: 90px;
          cursor: pointer;
        }
      }

      .center {
        width: 960px;

        span {
          letter-spacing: 8px;
          font-size: 44px;
          font-family: YouSheBiaoTiHei-Bold;
          background-image: linear-gradient(180deg, #FFFFFF 0%, #009DFF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .right {
        width: 480px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
          margin-left: 30px;
          font-size: 18px;
          line-height: 60px;

          .date {
            color: #009DFF;
            font-weight: normal;
          }

          .time,
          .week {
            font-weight: normal;
          }
        }

        .action {
          margin-right: 40px;

          img {
            cursor: pointer;
          }

          img+img {
            margin-left: 20px;
          }
        }
      }

      span {
        cursor: pointer;
      }
    }
  }
}
</style>