import { createApp } from 'vue'
import App from './App.vue'
import router, { beforeEachHandler } from './router'
import store from './store'
import locale from './utils/cn' //主要修改分页的中文
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
const app=createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {//引入图标
    app.component(key, component)
}

router.beforeEach(beforeEachHandler)

app.use(router);
app.use(store);
app.use(ElementPlus,{...locale});
app.mount('#app');
