<template>
  <div class="mobile-body">
    <div style="padding:12px 16px;">
      <div v-for="(item, index) in navs" :key="index"
           class="xj-list-item flex align-center justify-between marginBottom12"
           @click="toPage(item)">
        <div class="marginLeft10 fontSize16gay flex align-center">
          <img src="@/static/img/admin/icon-xj.png" class="img24">
          <span class="xj-name">{{ item.label }}</span>
        </div>
        <el-icon :size="18" style="color: #999;">
          <ArrowRight/>
        </el-icon>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navs: [
        {label: '每日巡检自查', type: 'patrolInspection'},
        {label: '灭火器每日检查', type: 'fireExtinguisher'},
        {label: '消火栓日常巡查', type: 'fireHydrants'},
        {label: '每周设备巡检', type: 'equipmentWeek'},
        {label: '实验室设备维修登记', type: 'laboratoryEquipment'},
      ]
    }
  },
  mounted() {
    document.title = '巡检管理'
  },
  methods: {
    toPage(item) {
      this.$root.useRouter.push(`/mobile/admin/inspectionList?type=${item.type}`);
    }
  },
}
</script>
<style lang="scss" scoped>
.xj-list-item {
  background: #fff;
  border-radius: 8px;
  padding: 16px 13px;

  .xj-name {
    padding-left: 12px;
    font-size: 16px;
    color: #333;
  }
}
</style>
