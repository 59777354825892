<template>
  <div style="background: #FFFFFF;width: 100vw;height:100vh;overflow-x: hidden;box-sizing: border-box; padding: 0 16px;">
    <div class="fontSize14gay" style="width: 100%;">
      <div class="flex justify-between" style="padding: 16px 0;border-bottom: 1px solid #F6F6F6">
        <div>原密码</div>
        <div class="flex-1">
          <input v-model="passInfo.oldPassword" type="password" placeholder="请输入原密码"
                 style="border: 0;outline: none;text-align: right;font-size: 14px">
        </div>
      </div>
      <div class="flex justify-between" style="padding: 16px 0;border-bottom: 1px solid #F6F6F6">
        <div>新密码</div>
        <div class="flex-1">
          <input v-model="passInfo.newPassword" type="password" placeholder="请输入新密码"
                 style="border: 0;outline: none;text-align: right;font-size: 14px">
        </div>
      </div>
      <div class="flex justify-between" style="padding: 16px 0;border-bottom: 1px solid #F6F6F6">
        <div>确认密码</div>
        <div class="flex-1">
          <input v-model="passInfo.twoNewPassword" type="password" placeholder="请确认密码"
                 style="border: 0;outline: none;text-align: right;font-size: 14px">
        </div>
      </div>
    </div>
    <div class="mobile-sub-div" style="padding-bottom: 46px; border-top:1px solid #F1F3F6">
      <div class="mobile-sub-btn" @click="subData">保存</div>
    </div>
  </div>
</template>
<script>
import {userLoginOut, changeAdminUserPass} from "@/api/mobile";

export default {
  data() {
    return {
      passInfo: {
        oldPassword: '',
        newPassword: '',
        twoNewPassword: ''
      },
    }
  },
  mounted() {
    document.title = '修改密码'
  },
  methods: {
    subData() {
      if (!this.passInfo.oldPassword || !this.passInfo.newPassword || !this.passInfo.twoNewPassword) {
        this.$root.ElMessage.error('密码不能为空');
        return false;
      }
      if (this.passInfo.newPassword != this.passInfo.twoNewPassword) {
        this.$root.ElMessage.error('两次密码不一致');
        return false;
      }
      changeAdminUserPass(this.passInfo).then((res) => {
        if (res.success) {
          this.$root.ElMessage({message: '密码修改成功，请重新登录', type: 'success'});
          userLoginOut();
          this.$root.useRouter.push("/mobile/login");
        } else {
          this.$root.ElMessage.error({message: res.message});
        }
      })
    }
  },
}
</script>
<!--
<style>
/* 左右滚动设置 */
html {
  overflow: hidden;
}

body {
  height: 100vh;
  overflow: auto;
}
</style>
-->
<style lang="scss" scoped>
.mobile-login-input {
  background: #F6F6F6;
  height: 44px;
  border-radius: 4px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  margin: auto 16px;
  margin-top: 20px;

  image {
    width: 24px;
    height: 24px
  }

  input {
    margin-left: 10px;
    width: 95%;
    outline: none;
    background: transparent;
    height: 28px;
    border: 0;
    font-size: 15px
  }
}
</style>