<template>
  <div class="resources-list-container">
    <div id="resourcesNav"
         class="resources-nav">
      <div class="nav-item flex align-center"
           @click="changeDirNavFirst">
        <span class="label">知识学习</span>
        <el-icon><ArrowRight /></el-icon>
      </div>
      <div class="nav-item flex align-center"
           :class="{'nav-item-last' : index == path.length - 1}"
           v-for="(iter, index) in path"
           :key="iter.id"
           @click="changeDirNav(iter, index + 1, index == path.length - 1)">
        <span class="label">{{ iter.label }}</span>
        <el-icon v-if="index != path.length - 1"><ArrowRight /></el-icon>
      </div>
    </div>
    <div id="resourcesNavNone"></div>
    <div class="resources-content">
      <div class="resources-item flex justify-between"
           v-for="iter in tree.child"
           :key="iter.id"
           @click="switchDirHandler(iter)">
        <div class="flex prefix-box">
          <img src="@/static/img/mobile/exam/mkdir.png" alt="">
          <div class="prefix-content">
            {{ iter.name }}
          </div>
        </div>
        <div class="flex align-center">
          <el-icon><ArrowRight /></el-icon>
        </div>
      </div>
      <div class="resources-item flex justify-between"
           v-for="iter in tree.resourceFiles"
           :key="iter.id"
           @click="viewResource(iter)">
        <div class="flex prefix-box"
             style="width: 73.33vw;">
          <img v-show="iter.fileType == 0" src="@/static/img/mobile/exam/video.png" alt="">
          <img v-show="iter.fileType == 1" src="@/static/img/mobile/exam/pdf.png" alt="">
          <div class="prefix-content">
            {{ iter.fileName }}
          </div>
        </div>
        <div class="flex justify-between">
          <span v-show="studyListAll.includes(iter.id)"
                class="learned">已学</span>
        </div>
      </div>
      <!-- 空 -->
      <div v-show="tree?.resourceFiles?.length === 0 && tree?.child?.length === 0">
        <el-empty description="暂时没有可学习的内容" />
      </div>
    </div>
    <preview-video ref="previewVideo"></preview-video>
    <pdf-preview-prompt ref="pdfPreviewPrompt"
                        @change="pdfAction"></pdf-preview-prompt>
  </div>
</template>

<script>
import {getResourceMobile, getStudyListAll, viewFile} from "@/api/examination";
import PreviewVideo from "@/view/mobile/exam/PreviewVideo";
import {projectName} from "@/utils/config";
import PdfPreviewPrompt from "@/view/mobile/exam/PdfPreviewPrompt";

export default {
  name: 'resourcesList',
  components: {PdfPreviewPrompt, PreviewVideo},
  data(){
    return {
      tree: {
        child: [],
        id: undefined,
        name: '',
        parentId: '',
        resourceFiles: [],
        time: '',
        version: ''
      },
      path: [],
      studyListAll: []
    }
  },
  mounted() {
    this.navInit()
    this.findNode()
  },
  methods: {
    /**
     * 更新已学列表
     */
    async refreshStudy() {
      const res = await getStudyListAll()
      if (res.success) {
        return res.data
      } else {
        this.$root.ElMessage.error(res.message)
        return []
      }
    },
    /**
     * 查看资源
     */
    viewResource(iter){
      viewFile(iter.id).then(() => {
        // 预览资源
        switch (String(iter.fileType)) {
          // 预览视频
          case '0':
            this.$refs.previewVideo.preview(projectName + iter.fileUrl)
            break
          // 预览pdf
          case '1':
            this.$refs.pdfPreviewPrompt.preview(projectName + iter.fileUrl)
            break
        }
      })
    },
    /**
     * 用户动作
     */
    pdfAction({ label, url }){

      switch (label) {
        case 'preview':
          this.$root.useRouter.push({
            path: `/mobile/user/pdf/preview`,
            query: {
              fileUrl: url
            }
          })
          break
        case 'download':
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.target = '_blank'
          document.body.appendChild(a)
          a.click();
          break
      }
    },
    /**
     * 根
     */
    changeDirNavFirst(){
      this.findNode(undefined, () => {
        this.path = []
        //
        this.navInit()
      })
    },
    /**
     * 从导航切换显示结点
     * index 从1开始
     */
    changeDirNav(iter, index, isLast){
      if (isLast) {
        return
      }

      const path = this.path.slice(0, index)
      this.findNode(iter.id, () => {
        this.path = path
        //
        this.navInit()
      })
    },
    /**
     * 切换显示结点
     */
    switchDirHandler(iter) {
      this.findNode(iter.id, node => {
        this.path.push({label: node.name, id: node.id})
        this.navInit()
      })
    },
    /**
     * 切换目录
     */
    async findNode(id, handler=() => {}) {
      // 更新已学列表
      this.studyListAll = await this.refreshStudy()
      // 更新目录
      const res = await getResourceMobile({parentId: id})
      if (res.success) {
        this.tree = res.data
        handler(this.tree)
      } else {
        this.$root.ElMessage.error(res.message)
      }
    },
    //
    navInit(){
      this.$nextTick(() => {
        const dom = document.getElementById('resourcesNav')
        if (dom) {
          const rect = dom.getBoundingClientRect()
          const dn = document.getElementById('resourcesNavNone')
          if (dn) {
            dn.style.height = `${rect.height}px`
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.resources-list-container{
  min-height: 100vh;
  background-color: #f6f6f6;
  text-align: left;
  box-sizing: border-box;
  .resources-nav{
    position: fixed;
    top: 0;
    left: 0;
    padding: 16px;
    width: 100vw;
    background-color: #fff;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    z-index: 1;

    .nav-item{
      justify-content: space-around;
      line-height: 23px;
      .label{
        font-size: 14px;
        color: #4A72FF;
        white-space:nowrap;

        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap
      }
    }
    .nav-item-last{
      justify-content: flex-start;
      .label{
        color: #666666;
      }
    }
  }
  #resourcesNavNone{
    width: 10px;
  }
  .resources-content{
    padding-top: 3.2vw;
    padding-bottom: 3.2vw;
    .resources-item{
      margin: 0 auto 3.2vw;
      padding: 3.2vw 4.27vw;
      width: 91.47vw;
      border-radius: 8px;
      background-color: #fff;
      box-sizing: border-box;
      z-index: 2;
      .learned{
        padding: 0.53vw 1.07vw;
        font-size: 2.67vw;
        font-weight: 400;
        color: #4A72FF;
        border-radius: 2px;
        border: 1px solid #4A72FF;
        white-space:nowrap
      }
      .prefix-box{
        width: 77.33vw;
        img{
          width: 24px;
          height: 24px;
        }
        .prefix-content{
          margin-left: 12px;
          flex: 1;
          font-size: 16px;
          color: #333333;

          overflow:hidden;
          text-overflow:ellipsis;
          white-space:nowrap
        }
      }
    }
  }
}
</style>