export default [
    {
        path: '/visualization/index',
        name: 'visualizationIndex',
        meta: {title: '智能监控提醒'},
        component: () => import('@/view/visualization/index.vue')
    },
    {
        path: '/visualization/video/monitor',
        name: 'videoMonitor',
        meta: {title: '智能监控系统大屏'},
        component: () => import('@/view/visualization/videoMonitor.vue')
    },
    {
        path: '/visualization/warning/list',
        name: 'warningList',
        meta: {title: '智能监控系统大屏'},
        component: () => import('@/view/visualization/warningList.vue')
    },
]