<template>
  <div class="mobile-body">
    <div class="flex"
         style="padding: 60px 16px 24px 16px;background: linear-gradient(180deg, #E8EDFF 0%, rgba(232,237,255,0) 100%);">
      <div><img src="@/static/img/logo-icon.png" class="img56"></div>
      <div class="marginLeft15 text-left">
        <div class="fontSize20gay">{{ user.userInfo ? user.userInfo.name : '' }}</div>
        <div class="fontSize14gay66 marginTop8px">电话：{{ user.userInfo ? user.userInfo.phone : '' }}</div>
      </div>
    </div>
    <div
        style="border-radius: 6px;box-shadow: 0px 8px 24px -4px rgba(0,0,0,0.04);background-color: white;margin: 0 16px">
      <div class="flex align-center justify-between" style="padding: 17px 12px" @click="goUserInfo">
        <div class="align-center flex">
          <img src="@/static/img/mobile/user/userInfo.png" class="img20">
          <div class="marginLeft10 fontSize16gay">个人中心</div>
        </div>
        <div><img src="@/static/img/mobile/index/right.png" class="img16"></div>
      </div>
      <div class="flex align-center justify-between" style="padding: 17px 12px" @click="goUserPass">
        <div class="align-center flex">
          <img src="@/static/img/mobile/user/userInfo.png" class="img20">
          <div class="marginLeft10 fontSize16gay">修改密码</div>
        </div>
        <div><img src="@/static/img/mobile/index/right.png" class="img16"></div>
      </div>
      <div class="flex align-center justify-between" style="padding: 17px 12px" @click="yuyueList">
        <div class="align-center flex">
          <img src="@/static/img/mobile/menu/yuyue-check.png" class="img20">
          <div class="marginLeft10 fontSize16gay">我的预约</div>
        </div>
        <div><img src="@/static/img/mobile/index/right.png" class="img16"></div>
      </div>
    </div>
    <div v-if="user && user.userType === 'teacher'"
         style="background: #4A72FF;color: #FFF;font-size: 16px;font-weight: 500;margin: 24px 16px;padding: 11px 0"
         class="text-center"
         @click="manageLogin">管理登录
    </div>
    <div style="background: #FFF5F4;color: #FF574D;font-size: 16px;font-weight: 500;margin: 24px 16px;padding: 11px 0"
         class="text-center" @click="loginOut">退出登录
    </div>
    <menuDiv :Row="menuIndex" @tiaozhuan="tiaozhuan" @getList="getList" ref="menuDiv"></menuDiv>
  </div>
</template>
<script>
import menuDiv from "@/view/mobile/menu.vue"
import {userLoginOut} from "@/api/mobile";

export default {
  components: {
    menuDiv,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    this.$refs.menuDiv.getIndex(2);//调用子组件方法
  },
  methods: {
    yuyueList() {
      this.$root.useRouter.push("/mobile/room/myYuYue");
    },
    goUserInfo() {
      this.$root.useRouter.push("/mobile/user/userInfo");
    },
    goUserPass() {
      this.$root.useRouter.push("/mobile/user/userPass");
    },
    // 老师跳管理员端
    manageLogin() {
      if (this.user && this.user.userType == 'teacher') {
        // this.$root.useRouter.push("/mobile/admin/login");
        window.open('/#/mobile/admin/login', '_blank')
      }
    },
    loginOut() {
      this.$root.ElMessageBox.confirm(
          '你确认要退出登录吗？', 'Warning', {confirmButtonText: '确认', cancelButtonText: '取消', type: 'warning',}
      ).then(() => {
        userLoginOut();
        this.$root.useRouter.push("/mobile/login");
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.mobile-login-input {
  background: #F6F6F6;
  height: 44px;
  border-radius: 4px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  margin: auto 16px;
  margin-top: 20px;

  image {
    width: 24px;
    height: 24px
  }

  input {
    margin-left: 10px;
    width: 95%;
    outline: none;
    background: transparent;
    height: 28px;
    border: 0;
    font-size: 15px
  }
}
</style>