<template>
  <div class="screen-recording flex justify-center align-center">
    <img src="@/static/img/monitor/luzhi.png" alt="">
    <div>
      <span>录制中.</span>
      <span class="recording-text"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScreenRecording"
}
</script>

<style lang="scss" scoped>
@keyframes recording {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.screen-recording{
  width: 128px;
  height: 48px;
  background: rgba(0,0,0,0.5);
  border-radius: 8px;
  z-index: 2008;
  color: #ffffff;
  img{
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
  .recording-text{

    &::after, &::before{
      content: '.';
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-name: recording;
      animation-timing-function: linear;
    }
    &::after{
      content: '.';
      animation-delay: 1s;
    }
  }
}
</style>