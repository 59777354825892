<template>
  <div class="preview-video-container"
       :class="{'show-preview-video':show}"
       @click.stop="show = false">
    <div class="video-container"
         @click.stop="() => {}">
      <div v-if="show">
        <video controls autoplay
               style="width: 100%; max-height: 60vh;">
          <source :src="url" type="video/mp4">
          <source :src="url" type="video/avi">
          <source :src="url" type="video/x-flv">
          您的浏览器不支持播放该视频
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewVideo',
  data(){
    return {
      show: false,
      url: '',
    }
  },
  methods: {
    preview(url){
      this.url = url
      this.show = true
    },
    close(){
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-video-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  display: flex;
  justify-content: center;
  align-items: center;

  .video-container{
    width: 100%;
    min-height: 210px;
    background-color: #000;
  }
}
.show-preview-video{
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  //background-color: #000;
}
</style>